import _get from 'lodash/get'
import { AxiosError } from 'axios'
import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryOptions,
  QueryFunction,
  MutationFunction,
} from '@tanstack/react-query'

import { eventTicketsKey } from './ticketsQuery'
import {
  EventCart,
  EventCartItem,
  EventCartItemOnlineRegistration,
  EventCartItemOnlineRegistrationGet,
  OnlineRegistrationForm,
  PaymentKeyInfo,
  Prospect,
  CartSummary,
} from '../types'
import axios from '../helpers/axios'
import { patchUpdateEventCart } from '../services/cartService'

type CreateEventCartItemVars = { cartItem: EventCartItem }
type DeleteEventCartItemVars = CreateEventCartItemVars

type EventCartCheckout = {
  cartId: number
  prospect: Prospect
  opaqueData?: { dataDescriptor: string; dataValue: string }
}

const EventCartQueryKey = 'event-cart'
export const EventCartsSummaryQueryKey = 'carts-summary'
const EventCartItemQueryKey = 'event-cart-item'
const EventPaymentKeyQueryKey = 'event-payment-key'
const RegistrationFormQueryKey = 'registration-form'

export function useEventCart(
  eventUuid: string,
  options?: Partial<UseQueryOptions<EventCart>>
): UseQueryResult<EventCart> {
  const queryFn: QueryFunction<EventCart> = ({ signal }) =>
    axios.get(`/events/${eventUuid}/cart`, { signal }).then((res) => _get(res.data, '0', {}))
  const config: UseQueryOptions<EventCart> = {
    queryKey: [EventCartQueryKey, { eventUuid }],
    staleTime: 0,
    queryFn,
    ...options,
  }
  return useQuery(config)
}

export function useUpdateEventCart(): UseMutationResult<
  EventCart,
  AxiosError,
  { id: number; cart: Partial<EventCart> }
> {
  const queryClient = useQueryClient()

  const mutationFn = ({ id, cart }: { id: number; cart: Partial<EventCart> }): Promise<EventCart> =>
    patchUpdateEventCart(id, cart).then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess(data) {
      queryClient.setQueryData([EventCartQueryKey, { eventUuid: data?.event?.uuid }], data)
      await queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] })
    },
  })
}

export function useDeleteEventCart(
  eventUuid?: string
): UseMutationResult<void, AxiosError, number | undefined, number> {
  const queryClient = useQueryClient()

  const mutationFn: MutationFunction<void, number | undefined> = (id) =>
    axios.delete(`event-carts/${id}`).then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [EventCartQueryKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [eventTicketsKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] }),
      ])
    },
  })
}

export function useEventCartItem(
  id?: number,
  configOptions?: Partial<UseQueryOptions<EventCartItemOnlineRegistrationGet>>
): UseQueryResult<EventCartItemOnlineRegistrationGet> {
  const queryFn: QueryFunction<EventCartItemOnlineRegistrationGet> = ({ signal }) =>
    axios.get(`/event-cart-items/${id}`, { signal }).then((res) => res.data)
  const config: UseQueryOptions<EventCartItemOnlineRegistrationGet> = {
    queryKey: [EventCartItemQueryKey, { id }],
    queryFn,
    ...configOptions,
  }
  return useQuery(config)
}

export function useCreateEventCartItem(
  eventUuid: string
): UseMutationResult<
  EventCartItemOnlineRegistration,
  AxiosError,
  EventCartItemOnlineRegistration,
  EventCartItemOnlineRegistration
> {
  const queryClient = useQueryClient()
  const mutationFn: MutationFunction<EventCartItemOnlineRegistration, EventCartItemOnlineRegistration> = (cartItem) =>
    axios.post(`/event-cart-items`, cartItem).then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess(data) {
      queryClient.setQueryData([EventCartItemQueryKey, { id: `${data.id}` }], data)
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [EventCartQueryKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [eventTicketsKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] }),
      ])
    },
  })
}

export function useUpdateEventCartItem(
  eventUuid: string
): UseMutationResult<EventCartItemOnlineRegistration, AxiosError, EventCartItemOnlineRegistration> {
  const queryClient = useQueryClient()
  const mutationFn: MutationFunction<EventCartItemOnlineRegistration, EventCartItemOnlineRegistration> = (cartItem) =>
    axios.patch(`/event-cart-items/${cartItem.id}`, cartItem).then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess(newItem) {
      queryClient.setQueryData([EventCartItemQueryKey, { id: newItem.id }], newItem)
      await queryClient.invalidateQueries({ queryKey: [EventCartQueryKey, { eventUuid }] })
      await queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] })
    },
  })
}

export function useDeleteEventCartItem(
  eventUuid: string
): UseMutationResult<void, AxiosError, DeleteEventCartItemVars, DeleteEventCartItemVars> {
  const queryClient = useQueryClient()

  const mutationFn: MutationFunction<void, DeleteEventCartItemVars> = ({ cartItem }) => {
    if (cartItem.id) return axios.delete(`/event-cart-items/${cartItem.id || -1}`).then((res) => res.data)
    else return Promise.reject()
  }

  return useMutation({
    mutationFn,
    onMutate({ cartItem: newItem }) {
      queryClient.cancelQueries({ queryKey: [EventCartItemQueryKey, { id: newItem.id }] }).then()
      const previousItem = queryClient.getQueryData([
        EventCartItemQueryKey,
        { id: newItem.id },
      ]) as DeleteEventCartItemVars
      queryClient.removeQueries({ queryKey: [EventCartItemQueryKey, { id: newItem.id }] })
      return previousItem
    },
    onError(_, vars: DeleteEventCartItemVars) {
      const previousItem = _get(vars, 'cartItem', undefined)
      if (previousItem) queryClient.setQueryData([EventCartItemQueryKey, { id: previousItem.id }], previousItem)
    },
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [EventCartQueryKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [eventTicketsKey, { eventUuid }] }),
        queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] }),
      ])
    },
  })
}

export function useCartsSummaryQuery(
  configOptions?: UseQueryOptions<Array<CartSummary>>
): UseQueryResult<Array<CartSummary>> {
  const queryFn: QueryFunction<Array<CartSummary>> = ({ signal }) =>
    axios.get(`/event-carts`, { signal }).then((res) => res.data)
  const config: UseQueryOptions<Array<CartSummary>> = {
    queryKey: [EventCartsSummaryQueryKey],
    queryFn,
    ...configOptions,
  }
  return useQuery(config)
}

export function useRegistrationTicketForm(
  {
    id,
    isAllowTicketFormPrefill,
  }: {
    id?: number
    isAllowTicketFormPrefill?: boolean
  },
  config?: Partial<UseQueryOptions<OnlineRegistrationForm>>
): UseQueryResult<OnlineRegistrationForm> {
  const queryFn: QueryFunction<OnlineRegistrationForm> = ({ signal }) =>
    axios.get(`/managed-events/forms/${id}`, { params: { isAllowTicketFormPrefill }, signal }).then((res) => res?.data)

  return useQuery({
    queryKey: [RegistrationFormQueryKey, { id }],
    queryFn,
    ...config,
  })
}

export function useEventPaymentKey(
  eventUuid: string,
  config: Partial<UseQueryOptions<PaymentKeyInfo>>
): UseQueryResult<PaymentKeyInfo> {
  const queryFn: QueryFunction<PaymentKeyInfo> = ({ signal }) =>
    axios
      .get(`/payment/public-info?eventUuid=${eventUuid}`, { headers: { 'X-SOURCE': 'hosted_event' }, signal })
      .then((res) => res?.data)
  return useQuery({
    queryKey: [EventPaymentKeyQueryKey, { eventUuid }],
    queryFn,
    ...config,
  })
}

export function useEventCartCheckout(): UseMutationResult<void, AxiosError, EventCartCheckout, EventCartCheckout> {
  const queryClient = useQueryClient()
  const mutationFn: MutationFunction<void, EventCartCheckout> = ({ cartId, prospect, opaqueData }) =>
    axios
      .post(
        `/managed-events/checkout`,
        { cart: { id: cartId }, prospect, opaqueData },
        { headers: { 'X-SOURCE': 'directory' } }
      )
      .then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: [EventCartsSummaryQueryKey] })
    },
  })
}

export function useEventCartPayment(): UseMutationResult<{ clientSecret: string }, AxiosError, EventCartCheckout> {
  const mutationFn: MutationFunction<{ clientSecret: string }, EventCartCheckout> = ({ cartId, prospect }) =>
    axios.post(`/payment-gateways/paymentIntent`, { cart: { id: cartId }, prospect }).then((res) => res.data)

  return useMutation({ mutationFn })
}

export function useExtendEventCartExpiration(
  eventUuid: string
): UseMutationResult<void, AxiosError, { cartId: number }, { cartId: number }> {
  const queryClient = useQueryClient()

  const mutationFn: MutationFunction<void, { cartId: number }> = ({ cartId }) =>
    axios
      .post(`/event-carts/${cartId}/extend-expiration`, {}, { headers: { 'Content-Type': 'application/json' } })
      .then((res) => res.data)

  return useMutation({
    mutationFn,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: [EventCartQueryKey, { eventUuid }] })
    },
  })
}

export function useEventCartExpired(id?: number): UseMutationResult<void, AxiosError, number | undefined, number> {
  const mutationFn: MutationFunction<void, number | undefined> = () =>
    axios.post(`event-carts/${id}/expired`, {}).then((res) => res.data)

  return useMutation({ mutationFn })
}

export function useEventCartVerify(): UseMutationResult<
  void,
  AxiosError,
  { id?: number; paymentSessionToken?: string }
> {
  const mutationFn: MutationFunction<void, { id?: number; paymentSessionToken?: string }> = ({
    id,
    paymentSessionToken,
  }) => axios.post(`event-carts/${id}/verify`, { paymentSessionToken }).then((res) => res.data)

  return useMutation({ mutationFn })
}
