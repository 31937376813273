import { useCallback } from 'react'
import { AxiosRequestConfig } from 'axios'
import {
  useQuery,
  queryOptions,
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
  UndefinedInitialDataOptions,
} from '@tanstack/react-query'

import axios from '../helpers/axios'
import { getBrand, getCustomerSettings } from '../services/metaDataService'
import { CustomerSettings, EmailVerificationSettings, HexUserMeta, SSRBrands } from '../types'

export const BrandQueryKey = 'brands'
export const userMetaQueryKey = 'user-meta'
export const emailVerificationSettingsQueryKey = 'email-verification-settings'
export const customerSettingsQueryKey = 'customer-settings'

export function useUserMeta(queryOptions?: Partial<UseQueryOptions<HexUserMeta>>): UseQueryResult<HexUserMeta> {
  const queryFn: QueryFunction<HexUserMeta> = ({ signal }) =>
    axios.get(`/user-meta-data`, { signal }).then((res) => res.data)

  const config: UseQueryOptions<HexUserMeta> = {
    queryKey: [userMetaQueryKey],
    queryFn,
    ...queryOptions,
  }

  return useQuery(config)
}

export function useEmailVerificationSettingsQuery(
  queryOptions?: Partial<UseQueryOptions<EmailVerificationSettings>>
): UseQueryResult<EmailVerificationSettings> {
  const queryFn: QueryFunction<EmailVerificationSettings> = ({ signal }) =>
    axios.get(`/managed-events/settings/email-verification`, { signal }).then((res) => res.data)

  return useQuery({
    queryKey: [emailVerificationSettingsQueryKey],
    queryFn,
    staleTime: Infinity,
    ...queryOptions,
  })
}

export const customerSettingsQueryOptions = (
  { headers }: { headers?: AxiosRequestConfig['headers'] } = {},
  options?: UndefinedInitialDataOptions<CustomerSettings>
) => {
  return queryOptions({
    queryKey: [customerSettingsQueryKey],
    queryFn: ({ signal }) => getCustomerSettings({ signal, headers }).then((res) => res.data),
    staleTime: Infinity,
    ...options,
  })
}

export function useCustomerSettingsQuery(
  options?: Partial<UseQueryOptions<CustomerSettings>>
): UseQueryResult<CustomerSettings> {
  return useQuery({
    ...customerSettingsQueryOptions(),
    ...options,
  })
}

export function useBrandQuery(configOptions?: Partial<UseQueryOptions<SSRBrands>>): UseQueryResult<SSRBrands> {
  const queryFn = (): Promise<SSRBrands> => getBrand().then((res) => res.data)
  const config: UseQueryOptions<SSRBrands> = {
    queryKey: [BrandQueryKey],
    queryFn,
    staleTime: Infinity,
    select: useCallback(
      (data: SSRBrands) => ({
        ...data,
        logo: data?.logo ?? '/images/Captello-Horizontal-Logo-wht.png',
        favIcon: data?.favIcon ?? '/images/captello.com.ico',
        headerBackgroundColor: data?.headerBackgroundColor ?? '#333333',
        footerBackgroundColor: data.footerBackgroundColor ?? '#242424',
        footerTextColor: data.footerTextColor ?? 'white',
      }),
      []
    ),
    ...configOptions,
  }
  return useQuery(config)
}
