import { FieldValues, useFormState, UseFormStateReturn } from 'react-hook-form'

interface Props {
  render: (formState: UseFormStateReturn<FieldValues>) => JSX.Element
  control: any
}

const FormStateRPC = (props: Props): JSX.Element => {
  const formState = useFormState(props)
  return props.render(formState)
}

export default FormStateRPC
