import { AlertProps, AlertTitleProps } from '@mui/material'
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'

export const Alert = (props: AlertProps) => {
  const { children, ...alertProps } = props

  return <MuiAlert {...alertProps}>{children}</MuiAlert>
}

export const AlertTitle = (props: AlertTitleProps) => <MuiAlertTitle {...props} />
