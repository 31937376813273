import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'

import { SSRBrands } from './types'
import { LocaleCode } from './enums'
import { MuiLocalesMap } from './constants/localization'

const getOverrides = () => ({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 20,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: 14,
        },
      },
    },
  },
})

const getOptions = (opts?: SSRBrands): ThemeOptions => {
  return {
    typography: {
      fontFamily: `${opts?.fontFamily}, Visby CF`,
      fontWeightLight: 200,
      fontWeightRegular: 300,
      fontWeightMedium: 500,
      fontWeightBold: 'bold',
    },
    palette: {
      primary: {
        main: opts?.themeColor ?? '#CF070D',
      },
      secondary: {
        main: '#111111',
      },
      background: {
        default: '#fff',
      },
      text: {
        primary: '#4A4949',
        secondary: '#757373',
      },
    },
    shape: {
      borderRadius: 12,
    },
  }
}

// A custom theme for the app
export const createCustomBrandTheme = ({
  brand,
  localeCode,
}: {
  brand?: SSRBrands
  localeCode?: LocaleCode
}): Theme => {
  return createTheme({ ...getOptions(brand), ...getOverrides() }, localeCode ? MuiLocalesMap[localeCode] : {})
}
// const theme = createTheme({ ...getOptions(), ...getOverrides() })
