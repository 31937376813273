import { AxiosPromise } from 'axios'

import axios from '../helpers/axios'
import { PreAuthData } from '../types'

export const postPreAuthData = ({ email, host, authenticationMode, token }: PreAuthData): AxiosPromise => {
  return axios.post(
    `/managed-events/pre-auth`,
    { email, host, authenticationMode, token },
    { headers: { skipAuth: 'true' } }
  )
}
