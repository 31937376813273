import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { HexEventTag } from '../types'
import axios from '../helpers/axios'

const searchQueryKey = 'search-tags'

export function useSearchTags(query: string): UseQueryResult<Array<HexEventTag>> {
  const queryFn: QueryFunction<Array<HexEventTag>> = ({ signal }) => {
    if (!query || query.length < 2) return Promise.resolve([])
    return axios.get(`/tags`, { params: { name: query }, signal }).then((res) => res.data)
  }

  const config: UseQueryOptions<Array<HexEventTag>> = { queryKey: [searchQueryKey, { query }], queryFn }

  return useQuery(config)
}
