import { Controller } from 'react-hook-form'
import { TextFieldProps } from '@mui/material'

import TextField from '../ui/TextField'
import FormFieldHelperText from '../ui/FormFieldHelperText'

type ComponentProps = TextFieldProps & {
  name: string
  control?: any
  label?: unknown
  helperText?: string
}

function ControlledTextField(props: ComponentProps): JSX.Element {
  const { name, control, label, helperText = '', ...inputProps } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...fieldProps }, fieldState }) => {
        return (
          <>
            <TextField
              fullWidth
              label={label}
              {...inputProps}
              {...fieldProps}
              value={fieldProps.value ?? ''}
              inputRef={ref}
            />

            <FormFieldHelperText fieldState={fieldState} helperText={helperText} />
          </>
        )
      }}
    />
  )
}

export default ControlledTextField
