import * as Yup from 'yup'
import _get from 'lodash/get'
import { Auth } from 'aws-amplify'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../shared/ui/Button'
import log from '../../helpers/log'
import { useSnackBar } from '../../providers/SnackbarProvider'
import FormStateRPC from '../rpc/FormStateRPC'
import { AuthenticationMode } from '../../enums'
import { useAuth } from '../../providers/authProvider'
import { useTranslation } from '../../providers/localesProvider'
import ControlledTextField from '../shared/form-fields/ControlledTextField'
import { postPreAuthData } from '../../services/authService'
import { getHost, getRandomString } from '../../helpers/common'
import { useEmailVerificationSettingsQuery } from '../../queryHooks/metaQuery'

type Props = {
  nextStep?: (user: any) => void
  user: any
  defaultEmail?: string
}

function EmailVerificationForm(props: Props): JSX.Element {
  const { nextStep, user, defaultEmail = '' } = props
  const { t } = useTranslation()
  const { enqueue } = useSnackBar()
  const { isAuthenticated } = useAuth()
  const { data: emailVerificationSettings, isLoading } = useEmailVerificationSettingsQuery({
    enabled: !isAuthenticated(),
  })
  const { emailVerificationPromptSubTitle = t('sendVerificationCode') } = emailVerificationSettings || {}

  const validationSchema = Yup.object({
    email: Yup.string().email(t('invalidValue')).required(t('requiredField')),
  })

  const { control, handleSubmit } = useForm<{ email: string }>({
    defaultValues: { email: user?.username ?? defaultEmail },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const onFormSubmit = handleSubmit(async (values: { email: string }, event): Promise<void> => {
    try {
      await postPreAuthData({ email: values.email, host: getHost(), authenticationMode: AuthenticationMode.verified })
      const userRes = await Auth.signIn(values.email)
      nextStep?.(userRes)
    } catch (err) {
      if (_get(err, 'code') === 'UserNotFoundException') {
        try {
          await Auth.signUp({
            username: values.email,
            password: `Pass@${getRandomString(30)}`,
            attributes: { name: values.email, 'custom:xHost': getHost() },
          })
          onFormSubmit(event).then()
        } catch (e) {
          enqueue({ title: 'error', body: t('failedSendVerificationCode'), severity: 'error' })
          console.error(t('failedSendVerificationCode'), e)
        }
      } else {
        enqueue({ title: 'error', body: t('failedSendVerificationCode'), severity: 'error' })
      }
      console.error(err)
      log.debug(err)
    }
  })

  return (
    <Grid container gap={2}>
      <Grid container item>
        {!isLoading ? <Typography sx={{ fontSize: 14 }}>{emailVerificationPromptSubTitle}</Typography> : null}
      </Grid>
      <form onSubmit={onFormSubmit} style={{ width: '100%' }}>
        <Grid item container justifyContent="center">
          <ControlledTextField
            data-cy="emailInput"
            fullWidth
            name="email"
            control={control}
            label={`${t('email')}`}
            placeholder={t('email')}
          />
        </Grid>

        <FormStateRPC
          control={control}
          render={({ isSubmitting }) => {
            return (
              <Button data-cy="sendCode" type="submit" fullWidth disabled={isSubmitting} isLoading={isSubmitting}>
                {t('sendCode')}
              </Button>
            )
          }}
        />
      </form>
    </Grid>
  )
}
export default EmailVerificationForm
