import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { FC, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import Avatar from '@mui/material/Avatar'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { HexUserProfile } from '../types'
import { useAuth } from '../providers/authProvider'
import { useTranslation } from '../providers/localesProvider'
import { useBrandQuery, useUserMeta } from '../queryHooks/metaQuery'
import { useCognitoUserInfo, useFetchProfile } from '../queryHooks/profileQuery'

import Image from './shared/ui/Image'
import Button from './shared/ui/Button'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  fontWeight: 'bold',
  borderBottom: '1px solid #0000001f',
}))

type ComponentProps = { handleOpenLogInDialog: () => void }

const UserMenu: FC<ComponentProps> = ({ handleOpenLogInDialog }: ComponentProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { logout, isLimited, isAuthenticated, isUnlimited } = useAuth()
  const { data: ssrBrands } = useBrandQuery()
  const isDisableFavorite = ssrBrands?.isDisableFavorite

  const { data: metadata } = useUserMeta({ enabled: isAuthenticated() && !isDisableFavorite && !isLimited() })
  const { totalFavouriteEvents = 0 } = metadata ?? {}
  const { data: user = {} as HexUserProfile } = useFetchProfile({ enabled: isUnlimited })
  const { data: cognitoUserInfo } = useCognitoUserInfo({ enabled: isLimited() })
  const userEmail = cognitoUserInfo?.attributes?.email

  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const picture = _get(user, 'profilePicture.url')
  const fullName = `${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`
  const initials =
    fullName
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase() || userEmail?.at(0)

  const handleProfileMenuOpen = (event: any): void => {
    if (event) setAnchorEl(event?.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  const goToRoute = async (to: string): Promise<void> => {
    if (isLimited()) {
      handleOpenLogInDialog()
    } else {
      handleMenuClose()
      await router.push({ pathname: to, query: _omit(router.query, 'eventId') })
    }
  }

  const handleLogout = async (): Promise<void> => {
    handleMenuClose()
    await logout()
  }

  return (
    <>
      <Button variant="text" color="inherit" onClick={handleProfileMenuOpen} data-cy="userMenuBtn">
        <Avatar
          alt={fullName}
          title={fullName}
          sx={{ fontSize: 13, width: '30px', height: '30px', backgroundColor: 'primary.main' }}
        >
          {picture ? (
            <Image
              priority
              alt={fullName}
              src={picture}
              style={{
                objectFit: 'cover',
              }}
              fill
            />
          ) : (
            initials
          )}
        </Avatar>
        <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
      </Button>
      <Popover
        keepMounted
        open={isMenuOpen}
        anchorEl={anchorEl}
        sx={{ '& .Mui-disabled': { opacity: 1 } }}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <StyledMenuItem disabled dense onClick={handleMenuClose}>
          <Grid container direction="column">
            <Grid item sx={{ fontSize: 14, color: 'text.primary', fontWeight: 'bold' }}>
              {fullName}
            </Grid>
            <Grid item sx={{ fontSize: 14, color: '#ACACAC' }}>
              {user.email}
            </Grid>
          </Grid>
        </StyledMenuItem>

        <StyledMenuItem dense onClick={(): Promise<void> => goToRoute('/profile')}>
          {t('profile')}
        </StyledMenuItem>

        <StyledMenuItem dense onClick={(): Promise<void> => goToRoute('/orders')}>
          {t('orders')}
        </StyledMenuItem>

        <StyledMenuItem dense onClick={(): Promise<void> => goToRoute('/mytickets')}>
          {t('myTickets')}
        </StyledMenuItem>

        {isDisableFavorite || isLimited() ? null : (
          <StyledMenuItem dense onClick={(): Promise<void> => goToRoute('/favorite')}>
            {t('favoriteEvents')} ({totalFavouriteEvents})
          </StyledMenuItem>
        )}

        <StyledMenuItem dense sx={{ fontSize: 14, py: 2, px: 3, fontWeight: 'bold' }} onClick={handleLogout}>
          {t('logout')}
        </StyledMenuItem>
      </Popover>
    </>
  )
}

export default UserMenu
