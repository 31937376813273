import _noop from 'lodash/noop'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { TextField as MuiTextField, TextFieldProps } from '@mui/material'

type ComponentProps = TextFieldProps & {
  clearable?: boolean
  onClear?: () => void
}

function TextField(props: ComponentProps): JSX.Element {
  const {
    variant = 'outlined',
    size = 'medium',
    clearable = false,
    onClear = _noop,
    InputProps,
    type,
    ...textFieldProps
  } = props

  const [showPassword, setShowPassword] = useState(() => type !== 'password')

  return (
    <MuiTextField
      variant={variant}
      size={size}
      type={showPassword && type !== 'password' ? type : showPassword ? 'text' : 'password'}
      sx={{
        '& .MuiInputLabel-formControl': { fontSize: 14 },
        '& .MuiInputBase-input': { fontSize: { xs: 16, sm: 14 }, fontWeight: 600 },
      }}
      InputProps={{
        endAdornment: (
          <>
            {clearable && !textFieldProps.disabled && (textFieldProps.value || textFieldProps?.inputProps?.value) ? (
              <Clear style={{ color: 'gray', cursor: 'pointer', fontSize: 18 }} onClick={onClear} />
            ) : null}
            {type === 'password' && textFieldProps.value ? (
              <IconButton style={{ padding: 0 }} onClick={() => setShowPassword((prev) => !prev)} size="large">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : null}
          </>
        ),
        ...InputProps,
      }}
      {...textFieldProps}
    />
  )
}

export default TextField
