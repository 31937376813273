import { AxiosPromise, AxiosRequestConfig } from 'axios'

import axios from '../helpers/axios'
import { TicketMode } from '../enums'
import { HexEvent } from '../types'

export const getEventTicketsById = (
  {
    eventUuid,
    mode,
    queryParameters,
  }: { eventUuid: HexEvent['uuid']; queryParameters?: Record<string, string | string[] | undefined>; mode: TicketMode },
  config?: AxiosRequestConfig
): AxiosPromise => {
  return axios.get(`/event-tickets/available?eventUuid=${eventUuid}`, {
    params: { ...queryParameters, mode },
    ...config,
  })
}
