import React from 'react'
import _get from 'lodash/get'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { HexEvent } from '../../types'
import { useDayJs } from '../../hooks/useDayJs'
import { useEvent } from '../../queryHooks/eventQuery'

import Link from '../shared/ui/Link'

type Props = {
  eventData: HexEvent
  onTitleClick?: () => void
}

const EventGlobalSearchCard: React.FC<Props> = ({ eventData, onTitleClick }: Props) => {
  const { data: event = {} as HexEvent } = useEvent({ eventUuid: eventData.uuid }, { initialData: eventData })

  const { format } = useDayJs()
  const { timeZone, isUseLocalTimeZone } = event?.timeSettings ?? {}
  const eventTimeZone = !isUseLocalTimeZone ? timeZone?.standardName : undefined

  const minSaleStartTimestamp = event.minSaleStartTimestamp

  if (!event) return <></>

  return (
    <Grid
      container
      item
      justifyContent="center"
      sx={{ height: '100px', borderBottom: '1px solid #E1E1E1', mb: 3 }}
      xs={12}
    >
      <Grid container direction="column" spacing={1}>
        {minSaleStartTimestamp ? (
          <Grid item>
            <Typography sx={{ fontSize: 14, color: '#ACACAC' }}>
              {format({ timeStamp: minSaleStartTimestamp, timeZone: eventTimeZone })}
            </Typography>
          </Grid>
        ) : null}

        <Grid item>
          <Link href={{ pathname: `/${event.uriTitle}` }} passHref>
            <Typography
              color="primary"
              sx={{ fontSize: 20, fontWeight: 'bold', '&:hover': { textDecoration: 'underline' } }}
              onClick={onTitleClick}
            >
              {event.title}
            </Typography>
          </Link>
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Typography
            sx={{ color: '#969696', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            title={_get(event, 'location.fullAddress', '')}
          >
            {_get(event, 'location.fullAddress', '')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EventGlobalSearchCard
