import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import djsDuration, { DurationUnitsObjectType } from 'dayjs/plugin/duration'

dayjs.extend(djsDuration)

const useCountdown = (duration: DurationUnitsObjectType) => {
  const [countdown, setCountdown] = useState(dayjs.duration(duration))

  const resetCountDown = () => setCountdown(dayjs.duration(duration))

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown.asMilliseconds() <= 0) {
        clearInterval(interval)
      } else {
        setCountdown((prev) => prev.subtract(1, 'second'))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [countdown, duration])

  return { countdown, resetCountDown }
}

export default useCountdown
