export const arTranslation = {
  signIn: 'تسجيل الدخول',
  signUp: 'إنشاء حساب',
  search: 'بحث',
  firstName: 'الاسم الأول',
  lastName: 'الاسم الأخير',
  emailAddress: 'عنوان البريد الإلكتروني',
  password: 'كلمة المرور',
  requiredField: 'حقل مطلوب',
  invalidValue: 'قيمة غير صالحة',
  fillUpFieldsToCompletePayment: 'املأ جميع الحقول لإتمام الدفع',
  paymentFailed: 'فشل الدفع',
  somethingWrong: 'حدث خطأ ما.',
  paymentNotSuccessful: 'لم يتم الدفع بنجاح، يرجى المحاولة مرة أخرى.',
  logInFailed: 'فشل تسجيل الدخول',
  phone: 'هاتف',
  address: 'عنوان',
  address1: 'العنوان 1',
  address2: 'العنوان 2',
  country: 'الدولة',
  state: 'الولاية',
  city: 'المدينة',
  zipcode: 'الرمز البريدي',
  saveChanges: 'حفظ التغييرات',
  reset: 'إعادة',
  saveChangesSuccess: 'تم الحفظ',
  saveChangesFailed: 'فشل في حفظ التغييرات',
  details: 'تفاصيل',
  aboutThisEvent: 'حول هذا الحدث',
  tags: 'العلامات',
  findUsOn: 'ابحث عنا على',
  salesStarts: 'بدء المبيعات',
  dateAndTime: 'التاريخ والوقت',
  from: 'من',
  to: 'إلى',
  location: 'الموقع',
  profile: 'الملف الشخصي',
  favoriteEvents: 'الأحداث المفضلة',
  noEvents: 'لا توجد أحداث',
  toggleFavoriteEventFailed: 'فشل في تبديل الحدث المفضل',
  logout: 'تسجيل الخروج',
  organizer: 'المنظم',
  eventNotFound: 'الحدث غير موجود',
  tickets: 'التذاكر',
  ticketsDetails: 'تفاصيل التذاكر',
  salesEndsOn: 'تنتهي المبيعات في',
  fee: 'رسوم',
  free: 'مجاني',
  soldOut: 'نفذت الكمية',
  salesStartsOn: 'تبدأ المبيعات في',
  register: 'تسجيل',
  guest: 'ضيف',
  noTickets: 'لا توجد تذاكر',
  minsLeftForCheckout: 'دقائق متبقية لإتمام الشراء',
  deleteCartItemSuccess: 'تم حذف عنصر السلة',
  deleteCartItemFailed: 'فشل في حذف العنصر من السلة',
  deleteTicketCartItemSuccess: 'تم تفريغ السلة',
  deleteTicketCartItemFailed: 'فشل في حذف العناصر من السلة',
  total: 'المجموع',
  totalDue: 'المجموع المستحق',
  subTotal: 'المجموع الفرعي',
  update: 'تحديث',
  cartExpired: 'انتهت صلاحية السلة',
  checkout: 'الدفع',
  orderSummary: 'ملخص الطلب',
  contactInfo: 'معلومات الاتصال',
  orderInfo: 'معلومات الطلب',
  name: 'الاسم',
  cardInfo: 'معلومات البطاقة',
  placeOrder: 'تقديم الطلب',
  mobilePhone: 'الهاتف المحمول',
  placeOrderSuccess: 'تم تقديم الطلب',
  placeOrderFailed: 'فشل في تقديم الطلب',
  extend: 'تمديد',
  cancel: 'إلغاء',
  extendEventCartExpirationSuccess: 'تم تمديد انتهاء صلاحية السلة',
  extendEventCartExpirationFailed: 'فشل في تمديد انتهاء صلاحية السلة',
  expirationExtendQuestion: 'هل ترغب في تمديد وقت انتهاء صلاحية السلة؟',
  orders: 'الطلبات',
  myTickets: 'تذاكري',
  upcomingEvents: 'الأحداث القادمة',
  pastEvents: 'الأحداث السابقة',
  eventDetails: 'تفاصيل الحدث',
  summary: 'ملخص',
  events: 'الأحداث',
  organizerEvents: 'أحداث المنظم',
  eventsSearchResultFor: 'نتائج البحث عن الأحداث لـ',
  viewAll: 'عرض الكل',
  noTags: 'بدون علامات',
  backToEventDetails: 'العودة إلى تفاصيل الحدث',
  removeAll: 'إزالة الكل',
  areYouSureYouWantToRemoveAllTickets: 'هل أنت متأكد أنك تريد إزالة جميع التذاكر؟',
  minQuantityPerOrderIs: 'الحد الأدنى للكمية في كل طلب هو',
  maxQuantityPerOrderIs: 'الحد الأقصى للكمية في كل طلب هو',
  aboutTheOrganizer: 'حول المنظم',
  viewOnMap: 'عرض على الخريطة',
  viewOrganizerEvents: 'عرض أحداث المنظم',
  invalidEmail: 'بريد إلكتروني غير صالح',
  invalidNumber: 'رقم غير صالح',
  unavailable: 'غير متوفر',
  available: 'متوفر',
  addons: 'إضافات',
  unlimited: 'غير محدود',
  noOrders: 'لا توجد طلبات',
  email: 'البريد الإلكتروني',
  termsOfService: 'شروط الخدمة',
  privacyPolicy: 'سياسة الخصوصية',
  privacyPolicyHint: 'إرشادات المجتمع وقد قرأت',
  backToEventTickets: 'العودة إلى تذاكر الحدث',
  step: 'خطوة',
  activities: 'الأنشطة',
  price: 'السعر',
  missingPaymentKeyMessage: 'تعذر معالجة الدفع الخاص بك. يرجى الاتصال بمسؤول الحدث الخاص بك.',
  sendVerificationCode:
    'لمتابعة التسجيل، سنحتاج إلى التحقق من عنوان بريدك الإلكتروني. أدخل بريدك الإلكتروني أدناه لتلقي رمز التحقق لمرة واحدة.',
  verifyYourEmail: 'تحقق من عنوان بريدك الإلكتروني',
  checkVerificationCode: 'لقد أرسلنا لك رمز التحقق لمرة واحدة. يرجى التحقق من بريدك الإلكتروني.',
  failedSendVerificationCode: 'فشل في إرسال رمز التحقق',
  back: 'العودة',
  remainingAttempts: 'المحاولات المتبقية',
  wrongVerificationCode: 'رمز التحقق غير صحيح. يرجى المحاولة مرة أخرى.',
  resendAnotherCode: 'إعادة إرسال رمز آخر',
  wrongEmailVerificationCode: 'البريد الإلكتروني أو رمز التحقق غير صحيح. يرجى المحاولة مرة أخرى.',
  aboutOrganizer: 'حول المنظم',
  proceedToCheckout: 'المتابعة إلى الخروج',
  ticket: 'تذكرة',
  saveAndAddAnotherTicket: 'حفظ وإضافة تذكرة أخرى',
  addToAppleWallet: 'إضافة إلى محفظة Apple',
  addToGoogleWallet: 'إضافة إلى محفظة Google',
  downloadFailed: 'فشل التنزيل',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'كل الحقوق محفوظة',
  fillFormWithInformation: 'املأ النموذج بمعلوماتي',
  shareEventHint: 'شارك هذا الحدث على وسائل التواصل الاجتماعي أو القنوات الأخرى.',
  favoriteEventHint:
    'الأحداث التي تفضلها تظهر تحت قلب في الزاوية اليمنى العلوية لسهولة الوصول. القلب الصلب يعني أنك قمت بتفضيل الحدث. القلب المحدد يعني أن الحدث لم يتم تفضيله. بعد التفضيل، انقر على القلب مرة أخرى لإزالته من قائمة التفضيلات الخاصة بك.',
  sendCode: 'إرسال الرمز',
  continue: 'متابعة',
  sendConfirmationEmail: 'إرسال تأكيد عبر البريد الإلكتروني إلى',
  specifyContactInfo: 'تحديد معلومات الاتصال',
  confirmationEmail: 'سيتم إرسال بريد إلكتروني تأكيدي يتضمن تذاكرك إلى هذا الاتصال',
  oops: 'عذرًا،',
  somethingWentWrong: 'حدث خطأ!',
  next: 'التالي',
  registration: 'التسجيل',
  includedItems: 'العناصر المضمنة',
  packages: 'الحزم',
  select: 'اختر',
  selected: 'محدد',
  categories: 'الفئات',
  all: 'الكل',
  noAddons: 'لا توجد إضافات',
  noActivities: 'لا توجد أنشطة',
  noSessions: 'لا توجد جلسات',
  noMoreAvailable: 'لا توجد مزيد من العروض',
  reachedMaxQuantityPerOrder: 'تم الوصول إلى الحد الأقصى للكمية لكل طلب',
  extraMinQuantityPerOrderIs: 'الحد الأدنى للكمية لكل طلب هو',
  didNotMeetMinQuantityPerOrder: 'لم يتم تحقيق الحد الأدنى للكمية لكل طلب',
  discountCode: 'رمز الخصم',
  remove: 'إزالة',
  apply: 'تطبيق',
  discount: 'خصم',
  totalPaid: 'المجموع المدفوع',
  ticketType: 'نوع التذكرة',
  sessions: 'الجلسات',
  speakers: 'المتحدثون',
  sponsors: 'الرعاة',
  aboutThisSession: 'حول هذه الجلسة',
  sessionDetails: 'تفاصيل الجلسة',
  myCart: 'سلتي',
  items: 'العناصر',
  confirmEmail: 'تأكيد البريد الإلكتروني',
  invalidEmailConfirmation: 'تأكيد البريد الإلكتروني غير صالح',
  addToCalendar: 'إضافة إلى التقويم',
  cartSummary: 'ملخص السلة',
  eventTickets: 'تذاكر الحدث',
  aboutEvent: 'حول الحدث',
  downloadTicket: 'تحميل التذكرة',
  addToWallet: 'إضافة إلى المحفظة',
  editRegistration: 'تعديل التسجيل',
  purchaseExtras: 'شراء إضافات',
  carts: 'عربات التسوق',
  organizerNotFound: 'المنظم غير موجود',
  extras: 'إضافات',
  registrationInfo: 'معلومات التسجيل',
  ticketsPurchasedForOthers: 'تم شراء التذاكر للآخرين',
  eventPurchasedTicketsInfo: 'لقد سجلت بالفعل لهذا الحدث',
  view: 'عرض',
  moreInfo: 'مزيد من المعلومات',
  maxQuantityPerOrderHasBeenReached: 'تم الوصول إلى الحد الأقصى لكمية الطلب',
  liveAndUpcomingEvents: 'الأحداث المباشرة والقادمة',
  brandName: 'كابتيلو',
  myOrders: 'طلباتي',
  save: 'حفظ',
  clear: 'مسح',
  edit: 'تعديل',
  verify: 'تحقق',
  change: 'تغيير',
  confirm: 'تأكيد',
  minLeftForExtend: 'دقائق متبقية للتمديد',
  cardNumber: 'رقم البطاقة',
  expiration: 'تاريخ انتهاء الصلاحية',
  cvv: 'CVV',
  submit: 'إرسال',
} as const
