import Popper from '@mui/material/Popper'
import Divider from '@mui/material/Divider'
import CheckIcon from '@mui/icons-material/Check'
import { Fade, Grid, Paper } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useState, useRef, SyntheticEvent, Fragment } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { LocaleCode } from '../enums'
import Button from './shared/ui/Button'
import { MenuItem } from './shared/ui/Menu'
import { LocalizationSettings } from '../types'
import { locales, useTranslation } from '../providers/localesProvider'

type Props = { localizationSettings: LocalizationSettings }

function LanguageMenu(props: Props) {
  const { localizationSettings } = props
  const { code, setLocale } = useTranslation()

  const [localCodeValue, setLocalCodeValue] = useState(code)

  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  const handleChange = (selectedOption: LocaleCode) => {
    if (selectedOption !== localCodeValue) {
      setLocalCodeValue(selectedOption)
      setLocale(selectedOption)
      window.location.reload()
    }
  }

  const languages = localizationSettings?.languages

  return (
    <>
      <Button ref={anchorRef} variant="text" onClick={handleToggle} sx={{ color: 'white' }}>
        {localCodeValue.toUpperCase()}
        <KeyboardArrowDownIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        sx={{ zIndex: 1 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper sx={{ minWidth: 150 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Grid>
                  {languages?.map((language, index) => (
                    <Fragment key={language.id}>
                      <MenuItem
                        disabled={!locales[language.code]}
                        sx={{ fontWeight: 'bold', fontSize: 14, m: 0 }}
                        selected={language.code === localCodeValue}
                        onClick={() => handleChange(language.code)}
                      >
                        <Grid container gap={1}>
                          <CheckIcon
                            color="primary"
                            sx={{ visibility: language.code === localCodeValue ? 'visible' : 'hidden' }}
                          />
                          {language.translatedName}
                        </Grid>
                      </MenuItem>
                      {index >= languages?.length - 1 ? null : <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />}
                    </Fragment>
                  ))}
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}
export default LanguageMenu
