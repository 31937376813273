export const spanishTranslation = {
  signIn: 'Iniciar sesión',
  signUp: 'Registrarse',
  search: 'Buscar',
  firstName: 'Nombre',
  lastName: 'Apellido',
  emailAddress: 'Correo electrónico',
  password: 'Contraseña',
  requiredField: 'Campo obligatorio',
  invalidValue: 'Valor no válido',
  fillUpFieldsToCompletePayment: 'Complete todos los campos para finalizar el pago',
  paymentFailed: 'Pago fallido',
  somethingWrong: 'Algo salió mal',
  paymentNotSuccessful: 'Su pago no se realizó con éxito, por favor inténtelo de nuevo',
  logInFailed: 'Inicio de sesión fallido',
  phone: 'Teléfono',
  address: 'Dirección',
  address1: 'Dirección 1',
  address2: 'Dirección 2',
  country: 'País',
  state: 'Estado',
  city: 'Ciudad',
  zipcode: 'Código postal',
  saveChanges: 'Guardar cambios',
  reset: 'Restablecer',
  saveChangesSuccess: 'Guardado',
  saveChangesFailed: 'Error al guardar los cambios',
  details: 'Detalles',
  aboutThisEvent: 'Acerca de este evento',
  tags: 'Etiquetas',
  findUsOn: 'Encuéntranos en',
  salesStarts: 'Inicio de ventas',
  dateAndTime: 'Fecha y hora',
  from: 'Desde',
  to: 'Hasta',
  location: 'Ubicación',
  profile: 'Perfil',
  favoriteEvents: 'Eventos favoritos',
  noEvents: 'Sin eventos',
  toggleFavoriteEventFailed: 'Error al alternar evento favorito',
  logout: 'Cerrar sesión',
  organizer: 'Organizador',
  eventNotFound: 'Evento no encontrado',
  tickets: 'Entradas',
  ticketsDetails: 'Detalles de las entradas',
  salesEndsOn: 'Las ventas finalizan el',
  fee: 'Tarifa',
  free: 'Gratis',
  soldOut: 'Agotado',
  salesStartsOn: 'Las ventas comienzan el',
  register: 'Registrarse',
  guest: 'Invitado',
  noTickets: 'Sin entradas',
  minsLeftForCheckout: 'minutos restantes para finalizar la compra',
  deleteCartItemSuccess: 'Elemento del carrito eliminado',
  deleteCartItemFailed: 'Error al eliminar el elemento del carrito',
  deleteTicketCartItemSuccess: 'Carrito vaciado',
  deleteTicketCartItemFailed: 'Error al eliminar elementos del carrito',
  total: 'Total',
  totalDue: 'Total a pagar',
  subTotal: 'Subtotal',
  update: 'Actualizar',
  cartExpired: 'Carrito caducado',
  checkout: 'Pagar',
  orderSummary: 'Resumen del pedido',
  contactInfo: 'Información de contacto',
  orderInfo: 'Información del pedido',
  name: 'Nombre',
  cardInfo: 'Información de la tarjeta',
  placeOrder: 'Realizar pedido',
  mobilePhone: 'Teléfono móvil',
  placeOrderSuccess: 'Pedido realizado',
  placeOrderFailed: 'Error al realizar el pedido',
  extend: 'Extender',
  cancel: 'Cancelar',
  extendEventCartExpirationSuccess: 'Caducidad del carrito extendida',
  extendEventCartExpirationFailed: 'Error al extender la caducidad del carrito',
  expirationExtendQuestion: '¿Le gustaría extender el tiempo de caducidad del carrito?',
  orders: 'Pedidos',
  myTickets: 'Mis entradas',
  upcomingEvents: 'Próximos eventos',
  pastEvents: 'Eventos pasados',
  eventDetails: 'Detalles del evento',
  summary: 'Resumen',
  events: 'Eventos',
  organizerEvents: 'Eventos del organizador',
  eventsSearchResultFor: 'Resultado de búsqueda de eventos para',
  viewAll: 'Ver todo',
  noTags: 'Sin etiquetas',
  backToEventDetails: 'Volver a los detalles del evento',
  removeAll: 'Eliminar todo',
  areYouSureYouWantToRemoveAllTickets: '¿Estás seguro de que quieres eliminar todos los tickets?',
  minQuantityPerOrderIs: 'Cantidad mínima por pedido es',
  maxQuantityPerOrderIs: 'Cantidad máxima por pedido es',
  aboutTheOrganizer: 'Acerca del organizador',
  viewOnMap: 'Ver en el mapa',
  viewOrganizerEvents: 'Ver eventos del organizador',
  invalidEmail: 'Correo electrónico no válido',
  invalidNumber: 'Número no válido',
  unavailable: 'No disponible',
  available: 'Disponible',
  addons: 'Extras',
  unlimited: 'Ilimitado',
  noOrders: 'Sin pedidos',
  email: 'Correo electrónico',
  termsOfService: 'Términos de servicio',
  privacyPolicy: 'política de privacidad',
  privacyPolicyHint: 'normas de la comunidad y he leído las',
  backToEventTickets: 'Volver a los tickets del evento',
  step: 'Paso',
  activities: 'Actividades',
  price: 'Precio',
  missingPaymentKeyMessage: 'No se puede procesar su pago. Por favor, contacte al administrador del evento.',
  sendVerificationCode:
    'Para continuar con el registro, necesitaremos verificar su dirección de correo electrónico. Ingrese su correo electrónico a continuación para recibir un código de verificación único.',
  verifyYourEmail: 'Verifique su dirección de correo electrónico',
  checkVerificationCode: 'Le hemos enviado su código de verificación único. Por favor, revise su correo electrónico.',
  failedSendVerificationCode: 'Error al enviar el código de verificación',
  back: 'Volver',
  remainingAttempts: 'Intentos restantes',
  wrongVerificationCode: 'Código de verificación incorrecto. Por favor, inténtelo de nuevo.',
  resendAnotherCode: 'Reenviar otro código',
  wrongEmailVerificationCode: 'Correo electrónico o código de verificación incorrecto. Por favor, inténtelo de nuevo.',
  aboutOrganizer: 'Acerca del organizador',
  proceedToCheckout: 'Continuar con la compra',
  ticket: 'Ticket',
  saveAndAddAnotherTicket: 'Guardar y agregar otro ticket',
  addToAppleWallet: 'Agregar a Apple Wallet',
  addToGoogleWallet: 'Agregar a Google Wallet',
  downloadFailed: 'Error al descargar',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'Todos los derechos reservados',
  fillFormWithInformation: 'Completar formulario con mi información',
  shareEventHint: 'Compartir este evento en redes sociales u otros canales.',
  favoriteEventHint:
    'Los eventos que marques como favoritos aparecerán con un corazón en la esquina superior derecha para un acceso más fácil. Un corazón sólido significa que has marcado el evento como favorito. Un corazón delineado significa que el evento no está marcado como favorito. Después de marcarlo como favorito, haz clic en el corazón nuevamente para quitarlo de tus favoritos.',
  sendCode: 'enviar código',
  continue: 'continuar',
  sendConfirmationEmail: 'Enviar confirmación por correo electrónico a',
  specifyContactInfo: 'Especificar información de contacto',
  confirmationEmail: 'Se enviará un correo electrónico de confirmación que incluye tus entradas a este contacto',
  oops: '¡Ups!',
  somethingWentWrong: '¡Algo salió mal!',
  next: 'Siguiente',
  registration: 'Registro',
  includedItems: 'Ítems incluidos',
  packages: 'Paquetes',
  select: 'Seleccionar',
  selected: 'Seleccionado',
  categories: 'Categorías',
  all: 'Todos',
  noAddons: 'Sin complementos',
  noActivities: 'Sin actividades',
  noSessions: 'Sin sesiones',
  noMoreAvailable: 'No hay más disponibles',
  reachedMaxQuantityPerOrder: 'Cantidad máxima por pedido alcanzada',
  extraMinQuantityPerOrderIs: 'cantidad mínima por pedido es',
  didNotMeetMinQuantityPerOrder: 'No se cumplió con la cantidad mínima por pedido',
  discountCode: 'Código de descuento',
  remove: 'Eliminar',
  apply: 'Aplicar',
  discount: 'Descuento',
  totalPaid: 'Total pagado',
  ticketType: 'Tipo de entrada',
  sessions: 'Sesiones',
  speakers: 'Oradores',
  sponsors: 'Patrocinadores',
  aboutThisSession: 'Acerca de esta sesión',
  sessionDetails: 'Detalles de la sesión',
  myCart: 'Mi Carrito',
  items: 'Ítems',
  confirmEmail: 'Confirmar correo electrónico',
  invalidEmailConfirmation: 'Confirmación de correo electrónico inválida',
  addToCalendar: 'Agregar al calendario',
  cartSummary: 'Resumen del carrito',
  eventTickets: 'Entradas para el evento',
  aboutEvent: 'Acerca del evento',
  downloadTicket: 'Descargar boleto',
  addToWallet: 'Agregar a la billetera',
  editRegistration: 'Editar registro',
  purchaseExtras: 'Comprar extras',
  carts: 'Carritos',
  organizerNotFound: 'Organizador no encontrado',
  extras: 'Extras',
  registrationInfo: 'Información de registro',
  ticketsPurchasedForOthers: 'Boletos comprados para otros',
  eventPurchasedTicketsInfo: 'Ya te has registrado para este evento',
  view: 'ver',
  moreInfo: 'Más información',
  maxQuantityPerOrderHasBeenReached: 'Se ha alcanzado la cantidad máxima por pedido',
  myOrders: 'Mis pedidos',
  liveAndUpcomingEvents: 'Eventos en vivo y próximos',
  brandName: 'Captello',
  save: 'Guardar',
  clear: 'Limpiar',
  edit: 'Editar',
  verify: 'Verificar',
  change: 'Cambiar',
  confirm: 'Confirmar',
  minLeftForExtend: 'minutos restantes para extender',
  cardNumber: 'Número de tarjeta',
  expiration: 'Fecha de expiración',
  cvv: 'CVV',
  submit: 'Enviar',
} as const
