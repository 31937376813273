import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiMenuList from '@mui/material/MenuList'
import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react'

export const MenuItem = forwardRef<ElementRef<typeof MuiMenuItem>, ComponentPropsWithoutRef<typeof MuiMenuItem>>(
  ({ ...props }, ref) => {
    return <MuiMenuItem ref={ref} {...props} />
  }
)
MenuItem.displayName = 'MenuItem'

export const MenuList = forwardRef<ElementRef<typeof MuiMenuList>, ComponentPropsWithoutRef<typeof MuiMenuList>>(
  ({ ...props }, ref) => {
    return <MenuList ref={ref} {...props} />
  }
)
MenuList.displayName = 'MenuList'

export const Menu = forwardRef<ElementRef<typeof MuiMenu>, ComponentPropsWithoutRef<typeof MuiMenu>>(
  ({ ...props }, ref) => {
    return <MuiMenu ref={ref} {...props} />
  }
)
Menu.displayName = 'MuiMenu'
