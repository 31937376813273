import Grid from '@mui/material/Grid'
import { ReactElement } from 'react'

import { useBrandQuery } from '../../queryHooks/metaQuery'

import Image from './ui/Image'

const sizesMap = {
  small: 25,
  medium: 50,
  large: 75,
  xlarge: 100,
}

export type SpinnerProps = {
  size?: keyof typeof sizesMap
}

function Spinner({ size = 'small' }: SpinnerProps): ReactElement {
  const { data: ssrBrands } = useBrandQuery()
  const l = sizesMap[size]

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Image
        src={ssrBrands?.loadingIcon ?? '/images/captello_css_only.svg'}
        alt="Loading spinner"
        width={l}
        height={l}
      />
    </Grid>
  )
}

export default Spinner
