import { useEffect, useState } from 'react'
import { Router } from 'next/router'
import { LinearProgress } from '@mui/material'

function RoutingProgressBar() {
  const [isRouting, setIsRouting] = useState(false)

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      setIsRouting(true)
    })
    Router.events.on('routeChangeComplete', () => {
      setIsRouting(false)
    })

    Router.events.on('routeChangeError', () => {
      setIsRouting(false)
    })

    return () => {
      Router.events.off('routeChangeError', () => {
        setIsRouting(false)
      })
      Router.events.off('routeChangeComplete', () => {
        setIsRouting(false)
      })
      Router.events.off('routeChangeStart', () => {
        setIsRouting(false)
      })
    }
  }, [])

  return isRouting ? (
    <LinearProgress color="primary" sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }} />
  ) : null
}
export default RoutingProgressBar
