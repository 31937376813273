import NextImage, { ImageProps } from 'next/image'

type Props = ImageProps & {
  src: string
  alt: string
  width?: number
  height?: number
}

function Image(props: Props) {
  const { src, alt, width, height, ...rest } = props
  return <NextImage sizes="100vw" src={src} alt={alt} width={width} height={height} {...rest} />
}

export default Image
