export const frTranslation = {
  signIn: 'Se connecter',
  signUp: "S'inscrire",
  search: 'Chercher',
  firstName: 'Prénom',
  lastName: 'Nom',
  emailAddress: 'Adresse courriel',
  password: 'Mot de passe',
  requiredField: 'Champ obligatoire',
  invalidValue: 'Valeur invalide',
  fillUpFieldsToCompletePayment: 'Remplissez tous les champs pour finaliser le paiement',
  paymentFailed: 'Paiement échoué',
  somethingWrong: 'Un problème est survenu.',
  paymentNotSuccessful: "Votre paiement n'a pas été effectué. Veuillez réessayer.",
  logInFailed: 'Échec de la connexion',
  phone: 'Téléphone',
  address: 'Adresse',
  address1: 'Adresse 1',
  address2: 'Adresse 2',
  country: 'Pays',
  state: 'État',
  city: 'Ville',
  zipcode: 'Code postal',
  saveChanges: 'Enregistrer les modifications',
  reset: 'Réinitialiser',
  saveChangesSuccess: 'Enregistré',
  saveChangesFailed: 'Échec de la sauvegarde des modifications',
  details: 'Détails',
  aboutThisEvent: 'À propos de cet événement',
  tags: 'Mots-clés',
  findUsOn: 'Retrouvez-nous sur',
  salesStarts: 'Début des ventes',
  dateAndTime: 'Date et heure',
  from: 'Du',
  to: 'Au',
  location: 'Emplacement',
  profile: 'Profil',
  favoriteEvents: 'Événements favoris',
  noEvents: 'Aucun événement',
  toggleFavoriteEventFailed: "Échec de l'ajout/retrait des favoris",
  logout: 'Se déconnecter',
  organizer: 'Organisateur',
  eventNotFound: 'Événement introuvable',
  tickets: 'Billets',
  ticketsDetails: 'Détails des billets',
  salesEndsOn: 'Fin des ventes',
  fee: 'Frais',
  free: 'Gratuit',
  soldOut: 'Épuisé',
  salesStartsOn: 'Début des ventes',
  register: "S'inscrire",
  guest: 'Invité',
  noTickets: 'Aucun billet',
  minsLeftForCheckout: 'minutes restantes pour le paiement',
  deleteCartItemSuccess: 'Article du panier supprimé',
  deleteCartItemFailed: "Échec de la suppression de l'article du panier",
  deleteTicketCartItemSuccess: 'Panier vidé',
  deleteTicketCartItemFailed: 'Échec de la suppression des articles du panier',
  total: 'Total',
  totalDue: 'Total dû',
  subTotal: 'Sous-total',
  update: 'Mettre à jour',
  cartExpired: 'Panier expiré',
  checkout: 'Paiement',
  orderSummary: 'Résumé de la commande',
  contactInfo: 'Coordonnées',
  orderInfo: 'Informations de commande',
  name: 'Nom',
  cardInfo: 'Informations de carte',
  placeOrder: 'Passer la commande',
  mobilePhone: 'Téléphone portable',
  placeOrderSuccess: 'Commande passée',
  placeOrderFailed: 'Échec de la commande',
  extend: 'Prolonger',
  cancel: 'Annuler',
  extendEventCartExpirationSuccess: 'Expiration du panier prolongée',
  extendEventCartExpirationFailed: "Échec de la prolongation de l'expiration du panier",
  expirationExtendQuestion: "Souhaitez-vous prolonger l'expiration du panier ?",
  orders: 'Commandes',
  myTickets: 'Mes billets',
  upcomingEvents: 'Événements à venir',
  pastEvents: 'Événements passés',
  eventDetails: "Détails de l'événement",
  summary: 'Résumé',
  events: 'Événements',
  organizerEvents: "Événements de l'organisateur",
  eventsSearchResultFor: "Résultats de recherche d'événements pour",
  viewAll: 'Tout voir',
  noTags: 'Aucun mot-clé',
  backToEventDetails: "Retour aux détails de l'événement",
  removeAll: 'Supprimer tout',
  areYouSureYouWantToRemoveAllTickets: 'Êtes-vous sûr de vouloir supprimer tous les billets ?',
  minQuantityPerOrderIs: 'La quantité minimale par commande est de',
  maxQuantityPerOrderIs: 'La quantité maximale par commande est de',
  aboutTheOrganizer: "À propos de l'organisateur",
  viewOnMap: 'Voir sur la carte',
  viewOrganizerEvents: "Voir les événements de l'organisateur",
  invalidEmail: 'Adresse e-mail invalide',
  invalidNumber: 'Numéro invalide',
  unavailable: 'Indisponible',
  available: 'Disponible',
  addons: 'Compléments',
  unlimited: 'Illimité',
  noOrders: 'Aucune commande',
  email: 'Courriel',
  termsOfService: "Conditions d'utilisation",
  privacyPolicy: 'Politique de confidentialité',
  privacyPolicyHint: 'directives de la communauté et lu la',
  backToEventTickets: "Retour aux billets de l'événement",
  step: 'Étape',
  activities: 'Activités',
  price: 'Prix',
  missingPaymentKeyMessage:
    "Impossible de traiter votre paiement. Veuillez contacter l'administrateur de votre événement.",
  sendVerificationCode:
    "Pour poursuivre l'inscription, nous devrons vérifier votre adresse e-mail. Saisissez votre adresse e-mail ci-dessous pour recevoir un code de vérification unique.",
  verifyYourEmail: 'Vérifiez votre adresse e-mail',
  checkVerificationCode:
    'Nous vous avons envoyé votre code de vérification unique. Veuillez consulter votre messagerie.',
  failedSendVerificationCode: "Échec de l'envoi du code de vérification",
  back: 'Retour',
  remainingAttempts: 'Tentatives restantes',
  wrongVerificationCode: 'Code de vérification incorrect. Veuillez réessayer.',
  resendAnotherCode: 'Renvoyer un autre code',
  wrongEmailVerificationCode: 'E-mail ou code de vérification incorrect. Veuillez réessayer.',
  aboutOrganizer: "À propos de l'organisateur",
  proceedToCheckout: 'Completer l’inscription',
  ticket: 'Billet',
  saveAndAddAnotherTicket: 'Enregistrer et ajouter un autre billet',
  addToAppleWallet: 'Ajouter à Apple Wallet',
  addToGoogleWallet: 'Ajouter à Google Wallet',
  downloadFailed: 'Téléchargement échoué',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'Tous droits réservés',
  fillFormWithInformation: 'Remplir le formulaire avec mes informations',
  shareEventHint: "Partager cet événement sur les réseaux sociaux ou d'autres canaux.",
  favoriteEventHint:
    "Les événements que vous ajoutez à vos favoris apparaissent sous un cœur dans le coin supérieur droit pour un accès plus facile. Un cœur plein signifie que vous avez ajouté l'événement à vos favoris. Un cœur vide signifie que l'événement n'est pas favori. Après avoir ajouté un événement à vos favoris, cliquez à nouveau sur le cœur pour le retirer de vos favoris.",
  sendCode: 'envoyer le code',
  continue: 'continuer',
  sendConfirmationEmail: 'Envoyer un courriel de confirmation à',
  specifyContactInfo: 'Spécifier les coordonnées',
  confirmationEmail: 'Un e-mail de confirmation incluant vos billets vous sera envoyé à ce contact',
  oops: 'Oups,',
  next: 'Suivant',
  registration: 'Inscription',
  includedItems: 'Articles inclus',
  packages: 'Forfaits',
  select: 'Sélectionner',
  selected: 'Sélectionné',
  categories: 'Catégories',
  all: 'Tous',
  noAddons: 'Aucun complément',
  noActivities: 'Aucune activité',
  noSessions: 'Aucune session',
  noMoreAvailable: 'Plus disponible',
  reachedMaxQuantityPerOrder: 'Quantité maximale par commande atteinte',
  extraMinQuantityPerOrderIs: 'La quantité minimale par commande est de',
  didNotMeetMinQuantityPerOrder: "La quantité minimale par commande n'est pas atteinte",
  discountCode: 'Code de réduction',
  remove: 'Supprimer',
  apply: 'Appliquer',
  discount: 'Réduction',
  totalPaid: 'Total payé',
  ticketType: 'Type de billet',
  sessions: 'Sessions',
  speakers: 'Intervenants',
  sponsors: 'Sponsors',
  aboutThisSession: 'À propos de cette session',
  sessionDetails: 'Détails de la session',
  myCart: 'Mon panier',
  items: 'Articles',
  confirmEmail: 'Confirmer votre courriel',
  invalidEmailConfirmation: "Confirmation d'e-mail invalide",
  addToCalendar: 'Ajouter au calendrier',
  cartSummary: 'Résumé du panier',
  eventTickets: "Billets de l'événement",
  aboutEvent: "À propos de l'événement",
  downloadTicket: 'Télécharger le billet',
  addToWallet: 'Ajouter au portefeuille',
  editRegistration: "Modifier l'inscription",
  purchaseExtras: 'Acheter des extras',
  carts: ' Paniers',
  organizerNotFound: 'Organisateur introuvable',
  extras: 'Extras',
  registrationInfo: "Informations d'inscription",
  ticketsPurchasedForOthers: "Billets achetés pour d'autres personnes",
  eventPurchasedTicketsInfo: 'Vous êtes déjà inscrit à cet événement',
  view: 'voir',
  moreInfo: "Plus d'infos",
  maxQuantityPerOrderHasBeenReached: 'La quantité maximale par commande a été atteinte',
  myOrders: 'Mes commandes',
  brandName: 'Captello',
  liveAndUpcomingEvents: 'Événements en direct et à venir',
  save: 'Enregistrer',
  clear: 'éclaircir',
  edit: 'éditer',
  verify: 'vérifier',
  change: 'changer',
  confirm: 'confirmer',
  minLeftForExtend: 'minutes restantes pour prolonger',
  cardNumber: 'Numéro de carte',
  expiration: "Date d'expiration",
  cvv: 'CVV',
  submit: 'Soumettre',
} as const
