import { useState, FC } from 'react'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import { emptyFunc } from '../../helpers/common'

import { AuthenticationMode } from '../../enums'
import LimitedLoginForm from './LimitedLoginForm'
import { useAuth } from '../../providers/authProvider'
import OtpVerificationForm from './OtpVerificationForm'
import { useTranslation } from '../../providers/localesProvider'
import EmailVerificationForm from './EmailVerificationForm'
import { useEmailVerificationSettingsQuery } from '../../queryHooks/metaQuery'

const LoginDialog: FC<{ open?: boolean; isVerified?: boolean; handleClose?: () => void }> = ({
  open = false,
  handleClose = emptyFunc,
  isVerified = false,
}) => {
  const { t } = useTranslation()

  const [user, setUser] = useState<any>(null)
  const [activeStep, setActiveStep] = useState<1 | 2>(1)
  const { isAuthenticated } = useAuth()
  const { data: emailVerificationSettings, isLoading } = useEmailVerificationSettingsQuery({
    enabled: !isAuthenticated(),
  })
  const { emailVerificationPromptTitle = t('verifyYourEmail') } = emailVerificationSettings || {}

  const authenticationMode = emailVerificationSettings?.authenticationMode

  const handleNext = (user: any): void => {
    setUser(user)
    setActiveStep(2)
  }
  const handleBack = (): void => setActiveStep(1)

  const handleCloseDialog = () => {
    handleClose()
    setActiveStep(1)
  }

  return !isLoading ? (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle>
        <Typography sx={{ fontWeight: 800, fontSize: 18 }}>{emailVerificationPromptTitle}</Typography>
        <IconButton
          aria-label="close"
          sx={{ position: 'absolute', right: '8px', top: '8px', color: '#9E9E9E' }}
          onClick={handleCloseDialog}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container item sx={{ width: 400 }} justifyContent="center">
            {authenticationMode === AuthenticationMode.limited && !isVerified ? (
              <LimitedLoginForm onLoggedIn={handleClose} />
            ) : activeStep === 1 ? (
              <EmailVerificationForm nextStep={handleNext} user={user} />
            ) : (
              <OtpVerificationForm
                handleBack={handleBack}
                user={user}
                setUser={setUser}
                onLoggedIn={handleCloseDialog}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null
}

export default LoginDialog
