export const enTranslation = {
  signIn: 'Sign In',
  signUp: 'Sign Up',
  search: 'Search',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailAddress: 'Email Address',
  password: 'Password',
  requiredField: 'Required Field',
  invalidValue: 'Invalid Value',
  fillUpFieldsToCompletePayment: 'Fill in all fields to complete payment',
  paymentFailed: 'Payment failed',
  somethingWrong: 'Something went wrong.',
  paymentNotSuccessful: 'Your payment was not successful, please try again.',
  logInFailed: 'Login failed',
  phone: 'Phone',
  address: 'Address',
  address1: 'Address 1',
  address2: 'Address 2',
  country: 'Country',
  state: 'State',
  city: 'City',
  zipcode: 'Zipcode',
  saveChanges: 'Save Changes',
  reset: 'Reset',
  saveChangesSuccess: 'Saved',
  saveChangesFailed: 'Failed to save changes',
  details: 'Details',
  aboutThisEvent: 'About this event',
  tags: 'Tags',
  findUsOn: 'Find us on',
  salesStarts: 'Sales starts',
  dateAndTime: 'Date and Time',
  from: 'From',
  to: 'To',
  location: 'Location',
  profile: 'Profile',
  favoriteEvents: 'Favorite Events',
  noEvents: 'No Events',
  toggleFavoriteEventFailed: 'Failed to toggle favorite event',
  logout: 'Logout',
  organizer: 'Organizer',
  eventNotFound: 'Event not found',
  tickets: 'Tickets',
  ticketsDetails: 'Tickets Details',
  salesEndsOn: 'Sales ends on',
  fee: 'Fee',
  free: 'Free',
  soldOut: 'Sold out',
  salesStartsOn: 'Sales starts on',
  register: 'Register',
  guest: 'Guest',
  noTickets: 'No Tickets',
  minsLeftForCheckout: 'mins left for checkout',
  deleteCartItemSuccess: 'Cart item deleted',
  deleteCartItemFailed: 'Failed to delete item in cart',
  deleteTicketCartItemSuccess: 'Cart emptied',
  deleteTicketCartItemFailed: 'Failed to delete items in cart',
  total: 'Total',
  totalDue: 'Total Due',
  subTotal: 'Sub-Total',
  update: 'Update',
  cartExpired: 'Cart expired',
  checkout: 'Checkout',
  orderSummary: 'Order Summary',
  contactInfo: 'Contact Information',
  orderInfo: 'Order Information',
  name: 'Name',
  cardInfo: 'Card Information',
  placeOrder: 'Place Order',
  mobilePhone: 'Mobile Phone',
  placeOrderSuccess: 'Order placed',
  placeOrderFailed: 'Failed to place order',
  extend: 'Extend',
  cancel: 'Cancel',
  extendEventCartExpirationSuccess: 'Cart expiration extended',
  extendEventCartExpirationFailed: 'Failed to extend cart expiration',
  expirationExtendQuestion: 'Would you like to extend the cart expiration time?',
  orders: 'Orders',
  myTickets: 'My Tickets',
  upcomingEvents: 'Upcoming Events',
  pastEvents: 'Past Events',
  eventDetails: 'Event details',
  summary: 'Summary',
  events: 'Events',
  organizerEvents: "Organizer's events",
  eventsSearchResultFor: 'Events search result for',
  viewAll: 'View All',
  noTags: 'No tags',
  backToEventDetails: 'Back to event details',
  removeAll: 'Remove all',
  areYouSureYouWantToRemoveAllTickets: 'Are you sure you want to remove all tickets?',
  minQuantityPerOrderIs: 'Min quantity per order is',
  maxQuantityPerOrderIs: 'Max quantity per order is',
  aboutTheOrganizer: 'About the Organizer',
  viewOnMap: 'View on map',
  viewOrganizerEvents: "View organizer's events",
  invalidEmail: 'Invalid email',
  invalidNumber: 'Invalid number',
  unavailable: 'Unavailable',
  available: 'Available',
  addons: 'Add-Ons',
  unlimited: 'Unlimited',
  noOrders: 'No orders',
  email: 'Email',
  termsOfService: 'Terms of service',
  privacyPolicy: 'privacy policy',
  privacyPolicyHint: 'community guidelines and have read the',
  backToEventTickets: 'Back to event tickets',
  step: 'Step',
  activities: 'Activities',
  price: 'Price',
  missingPaymentKeyMessage: 'Unable to process your payment. Please contact your event administrator.',
  sendVerificationCode:
    'To continue with registration, we will need to verify your email address. Enter your email below to receive a one-time verification code.',
  verifyYourEmail: 'Verify your email address',
  checkVerificationCode: "We've sent you your one-time verification code. Please check your email.",
  failedSendVerificationCode: 'Failed to send verification code',
  back: 'Back',
  remainingAttempts: 'Remaining Attempts',
  wrongVerificationCode: 'Incorrect verification code. Please try again.',
  resendAnotherCode: 'Re-send another code',
  wrongEmailVerificationCode: 'Incorrect email or verification code. Please try again.',
  aboutOrganizer: 'About organizer',
  proceedToCheckout: 'Proceed to Checkout',
  ticket: 'Ticket',
  saveAndAddAnotherTicket: 'Save and Add another ticket',
  addToAppleWallet: 'Add to Apple wallet',
  addToGoogleWallet: 'Add to Google wallet',
  downloadFailed: 'Failed to download',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'All Rights Reserved',
  fillFormWithInformation: 'Fill form with my information',
  shareEventHint: 'Share this event on social media or other channels.',
  favoriteEventHint:
    "Events that you favorite appear under a heart in the top right corner for easier access. A solid heart means you've favorited the event. An outlined heart means the event is not favorited. After favoriting, click the heart again to remove it from your favorites.",
  sendCode: 'send code',
  continue: 'continue',
  sendConfirmationEmail: 'Send confirmation email to',
  specifyContactInfo: 'Specify contact information',
  confirmationEmail: 'A confirmation email that includes your tickets will be sent to this contact',
  oops: 'Oops,',
  next: 'Next',
  registration: 'Registration',
  includedItems: 'Included Items',
  packages: 'Packages',
  select: 'Select',
  selected: 'Selected',
  categories: 'Categories',
  all: 'All',
  noAddons: 'No Addons',
  noActivities: 'No Activities',
  noSessions: 'No Sessions',
  noMoreAvailable: 'No More Available',
  reachedMaxQuantityPerOrder: 'Reached max quantity per order',
  extraMinQuantityPerOrderIs: 'min quantity per order is',
  didNotMeetMinQuantityPerOrder: 'Did not meet min quantity per order',
  discountCode: 'Discount code',
  remove: 'Remove',
  apply: 'Apply',
  discount: 'Discount',
  totalPaid: 'Total Paid',
  ticketType: 'Ticket Type',
  sessions: 'Sessions',
  speakers: 'Speakers',
  sponsors: 'Sponsors',
  aboutThisSession: 'About this session',
  sessionDetails: 'Session Details',
  myCart: 'My Cart',
  items: 'Items',
  confirmEmail: 'Confirm Email',
  invalidEmailConfirmation: 'Invalid Email confirmation',
  addToCalendar: 'Add to calendar',
  cartSummary: 'Cart Summary',
  eventTickets: 'Event Tickets',
  aboutEvent: 'About Event',
  downloadTicket: 'Download ticket',
  addToWallet: 'Add to wallet',
  editRegistration: 'Edit Registration',
  purchaseExtras: 'Purchase Extras',
  carts: 'Carts',
  organizerNotFound: 'Organizer not found',
  extras: 'Extras',
  registrationInfo: 'Registration info',
  ticketsPurchasedForOthers: 'Tickets Purchased for Others',
  eventPurchasedTicketsInfo: 'You’ve already registered for this event ',
  view: 'view',
  moreInfo: 'More Info',
  maxQuantityPerOrderHasBeenReached: 'Max quantity per order has been reached',
  myOrders: 'My Orders',
  liveAndUpcomingEvents: 'Live & Upcoming Events',
  brandName: 'Captello',
  save: 'Save',
  clear: 'Clear',
  edit: 'Edit',
  verify: 'Verify',
  change: 'Change',
  confirm: 'Confirm',
  minLeftForExtend: 'mins left for extend',
  cardNumber: 'Card Number',
  expiration: 'Expiration',
  cvv: 'CVV',
  submit: 'Submit',
} as const
