import _merge from 'lodash/merge'
import { AxiosError, AxiosRequestConfig } from 'axios'
import {
  useQuery,
  useMutation,
  queryOptions,
  useQueryClient,
  useInfiniteQuery,
  infiniteQueryOptions,
  InfiniteData,
  UseQueryResult,
  UseQueryOptions,
  MutationFunction,
  UseMutationResult,
  InfiniteQueryObserverResult,
  UndefinedInitialDataOptions,
} from '@tanstack/react-query'

import { IPagedData, HexEvent, HexEventFavorite } from '../types'
import { getEventById, getEvents, getPaginatedEvents, searchEvents } from '../services/eventService'
import { EventInterval, EventsSearchType } from '../enums'
import { userMetaQueryKey } from './metaQuery'
import axios from '../helpers/axios'

export const eventQueryKey = 'event'
export const eventsQueryKey = 'events'
export const eventsPaginatedQueryKey = 'events-paginated'
const favoriteQueryKey = 'favorite-events'
const organizerEventsQueryKey = 'organizer-events'
export const searchQueryKey = 'search-events'

const itemsPerPage = 10
const searchItemsPerPage = 2

interface PostFavoriteEventVars {
  isUserFavouriteEvent: boolean
  eventUuid: string
}

export const eventQueryOptions = (
  {
    eventUuid,
    params,
    headers,
  }: {
    eventUuid: string
    params?: Record<string, string | string[] | undefined>
    headers?: AxiosRequestConfig['headers']
  },
  options?: UndefinedInitialDataOptions<HexEvent>
) => {
  return queryOptions({
    queryKey: [eventQueryKey, { eventId: eventUuid }],
    queryFn: () => getEventById(eventUuid, { params, headers }).then((res) => res.data),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...options,
  })
}

export const eventsQueryOptions = (
  {
    isFeatured,
    headers,
  }: {
    isFeatured?: boolean
    headers?: AxiosRequestConfig['headers']
  },
  options?: UndefinedInitialDataOptions<HexEvent[]>
) => {
  return queryOptions({
    queryKey: [eventsQueryKey, { isFeatured }],
    queryFn: () => getEvents(isFeatured, { headers }).then((res) => res.data),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...options,
  })
}

export const eventsInfiniteQueryOptions = ({
  interval,
  isFeatured,
  requestConfig,
}: {
  interval: EventInterval
  isFeatured?: boolean
  requestConfig?: AxiosRequestConfig
}) =>
  infiniteQueryOptions({
    queryKey: [eventsPaginatedQueryKey, { interval, isFeatured }],
    initialPageParam: { offset: 0 },
    gcTime: Infinity,
    queryFn: ({ pageParam, signal }) =>
      getPaginatedEvents({
        offset: pageParam.offset,
        itemsPerPage,
        interval,
        isFeatured,
        config: { signal, ...requestConfig },
      }).then((res) => res.data),
    staleTime: Infinity,
    getNextPageParam: (lastResult) => {
      const { data, offset, itemsPerPage } = lastResult
      return data && data.length === itemsPerPage ? { offset: offset + itemsPerPage } : undefined
    },
  })

export function useEvent(
  { eventUuid, params }: { eventUuid: string; params?: Record<string, string | string[] | undefined> },
  options?: Partial<UseQueryOptions<HexEvent>>
): UseQueryResult<HexEvent> {
  // can't pass options to eventQueryOptions !?
  return useQuery({ ...eventQueryOptions({ eventUuid, params }), ...options })
}

export function useEvents(
  { isFeatured }: { isFeatured: boolean },
  options?: Partial<UseQueryOptions<HexEvent[]>>
): UseQueryResult<HexEvent[]> {
  // can't pass options to eventQueryOptions !?
  return useQuery({ ...eventsQueryOptions({ isFeatured }), ...options })
}

export const useInfiniteEvents = (interval: EventInterval, isFeatured?: boolean) =>
  useInfiniteQuery(eventsInfiniteQueryOptions({ interval, isFeatured }))

export function useInfiniteFavoriteEvents() {
  const getNextPageParam = (lastResult: IPagedData<HexEventFavorite>) => {
    const { data, offset, itemsPerPage } = lastResult
    return data && data.length === itemsPerPage ? { offset: offset + itemsPerPage } : undefined
  }

  const config = infiniteQueryOptions({
    queryKey: [favoriteQueryKey],
    queryFn: ({ pageParam, signal }) =>
      axios
        .get(`/event-favourites/me`, { params: { offset: pageParam?.offset, itemsPerPage: itemsPerPage }, signal })
        .then((res) => res.data),
    initialPageParam: { offset: 0 },
    refetchOnWindowFocus: true,
    getNextPageParam,
  })

  return useInfiniteQuery(config)
}

export function usePostFavoriteEvent(): UseMutationResult<
  HexEventFavorite,
  AxiosError,
  PostFavoriteEventVars,
  Partial<HexEventFavorite>
> {
  const queryClient = useQueryClient()
  const mutationFn: MutationFunction<HexEventFavorite, PostFavoriteEventVars> = ({
    isUserFavouriteEvent,
    eventUuid,
  }: PostFavoriteEventVars) =>
    axios.post(`/event-favourites/me`, { isUserFavouriteEvent, eventUuid }).then((res) => res.data)

  return useMutation({
    mutationFn,
    async onMutate({ isUserFavouriteEvent, eventUuid }) {
      await queryClient.cancelQueries({ queryKey: eventQueryOptions({ eventUuid }).queryKey })
      const prevEventData = queryClient.getQueryData(eventQueryOptions({ eventUuid }).queryKey)
      if (prevEventData) {
        queryClient.setQueryData(eventQueryOptions({ eventUuid }).queryKey, { ...prevEventData, isUserFavouriteEvent })
        // pass context to onError & onSettled
        return { event: prevEventData }
      }
    },
    async onSuccess(event) {
      queryClient.invalidateQueries({ queryKey: [userMetaQueryKey] }).then()
      const data = queryClient.getQueryData(eventQueryOptions({ eventUuid: event.event.uuid }).queryKey)

      if (data) {
        queryClient.setQueryData(eventQueryOptions({ eventUuid: event.event.uuid }).queryKey, _merge(data, event.event))
      }
    },
    onError(_, { eventUuid }, context) {
      if (context?.event) {
        queryClient.setQueryData(eventQueryOptions({ eventUuid }).queryKey, context?.event)
      }
    },
    async onSettled() {
      await queryClient.invalidateQueries({ queryKey: [favoriteQueryKey] })
    },
  })
}

export function useInfiniteOrganizerEvents(
  organizerId?: string
): InfiniteQueryObserverResult<InfiniteData<IPagedData<HexEvent>>> {
  const getNextPageParam = (lastResult: IPagedData<HexEvent>) => {
    const { data, offset, itemsPerPage } = lastResult
    return data && data.length === itemsPerPage ? { offset: offset + itemsPerPage } : undefined
  }

  const config = infiniteQueryOptions({
    queryKey: [organizerEventsQueryKey, { organizerId }],
    queryFn: ({ pageParam, signal }) =>
      axios
        .get(`/events`, {
          params: { offset: pageParam?.offset, itemsPerPage: itemsPerPage, 'organizer.uuid': organizerId },
          signal,
        })
        .then((res) => res.data),
    refetchOnWindowFocus: true,
    getNextPageParam,
    initialPageParam: { offset: 0 },
  })

  return useInfiniteQuery(config)
}

export const searchEventQueryOptions = (
  query: string,
  searchType: EventsSearchType,
  headers?: AxiosRequestConfig['headers']
) => {
  const searchQuery = searchType === EventsSearchType.Tag ? { tag: query } : { title: query }

  return infiniteQueryOptions({
    queryKey: [`${searchType}-${query}`, searchQuery],
    queryFn: ({ pageParam }) =>
      searchEvents(searchQuery, pageParam?.offset, itemsPerPage, { headers }).then((res) => res.data),
    refetchOnWindowFocus: true,
    getNextPageParam: (lastResult) => {
      const { data, offset, itemsPerPage } = lastResult
      return data && data.length === itemsPerPage ? { offset: offset + itemsPerPage } : undefined
    },
    initialPageParam: { offset: 0 },
  })
}

export function useInfiniteSearchEvents(
  query: string,
  searchType: EventsSearchType
): InfiniteQueryObserverResult<InfiniteData<IPagedData<HexEvent>>> {
  return useInfiniteQuery(searchEventQueryOptions(query, searchType))
}

export const searchQueryOptions = (query: string, searchType: EventsSearchType) => {
  const searchQuery = searchType === EventsSearchType.Tag ? { tag: query } : { title: query }
  return queryOptions({
    queryKey: [searchQueryKey, { query }, searchQuery],
    queryFn: () => {
      if (!query || query.length < 2) return Promise.resolve([])
      return searchEvents(searchQuery, 0, searchItemsPerPage).then((res) => res.data.data)
    },
  })
}

export function useSearchEvents(query: string, searchType: EventsSearchType): UseQueryResult<Array<HexEvent>> {
  const config = searchQueryOptions(query, searchType)

  return useQuery(config)
}
