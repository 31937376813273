export enum TicketType {
  Free = 'free',
  Paid = 'paid',
  Donation = 'donation',
}

export enum TicketSaleStatus {
  SoldOut = 'soldOut',
  Available = 'available',
  SaleStartsLater = 'saleStartsLater',
}

export enum ProspectInfoMode {
  Required = 'required',
  NotRequired = 'notRequired',
  Optional = 'optional',
}

export enum EventTicketFieldSize {
  Small = 'small',
  Large = 'large',
}

export enum EventsSearchType {
  Title = 'title',
  Tag = 'tag',
}

export enum MediaFileSource {
  heUserProfilePhoto = 'he_user_profile_photo',
}
export enum TicketMode {
  standard = 'standard',
  extraAddons = 'extras',
}

export enum FieldVisibility {
  alwaysVisible = 'always_visible',
  hidden = 'hidden',
  visibleIfEmpty = 'visible_if_empty ',
  conditional = 'conditional',
}

export enum TicketButtonMode {
  automatic = 'automatic',
  custom = 'custom',
}

export enum StyleMode {
  default = 'default',
  custom = 'custom',
}

export enum AddonCategoryType {
  addon = 'addon',
  activity = 'activity',
  session = 'session',
}

export enum AuthenticationMode {
  limited = 'limited',
  verified = 'verified',
}

export enum SocialNetworkType {
  facebook = 'facebook',
  twitter = 'twitter',
  instagram = 'instagram',
  linkedIn = 'linkedIn',
}

export enum OrderStatus {
  inProgress = 'in_progress',
  completed = 'completed',
  expired = 'expired',
  failed = 'failed',
}

export enum PaymentType {
  stripe = 'stripe',
  authorizeDotNet = 'authorize_dot_net',
  paypal = 'paypal',
}

export enum UlcFormActionTypeSent {
  submit = 'submit_form',
  formPrefill = 'form_prefill',
  resetForm = 'reset_form',
}

export enum UlcFormActionTypeReceived {
  submissionBody = 'submission_body',
  formErrorMessage = 'form_error_message',
  formLoadComplete = 'form_load_complete',
}

export enum UlcFormDataType {
  ulcSubmission = 'ulc_submission',
  info = 'info',
  SubmissionAndInfo = 'ulc_submission_and_info',
}

export enum DataOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum DataInterval {
  upcoming = 'upcoming',
  past = 'past',
}

export enum SalesEndDateTextMode {
  automatic = 'automatic',
  custom = 'custom',
  hidden = 'hidden',
}

export enum LocaleCode {
  english = 'en',
  french = 'fr',
  arabic = 'ar',
  german = 'de',
  chinese = 'zh',
  italian = 'it',
  japanese = 'ja',
  korean = 'ko',
  Portuguese = 'pt',
  spanish = 'es',
}

export enum EventInterval {
  upcoming = 'upcoming',
  past = 'past',
}
