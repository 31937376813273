export const koreanTranslation = {
  signIn: '로그인',
  signUp: '가입',
  search: '검색',
  firstName: '이름',
  lastName: '성',
  emailAddress: '이메일 주소',
  password: '비밀번호',
  requiredField: '필수 입력란',
  invalidValue: '잘못된 값',
  fillUpFieldsToCompletePayment: '결제를 완료하려면 모든 필드를 작성하세요',
  paymentFailed: '결제 실패',
  somethingWrong: '문제가 발생했습니다.',
  paymentNotSuccessful: '결제가 성공적으로 이루어지지 않았습니다. 다시 시도해주세요.',
  logInFailed: '로그인 실패',
  phone: '전화번호',
  address: '주소',
  address1: '주소 1',
  address2: '주소 2',
  country: '국가',
  state: '주',
  city: '도시',
  zipcode: '우편번호',
  saveChanges: '변경 사항 저장',
  reset: '재설정',
  saveChangesSuccess: '저장됨',
  saveChangesFailed: '변경 사항 저장 실패',
  details: '세부 정보',
  aboutThisEvent: '이 이벤트에 대해',
  tags: '태그',
  findUsOn: '다음 위치에서 찾기',
  salesStarts: '판매 시작',
  dateAndTime: '날짜 및 시간',
  from: '부터',
  to: '까지',
  location: '장소',
  profile: '프로필',
  favoriteEvents: '즐겨찾기 이벤트',
  noEvents: '이벤트 없음',
  toggleFavoriteEventFailed: '즐겨찾기 이벤트 전환 실패',
  logout: '로그아웃',
  organizer: '주최자',
  eventNotFound: '이벤트를 찾을 수 없음',
  tickets: '티켓',
  ticketsDetails: '티켓 세부 정보',
  salesEndsOn: '판매 종료일',
  fee: '수수료',
  free: '무료',
  soldOut: '매진',
  salesStartsOn: '판매 시작일',
  register: '등록',
  guest: '게스트',
  noTickets: '티켓 없음',
  minsLeftForCheckout: '결제까지 남은 시간',
  deleteCartItemSuccess: '카트 항목 삭제됨',
  deleteCartItemFailed: '카트 항목 삭제 실패',
  deleteTicketCartItemSuccess: '카트 비워짐',
  deleteTicketCartItemFailed: '카트 항목 삭제 실패',
  total: '합계',
  totalDue: '총액',
  subTotal: '소계',
  update: '업데이트',
  cartExpired: '카트 만료',
  checkout: '결제',
  orderSummary: '주문 요약',
  contactInfo: '연락처 정보',
  orderInfo: '주문 정보',
  name: '이름',
  cardInfo: '카드 정보',
  placeOrder: '주문하기',
  mobilePhone: '휴대전화',
  placeOrderSuccess: '주문 완료',
  placeOrderFailed: '주문 실패',
  extend: '연장',
  cancel: '취소',
  extendEventCartExpirationSuccess: '카트 만료 연장됨',
  extendEventCartExpirationFailed: '카트 만료 연장 실패',
  expirationExtendQuestion: '카트 만료 시간을 연장하시겠습니까?',
  orders: '주문',
  myTickets: '내 티켓',
  upcomingEvents: '예정된 이벤트',
  pastEvents: '지난 이벤트',
  eventDetails: '이벤트 세부 정보',
  summary: '요약',
  events: '이벤트',
  organizerEvents: '주최자의 이벤트',
  eventsSearchResultFor: '이벤트 검색 결과',
  viewAll: '모두 보기',
  noTags: '태그 없음',
  backToEventDetails: '이벤트 세부 정보로 돌아가기',
  removeAll: '모두 제거',
  areYouSureYouWantToRemoveAllTickets: '모든 티켓을 제거하시겠습니까?',
  minQuantityPerOrderIs: '주문 당 최소 수량은',
  maxQuantityPerOrderIs: '주문 당 최대 수량은',
  aboutTheOrganizer: '주최자 정보',
  viewOnMap: '지도에서 보기',
  viewOrganizerEvents: '주최자의 이벤트 보기',
  invalidEmail: '유효하지 않은 이메일',
  invalidNumber: '유효하지 않은 번호',
  unavailable: '사용 불가',
  available: '사용 가능',
  addons: '부가 서비스',
  unlimited: '무제한',
  noOrders: '주문 없음',
  email: '이메일',
  termsOfService: '서비스 약관',
  privacyPolicy: '개인정보 보호 정책',
  privacyPolicyHint: '커뮤니티 지침을 읽고 동의했음을 확인합니다',
  backToEventTickets: '이벤트 티켓으로 돌아가기',
  step: '단계',
  activities: '활동',
  price: '가격',
  missingPaymentKeyMessage: '결제 처리할 수 없습니다. 이벤트 관리자에게 문의하십시오.',
  sendVerificationCode:
    '등록을 계속하려면 이메일 주소를 확인해야 합니다. 일회용 확인 코드를 받으려면 아래에 이메일을 입력하십시오.',
  verifyYourEmail: '이메일 주소 확인',
  checkVerificationCode: '일회용 확인 코드를 보냈습니다. 이메일을 확인하십시오.',
  failedSendVerificationCode: '확인 코드 전송 실패',
  back: '뒤로',
  remainingAttempts: '남은 시도 횟수',
  wrongVerificationCode: '잘못된 확인 코드입니다. 다시 시도하십시오.',
  resendAnotherCode: '다른 코드 다시 보내기',
  wrongEmailVerificationCode: '잘못된 이메일 또는 확인 코드입니다. 다시 시도하십시오.',
  aboutOrganizer: '주최자 정보',
  proceedToCheckout: '결제 진행',
  ticket: '티켓',
  saveAndAddAnotherTicket: '저장하고 다른 티켓 추가하기',
  addToAppleWallet: 'Apple 지갑에 추가',
  addToGoogleWallet: 'Google 지갑에 추가',
  downloadFailed: '다운로드 실패',
  ios: 'iOS',
  android: '안드로이드',
  allRightsReserved: '모든 권리 보유',
  fillFormWithInformation: '내 정보로 양식 작성',
  shareEventHint: '이 이벤트를 소셜 미디어나 다른 채널에 공유하세요.',
  favoriteEventHint:
    '좋아하는 이벤트는 오른쪽 상단의 하트 아래에 나타납니다. 솔리드 하트는 이벤트를 좋아하는 것을 의미합니다. 외곽선 하트는 이벤트를 좋아하지 않은 것을 의미합니다. 좋아요를 한 후에는 다시 하트를 클릭하여 즐겨찾기에서 제거하세요.',
  sendCode: '코드 보내기',
  continue: '계속',
  sendConfirmationEmail: '확인 이메일 보내기',
  specifyContactInfo: '연락처 정보 지정',
  confirmationEmail: '이 연락처로 티켓이 포함된 확인 이메일이 전송됩니다',
  oops: '이런,',
  somethingWentWrong: '문제가 발생했습니다!',
  next: '다음',
  registration: '등록',
  includedItems: '포함된 항목',
  packages: '패키지',
  select: '선택',
  selected: '선택됨',
  categories: '카테고리',
  all: '모두',
  noAddons: '추가 기능 없음',
  noActivities: '활동 없음',
  noSessions: '세션이 없음',
  noMoreAvailable: '더 이상 이용 가능하지 않음',
  reachedMaxQuantityPerOrder: '주문 당 최대 수량 도달',
  extraMinQuantityPerOrderIs: '주문 당 최소 수량은',
  didNotMeetMinQuantityPerOrder: '최소 주문 수량 미달',
  discountCode: '할인 코드',
  remove: '제거',
  apply: '적용',
  discount: '할인',
  totalPaid: '총 지불액',
  ticketType: '티켓 유형',
  sessions: '세션',
  speakers: '스피커',
  sponsors: '후원사',
  aboutThisSession: '이 세션에 대해',
  sessionDetails: '세션 세부정보',
  myCart: '내 장바구니',
  items: '항목',
  confirmEmail: '이메일 확인',
  invalidEmailConfirmation: '유효하지 않은 이메일 확인',
  addToCalendar: '캘린더에 추가',
  cartSummary: '장바구니 요약',
  eventTickets: '이벤트 티켓',
  aboutEvent: '이벤트 정보',
  downloadTicket: '티켓 다운로드',
  addToWallet: '지갑에 추가',
  editRegistration: '등록 수정',
  purchaseExtras: '부가품 구매',
  carts: '카트',
  organizerNotFound: '주최자를 찾을 수 없음',
  extras: '부가품',
  registrationInfo: '등록 정보',
  ticketsPurchasedForOthers: '다른 사람을 위해 구매한 티켓',
  eventPurchasedTicketsInfo: '이 이벤트에 이미 등록되었습니다',
  view: '보기',
  moreInfo: '추가 정보',
  maxQuantityPerOrderHasBeenReached: '주문 당 최대 수량이 도달했습니다',
  myOrders: '내 주문',
  liveAndUpcomingEvents: '실시간 및 예정된 이벤트',
  brandName: 'Captello',
  save: '저장',
  clear: '지우기',
  edit: '편집',
  verify: '확인',
  change: '변경',
  confirm: '확인',
  minLeftForExtend: '연장까지 남은 시간 (분)',
  cardNumber: '카드 번호',
  expiration: '만료',
  cvv: 'CVV',
  submit: '제출',
} as const
