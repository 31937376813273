import Head from 'next/head'
import Grid from '@mui/material/Grid'
import { useRouter } from 'next/router'
import { FC, ReactElement, useMemo } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import Header from './Header'
import Footer from './Footer'
import { SSRBrands } from '../types'
import { createCustomBrandTheme } from '../theme'
import { useTranslation } from '../providers/localesProvider'
import useAddTrackingCode from '../hooks/useAddTrackingCode'
import useAddIntercomCode from '../hooks/useAddIntercomCode'
import { SnackbarProvider } from '../providers/SnackbarProvider'
import RoutingProgressBar from '../components/shared/RoutingProgressBar'

type Props = {
  children: ReactElement
  brand: SSRBrands
}

const AppLayout: FC<Props> = ({ children, brand }): ReactElement => {
  const router = useRouter()

  const { code } = useTranslation()

  useAddTrackingCode()
  useAddIntercomCode()

  const { hideHeader, hideFooter } = router.query

  const isHideHeader = hideHeader === 'true'
  const isHideFooter = hideFooter === 'true'

  const theme = useMemo(() => createCustomBrandTheme({ brand, localeCode: code }), [code])

  return (
    <div>
      <Head>
        <meta name="theme-color" content={theme.palette.primary.main} />
        <link rel="icon" type="image/png" href={brand?.favIcon} />
      </Head>
      <ThemeProvider theme={theme}>
        <Grid sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <CssBaseline />
          <SnackbarProvider>
            <RoutingProgressBar />
            {!isHideHeader ? <Header /> : null}
            <Grid
              container
              component="main"
              justifyContent="center"
              sx={{
                marginBottom: 3,
                minHeight: !isHideHeader
                  ? { xs: 'calc(100vh - 130px)', sm: 'calc(100vh - 158px)' }
                  : 'calc(100vh - 94px)',
              }}
            >
              {children}
            </Grid>
            {!isHideFooter ? <Footer /> : null}
          </SnackbarProvider>
        </Grid>
      </ThemeProvider>
    </div>
  )
}

export default AppLayout
