export const japaneseTranslation = {
  signIn: 'サインイン',
  signUp: 'サインアップ',
  search: '検索',
  firstName: '名',
  lastName: '姓',
  emailAddress: 'メールアドレス',
  password: 'パスワード',
  requiredField: '必須項目',
  invalidValue: '無効な値',
  fillUpFieldsToCompletePayment: '支払いを完了するにはすべての項目を入力してください',
  paymentFailed: '支払いに失敗しました',
  somethingWrong: '何か問題が発生しました。',
  paymentNotSuccessful: '支払いが成功しませんでした。もう一度お試しください。',
  logInFailed: 'ログインに失敗しました',
  phone: '電話',
  address: '住所',
  address1: '住所1',
  address2: '住所2',
  country: '国',
  state: '州',
  city: '市',
  zipcode: '郵便番号',
  saveChanges: '変更を保存',
  reset: 'リセット',
  saveChangesSuccess: '保存済み',
  saveChangesFailed: '変更を保存できませんでした',
  details: '詳細',
  aboutThisEvent: 'このイベントについて',
  tags: 'タグ',
  findUsOn: 'こちらで見つける',
  salesStarts: '販売開始',
  dateAndTime: '日付と時刻',
  from: '開始',
  to: '終了',
  location: '場所',
  profile: 'プロフィール',
  favoriteEvents: 'お気に入りイベント',
  noEvents: 'イベントがありません',
  toggleFavoriteEventFailed: 'お気に入りイベントの切り替えに失敗しました',
  logout: 'ログアウト',
  organizer: '主催者',
  eventNotFound: 'イベントが見つかりません',
  tickets: 'チケット',
  ticketsDetails: 'チケットの詳細',
  salesEndsOn: '販売終了日',
  fee: '手数料',
  free: '無料',
  soldOut: '売り切れ',
  salesStartsOn: '販売開始日',
  register: '登録',
  guest: 'ゲスト',
  noTickets: 'チケットなし',
  minsLeftForCheckout: 'チェックアウトまで残り時間',
  deleteCartItemSuccess: 'カートアイテムが削除されました',
  deleteCartItemFailed: 'カート内のアイテムの削除に失敗しました',
  deleteTicketCartItemSuccess: 'カートが空になりました',
  deleteTicketCartItemFailed: 'カート内のアイテムの削除に失敗しました',
  total: '合計',
  totalDue: '合計金額',
  subTotal: '小計',
  update: '更新',
  cartExpired: 'カートの有効期限が切れました',
  checkout: 'チェックアウト',
  orderSummary: '注文の概要',
  contactInfo: '連絡先情報',
  orderInfo: '注文情報',
  name: '名前',
  cardInfo: 'カード情報',
  placeOrder: '注文する',
  mobilePhone: '携帯電話',
  placeOrderSuccess: '注文が確定しました',
  placeOrderFailed: '注文に失敗しました',
  extend: '延長',
  cancel: 'キャンセル',
  extendEventCartExpirationSuccess: 'カートの有効期限が延長されました',
  extendEventCartExpirationFailed: 'カートの有効期限の延長に失敗しました',
  expirationExtendQuestion: 'カートの有効期限を延長しますか？',
  orders: '注文',
  myTickets: 'マイチケット',
  upcomingEvents: '今後のイベント',
  pastEvents: '過去のイベント',
  eventDetails: 'イベントの詳細',
  summary: '概要',
  events: 'イベント',
  organizerEvents: '主催者のイベント',
  eventsSearchResultFor: 'イベントの検索結果',
  viewAll: 'すべて表示',
  noTags: 'タグなし',
  backToEventDetails: 'イベントの詳細に戻る',
  removeAll: 'すべて削除',
  areYouSureYouWantToRemoveAllTickets: 'すべてのチケットを削除してもよろしいですか？',
  minQuantityPerOrderIs: '注文ごとの最小数量は',
  maxQuantityPerOrderIs: '注文ごとの最大数量は',
  aboutTheOrganizer: '主催者について',
  viewOnMap: '地図で表示',
  viewOrganizerEvents: '主催者のイベントを表示',
  invalidEmail: '無効なメールアドレス',
  invalidNumber: '無効な番号',
  unavailable: '利用不可',
  available: '利用可能',
  addons: 'アドオン',
  unlimited: '無制限',
  noOrders: '注文なし',
  email: 'メール',
  termsOfService: '利用規約',
  privacyPolicy: 'プライバシーポリシー',
  privacyPolicyHint: 'コミュニティガイドラインを読み、同意しました',
  backToEventTickets: 'イベントチケットに戻る',
  step: 'ステップ',
  activities: 'アクティビティ',
  price: '価格',
  missingPaymentKeyMessage: 'お支払いを処理できません。イベント管理者に連絡してください。',
  sendVerificationCode:
    '登録を続行するには、メールアドレスを確認する必要があります。以下にメールアドレスを入力して、ワンタイムの認証コードを受け取ってください。',
  verifyYourEmail: 'メールアドレスを確認してください',
  checkVerificationCode: 'ワンタイムの認証コードを送信しました。メールをご確認ください。',
  failedSendVerificationCode: '認証コードの送信に失敗しました',
  back: '戻る',
  remainingAttempts: '残りの試行回数',
  wrongVerificationCode: '認証コードが間違っています。もう一度お試しください。',
  resendAnotherCode: '別のコードを再送信',
  wrongEmailVerificationCode: 'メールアドレスまたは認証コードが間違っています。もう一度お試しください。',
  aboutOrganizer: '主催者について',
  proceedToCheckout: 'チェックアウトに進む',
  ticket: 'チケット',
  saveAndAddAnotherTicket: '保存して別のチケットを追加',
  addToAppleWallet: 'Appleウォレットに追加',
  addToGoogleWallet: 'Googleウォレットに追加',
  downloadFailed: 'ダウンロードに失敗しました',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: '全著作権所有',
  fillFormWithInformation: '私の情報でフォームを記入する',
  shareEventHint: 'このイベントをソーシャルメディアや他のチャンネルで共有する',
  favoriteEventHint:
    'お気に入りのイベントは、右上隅のハートの下に表示されます。塗りつぶしのハートは、イベントがお気に入りであることを示します。アウトラインのハートは、イベントがお気に入りでないことを示します。お気に入りに登録した後は、再度ハートをクリックしてお気に入りから削除してください。',
  sendCode: 'コードを送信',
  continue: '続行',
  sendConfirmationEmail: '確認メールを送信',
  specifyContactInfo: '連絡先情報を指定',
  confirmationEmail: 'この連絡先にチケットが含まれた確認メールが送信されます',
  oops: 'おっと、',
  somethingWentWrong: '問題が発生しました！',
  next: '次へ',
  registration: '登録',
  includedItems: '含まれるアイテム',
  packages: 'パッケージ',
  select: '選択',
  selected: '選択済み',
  categories: 'カテゴリ',
  all: '全て',
  noAddons: 'アドオンなし',
  noActivities: 'アクティビティなし',
  noSessions: 'セッションなし',
  noMoreAvailable: 'これ以上利用できません',
  reachedMaxQuantityPerOrder: '注文ごとの最大数量に達しました',
  extraMinQuantityPerOrderIs: '注文ごとの最小数量は',
  didNotMeetMinQuantityPerOrder: '最小数量に達していません',
  discountCode: '割引コード',
  remove: '削除',
  apply: '適用',
  discount: '割引',
  totalPaid: '合計支払額',
  ticketType: 'チケットタイプ',
  sessions: 'セッション',
  speakers: 'スピーカー',
  sponsors: 'スポンサー',
  aboutThisSession: 'このセッションについて',
  sessionDetails: 'セッションの詳細',
  myCart: 'マイカート',
  items: 'アイテム',
  confirmEmail: 'メールを確認',
  invalidEmailConfirmation: '無効なメール確認',
  addToCalendar: 'カレンダーに追加',
  cartSummary: 'カートの概要',
  eventTickets: 'イベントチケット',
  aboutEvent: 'イベントについて',
  downloadTicket: 'チケットをダウンロード',
  addToWallet: 'ウォレットに追加',
  editRegistration: '登録を編集',
  purchaseExtras: 'エクストラを購入',
  carts: 'カート',
  organizerNotFound: '主催者が見つかりません',
  extras: 'エクストラ',
  registrationInfo: '登録情報',
  ticketsPurchasedForOthers: '他の人のために購入したチケット',
  eventPurchasedTicketsInfo: 'このイベントにはすでに登録済みです',
  view: '表示',
  moreInfo: '詳細',
  maxQuantityPerOrderHasBeenReached: '1注文あたりの最大数量に達しました',
  myOrders: '私の注文',
  liveAndUpcomingEvents: 'ライブおよび今後のイベント',
  brandName: 'Captello',
  save: '保存',
  clear: 'クリア',
  edit: '編集',
  verify: '確認',
  change: '変更',
  confirm: '確認',
  minLeftForExtend: '延長までの残り時間分',
  cardNumber: 'カード番号',
  expiration: '有効期限',
  cvv: 'CVV',
  submit: '送信',
} as const
