import { useEffect } from 'react'

import { loadScript } from '../helpers/utils'
import { useAuth } from '../providers/authProvider'
import { useCustomerSettingsQuery } from '../queryHooks/metaQuery'

export default function useAddIntercomCode() {
  const { isAuthenticated } = useAuth()
  const isAuthenticatedValue = isAuthenticated()
  const { data: customerSettingsData } = useCustomerSettingsQuery()
  const { intercomMessengerCode } = customerSettingsData ?? {}

  useEffect(() => {
    isAuthenticatedValue &&
      intercomMessengerCode &&
      loadScript(intercomMessengerCode, 'intercomMessengerCodeId').finally()

    return () => {
      window.Intercom?.('shutdown')
      document?.getElementById('intercomMessengerCodeId')?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSettingsData])
}
