import * as Yup from 'yup'
import { Auth } from 'aws-amplify'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Dispatch, useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormHelperText } from '@mui/material'
import Typography from '@mui/material/Typography'
import { yupResolver } from '@hookform/resolvers/yup'
import { MuiOtpInput } from 'mui-one-time-password-input'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import Button from '../shared/ui/Button'
import { useSnackBar } from '../../providers/SnackbarProvider'
import { getVars } from '../../helpers/common'
import FormStateRPC from '../rpc/FormStateRPC'
import useCountdown from '../../hooks/useCountdown'
import { useAuth } from '../../providers/authProvider'
import { useTranslation } from '../../providers/localesProvider'

type Props = {
  handleBack?: () => void
  user: any
  onLoggedIn?: () => void
  setUser: Dispatch<any>
}

const { cognitoSessionDurationInMinutes } = getVars()

function OtpVerificationForm(props: Props): JSX.Element {
  const { handleBack, user, onLoggedIn, setUser } = props
  const { t } = useTranslation()
  const { login } = useAuth()
  const { enqueue } = useSnackBar()

  const [remainingAttemptCount, setRemainingAttemptCount] = useState(3)
  const [isLoadingResendCode, setIsLoadingResendCode] = useState(false)

  const { countdown, resetCountDown } = useCountdown({
    minutes: parseInt(cognitoSessionDurationInMinutes ?? 5) - 1,
    seconds: 59,
  })
  const timer = `${countdown.minutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${countdown
    .seconds()
    .toLocaleString('en-US', { minimumIntegerDigits: 2 })}`

  const validationSchema = Yup.object({
    otp: Yup.string()
      .trim()
      .required(t('requiredField'))
      .test('otp', '', (value) => value?.length === 6),
  })

  const { control, handleSubmit } = useForm<{ otp: string }>({
    defaultValues: { otp: '' },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const onFormSubmit = handleSubmit(async (values: { otp: string }): Promise<void> => {
    try {
      const userRes = await Auth.sendCustomChallengeAnswer(user, `${values.otp}`)
      if (userRes.challengeName !== 'CUSTOM_CHALLENGE') {
        login(userRes.signInUserSession.idToken.jwtToken, false)
        onLoggedIn?.()
      } else {
        setRemainingAttemptCount((prev) => prev - 1)
        enqueue({
          title: t('logInFailed'),
          body: t('wrongVerificationCode'),
          severity: 'error',
        })
      }
    } catch (e) {
      setRemainingAttemptCount(0)
      enqueue({
        title: t('logInFailed'),
        body: t('wrongEmailVerificationCode'),
        severity: 'error',
      })
      console.error(t('wrongEmailVerificationCode'), e)
    }
  })

  const resendCode = async (username: string): Promise<void> => {
    setIsLoadingResendCode(true)
    try {
      const user = await Auth.signIn(username)
      setUser(user)
      setRemainingAttemptCount(3)
      resetCountDown()
      enqueue({ body: t('checkVerificationCode'), severity: 'success' })
    } catch (e) {
      enqueue({ title: 'error', body: t('failedSendVerificationCode'), severity: 'error' })
    }
    setIsLoadingResendCode(false)
  }

  return (
    <Grid container gap={2}>
      <Grid container item>
        <Typography sx={{ fontSize: 14 }}>{t('checkVerificationCode')}</Typography>
      </Grid>
      <form onSubmit={onFormSubmit} style={{ width: '100%' }}>
        <Grid item container justifyContent="center" gap={2}>
          <Controller
            name="otp"
            control={control}
            rules={{ validate: (value) => value.length === 6 }}
            render={({ field, fieldState }) => (
              <Grid>
                <MuiOtpInput
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  placeholder=""
                  data-cy="otp"
                  sx={{ gap: 1, '.MuiOutlinedInput-root': { height: 50, width: 40 } }}
                  {...field}
                  length={6}
                />
                {fieldState.error ? <FormHelperText error>{fieldState.error.message}</FormHelperText> : null}
              </Grid>
            )}
          />

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {remainingAttemptCount === 3 && countdown.asMilliseconds() ? null : remainingAttemptCount === 0 ||
                !countdown.asMilliseconds() ? (
                <Button
                  isLoading={isLoadingResendCode}
                  disabled={isLoadingResendCode}
                  variant="text"
                  onClick={() => resendCode(user.username)}
                >
                  {t('resendAnotherCode')}
                </Button>
              ) : (
                <FormHelperText error sx={{ fontSize: 13, fontWeight: 700 }}>{`${remainingAttemptCount} ${t(
                  'remainingAttempts'
                )}`}</FormHelperText>
              )}
            </Grid>
            <Grid item sx={{ width: 40 }}>
              {timer}
            </Grid>
          </Grid>

          <FormStateRPC
            control={control}
            render={({ isSubmitting, isDirty, isValid }) => {
              return (
                <Button
                  data-cy="verifyBtn"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting || !isDirty || !isValid}
                  isLoading={isSubmitting}
                >
                  {t('verify')}
                </Button>
              )
            }}
          />
        </Grid>

        <Grid container item justifyContent="center" mt={1}>
          <Button variant="text" onClick={handleBack}>
            <ArrowBackIosIcon sx={{ fontSize: 14 }} />
            {t('back')}
          </Button>
        </Grid>
      </form>
    </Grid>
  )
}
export default OtpVerificationForm
