export const PortugueseTranslation = {
  signIn: 'Entrar',
  signUp: 'Registrar',
  search: 'Buscar',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  emailAddress: 'Endereço de Email',
  password: 'Senha',
  requiredField: 'Campo obrigatório',
  invalidValue: 'Valor inválido',
  fillUpFieldsToCompletePayment: 'Preencha todos os campos para concluir o pagamento',
  paymentFailed: 'Pagamento falhou',
  somethingWrong: 'Algo deu errado.',
  paymentNotSuccessful: 'Seu pagamento não foi bem-sucedido, por favor, tente novamente.',
  logInFailed: 'Falha no login',
  phone: 'Telefone',
  address: 'Endereço',
  address1: 'Endereço 1',
  address2: 'Endereço 2',
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  zipcode: 'CEP',
  saveChanges: 'Salvar Alterações',
  reset: 'Redefinir',
  saveChangesSuccess: 'Salvo',
  saveChangesFailed: 'Falha ao salvar alterações',
  details: 'Detalhes',
  aboutThisEvent: 'Sobre este evento',
  tags: 'Tags',
  findUsOn: 'Nos encontre em',
  salesStarts: 'Vendas começam',
  dateAndTime: 'Data e Hora',
  from: 'De',
  to: 'Para',
  location: 'Localização',
  profile: 'Perfil',
  favoriteEvents: 'Eventos Favoritos',
  noEvents: 'Sem Eventos',
  toggleFavoriteEventFailed: 'Falha ao alternar evento favorito',
  logout: 'Sair',
  organizer: 'Organizador',
  eventNotFound: 'Evento não encontrado',
  tickets: 'Ingressos',
  ticketsDetails: 'Detalhes dos Ingressos',
  salesEndsOn: 'Vendas encerram em',
  fee: 'Taxa',
  free: 'Grátis',
  soldOut: 'Esgotado',
  salesStartsOn: 'Vendas começam em',
  register: 'Registrar',
  guest: 'Convidado',
  noTickets: 'Sem Ingressos',
  minsLeftForCheckout: 'minutos restantes para finalizar a compra',
  deleteCartItemSuccess: 'Item do carrinho excluído',
  deleteCartItemFailed: 'Falha ao excluir item do carrinho',
  deleteTicketCartItemSuccess: 'Carrinho esvaziado',
  deleteTicketCartItemFailed: 'Falha ao excluir itens do carrinho',
  total: 'Total',
  totalDue: 'Total devido',
  subTotal: 'Subtotal',
  update: 'Atualizar',
  cartExpired: 'Carrinho expirado',
  checkout: 'Finalizar Compra',
  orderSummary: 'Resumo do Pedido',
  contactInfo: 'Informações de Contato',
  orderInfo: 'Informações do Pedido',
  name: 'Nome',
  cardInfo: 'Informações do Cartão',
  placeOrder: 'Realizar Pedido',
  mobilePhone: 'Telefone Celular',
  placeOrderSuccess: 'Pedido realizado',
  placeOrderFailed: 'Falha ao realizar pedido',
  extend: 'Estender',
  cancel: 'Cancelar',
  extendEventCartExpirationSuccess: 'Expiração do carrinho estendida',
  extendEventCartExpirationFailed: 'Falha ao estender a expiração do carrinho',
  expirationExtendQuestion: 'Gostaria de estender o tempo de expiração do carrinho?',
  orders: 'Pedidos',
  myTickets: 'Meus Ingressos',
  upcomingEvents: 'Próximos Eventos',
  pastEvents: 'Eventos Anteriores',
  eventDetails: 'Detalhes do Evento',
  summary: 'Resumo',
  events: 'Eventos',
  organizerEvents: 'Eventos do Organizador',
  eventsSearchResultFor: 'Resultado da busca por eventos para',
  viewAll: 'Ver Todos',
  noTags: 'Sem tags',
  backToEventDetails: 'Voltar aos detalhes do evento',
  removeAll: 'Remover todos',
  areYouSureYouWantToRemoveAllTickets: 'Tem certeza de que deseja remover todos os ingressos?',
  minQuantityPerOrderIs: 'Quantidade mínima por pedido é',
  maxQuantityPerOrderIs: 'Quantidade máxima por pedido é',
  aboutTheOrganizer: 'Sobre o Organizador',
  viewOnMap: 'Ver no mapa',
  viewOrganizerEvents: 'Ver eventos do organizador',
  invalidEmail: 'E-mail inválido',
  invalidNumber: 'Número inválido',
  unavailable: 'Indisponível',
  available: 'Disponível',
  addons: 'Extras',
  unlimited: 'Ilimitado',
  noOrders: 'Sem pedidos',
  email: 'E-mail',
  termsOfService: 'Termos de Serviço',
  privacyPolicy: 'Política de Privacidade',
  privacyPolicyHint: 'diretrizes da comunidade e li',
  backToEventTickets: 'Voltar aos ingressos do evento',
  step: 'Passo',
  activities: 'Atividades',
  price: 'Preço',
  missingPaymentKeyMessage: 'Não foi possível processar seu pagamento. Entre em contato com o administrador do evento.',
  sendVerificationCode:
    'Para continuar com o registro, precisaremos verificar seu endereço de e-mail. Insira seu e-mail abaixo para receber um código de verificação único.',
  verifyYourEmail: 'Verifique seu endereço de e-mail',
  checkVerificationCode: 'Enviamos o seu código de verificação único. Por favor, verifique seu e-mail.',
  failedSendVerificationCode: 'Falha ao enviar o código de verificação',
  back: 'Voltar',
  remainingAttempts: 'Tentativas Restantes',
  wrongVerificationCode: 'Código de verificação incorreto. Por favor, tente novamente.',
  resendAnotherCode: 'Reenviar outro código',
  wrongEmailVerificationCode: 'E-mail ou código de verificação incorretos. Por favor, tente novamente.',
  aboutOrganizer: 'Sobre o organizador',
  proceedToCheckout: 'Prosseguir para o Checkout',
  ticket: 'Ingresso',
  saveAndAddAnotherTicket: 'Salvar e Adicionar outro ingresso',
  addToAppleWallet: 'Adicionar ao Apple Wallet',
  addToGoogleWallet: 'Adicionar ao Google Wallet',
  downloadFailed: 'Falha ao baixar',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'Todos os Direitos Reservados',
  fillFormWithInformation: 'Preencher formulário com minhas informações',
  shareEventHint: 'Compartilhar este evento nas redes sociais ou em outros canais.',
  favoriteEventHint:
    'Eventos que você favoritou aparecem sob um coração no canto superior direito para acesso mais fácil. Um coração sólido significa que você favoritou o evento. Um coração contornado significa que o evento não está favoritado. Depois de favoritar, clique no coração novamente para removê-lo dos favoritos.',
  sendCode: 'enviar código',
  continue: 'continuar',
  sendConfirmationEmail: 'Enviar email de confirmação para',
  specifyContactInfo: 'Especificar informações de contato',
  confirmationEmail: 'Um email de confirmação que inclui seus ingressos será enviado para este contato',
  oops: 'Ops,',
  somethingWentWrong: 'Algo deu errado!',
  next: 'Próximo',
  registration: 'Registro',
  includedItems: 'Itens Incluídos',
  packages: 'Pacotes',
  select: 'Selecionar',
  selected: 'Selecionado',
  categories: 'Categorias',
  all: 'Todos',
  noAddons: 'Sem Extras',
  noActivities: 'Sem Atividades',
  noSessions: 'Sem Sessões',
  noMoreAvailable: 'Não há mais disponível',
  reachedMaxQuantityPerOrder: 'Quantidade máxima atingida por pedido',
  extraMinQuantityPerOrderIs: 'quantidade mínima por pedido é',
  didNotMeetMinQuantityPerOrder: 'Não atendeu à quantidade mínima por pedido',
  discountCode: 'Código de desconto',
  remove: 'Remover',
  apply: 'Aplicar',
  discount: 'Desconto',
  totalPaid: 'Total Pago',
  ticketType: 'Tipo de Ingresso',
  sessions: 'Sessões',
  speakers: 'Palestrantes',
  sponsors: 'Patrocinadores',
  aboutThisSession: 'Sobre esta sessão',
  sessionDetails: 'Detalhes da Sessão',
  myCart: 'Meu Carrinho',
  items: 'Itens',
  confirmEmail: 'Confirmar Email',
  invalidEmailConfirmation: 'Confirmação de Email inválida',
  addToCalendar: 'Adicionar ao calendário',
  cartSummary: 'Resumo do Carrinho',
  eventTickets: 'Ingressos para o Evento',
  aboutEvent: 'Sobre o Evento',
  downloadTicket: 'Baixar ingresso',
  addToWallet: 'Adicionar à carteira',
  editRegistration: 'Editar Inscrição',
  purchaseExtras: 'Comprar Extras',
  carts: 'Carrinhos',
  organizerNotFound: 'Organizador não encontrado',
  extras: 'Extras',
  registrationInfo: 'Informações de Inscrição',
  ticketsPurchasedForOthers: 'Ingressos Comprados para Outros',
  eventPurchasedTicketsInfo: 'Você já se inscreveu para este evento',
  view: 'ver',
  moreInfo: 'Mais Informações',
  maxQuantityPerOrderHasBeenReached: 'Quantidade máxima por pedido foi atingida',
  myOrders: 'Meus Pedidos',
  liveAndUpcomingEvents: 'Eventos ao vivo e futuros',
  brandName: 'Captello',
  save: 'Salvar',
  clear: 'Limpar',
  edit: 'Editar',
  verify: 'Verificar',
  change: 'Alterar',
  confirm: 'Confirmar',
  minLeftForExtend: 'minutos restantes para estender',
  cardNumber: 'Número do cartão',
  expiration: 'Data de expiração',
  cvv: 'CVV',
  submit: 'Enviar',
} as const
