import { AxiosPromise, AxiosResponse } from 'axios'

import axios from '../helpers/axios'
import { HexUserProfile } from '../types'
import { MediaFileSource } from '../enums'

const postProfilePicture = (formData: FormData): AxiosPromise<AxiosResponse> =>
  axios
    .post(`/profiles/me/profile-picture`, formData, {
      params: {
        source: MediaFileSource.heUserProfilePhoto,
      },
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res.data)

const patchProfileInfo = (data: any): AxiosPromise<AxiosResponse> =>
  axios.patch(`/profiles/me`, data).then((res) => res.data)

const deleteProfilePicture = (): AxiosPromise => axios.delete(`/profiles/me/profile-picture`).then((res) => res.data)

export const patchProfile = async (data: HexUserProfile, formData?: FormData | null): Promise<any> => {
  if (formData) {
    await postProfilePicture(formData)
    return patchProfileInfo(data)
  }

  if (formData === null) {
    await deleteProfilePicture()
    return patchProfileInfo(data)
  }

  return patchProfileInfo(data)
}
