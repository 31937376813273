import { ReactNode } from 'react'
import { AlertProps } from '@mui/lab'
import { toast, Toaster } from 'sonner'
import { AlertColor } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { wordsCounter } from '../helpers/common'
import { Alert, AlertTitle } from '../components/shared/ui/Alert'

type EnqueueFunc = (message: { title?: string; body?: string; severity?: AlertColor }) => void

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Toaster expand position="top-right" style={{ display: 'flex', justifyContent: 'end' }} />
      {children}
    </>
  )
}
export const useSnackBar = (): { enqueue: EnqueueFunc } => {
  const alertOpts: AlertProps = { variant: 'filled' }

  const enqueue: EnqueueFunc = (message) => {
    toast.custom(
      (toastId) => (
        <Alert
          variant={alertOpts.variant}
          severity={message.severity || 'info'}
          action={
            <IconButton size="small" color="inherit" style={{ float: 'right' }} onClick={() => toast.dismiss(toastId)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <>
            {message.title && <AlertTitle>{message.title}</AlertTitle>}
            {message.body && message.body}
          </>
        </Alert>
      ),
      { duration: message.body && wordsCounter(message.body) > 6 ? wordsCounter(message.body) * 500 : 3000 }
    )
  }

  return { enqueue }
}
