import { AxiosPromise, AxiosRequestConfig } from 'axios'

import { EventCart } from '../types'
import axios from '../helpers/axios'

export const getCurrentCartByEventId = (eventId: string, config?: AxiosRequestConfig): AxiosPromise<EventCart> => {
  return axios.get(`/events/${eventId}/carts/current`, config)
}

export const patchUpdateEventCart = (id: number, cart: Partial<EventCart>): AxiosPromise<EventCart> => {
  return axios.patch(`event-carts/${id}`, cart)
}
