import { useState } from 'react'
import { Auth } from 'aws-amplify'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import { useSnackBar } from '../../providers/SnackbarProvider'
import { getHost } from '../../helpers/common'
import { AuthenticationMode } from '../../enums'
import { useAuth } from '../../providers/authProvider'
import { useTranslation } from '../../providers/localesProvider'
import { postPreAuthData } from '../../services/authService'
import { useCognitoUserInfo } from '../../queryHooks/profileQuery'
import { useEmailVerificationSettingsQuery } from '../../queryHooks/metaQuery'

import Button from '../shared/ui/Button'
import OtpVerificationForm from './OtpVerificationForm'

type Props = { open?: boolean; handleClose: (openVerifiedDialog?: boolean) => void }

function VerifyEmailLoginForm(props: Props): JSX.Element {
  const { open = false, handleClose } = props
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { enqueue } = useSnackBar()

  const { data: cognitoUserInfo } = useCognitoUserInfo()
  const userEmail = cognitoUserInfo?.attributes?.email

  const [activeStep, setActiveStep] = useState<1 | 2>(1)
  const [user, setUser] = useState<any>(null)
  const [isLoadingSendCode, setIsLoadingSendCode] = useState(false)

  const { data: emailVerificationSettings } = useEmailVerificationSettingsQuery()
  const { emailVerificationPromptTitle, emailVerificationPromptSubTitle } = emailVerificationSettings || {}

  const handleNext = async (): Promise<void> => {
    if (userEmail) {
      setIsLoadingSendCode(true)
      try {
        await postPreAuthData({ email: userEmail, host: getHost(), authenticationMode: AuthenticationMode.verified })
        const userRes = await Auth.signIn(userEmail)
        setUser(userRes)
        enqueue({ body: t('checkVerificationCode'), severity: 'success' })
      } catch (e) {
        enqueue({ title: 'error', body: t('failedSendVerificationCode'), severity: 'error' })
      }
      setIsLoadingSendCode(false)
      setActiveStep(2)
    }
  }
  const handleBack = (): void => setActiveStep(1)

  const handleCloseDialog = async (openVerifiedDialog?: boolean) => {
    setActiveStep(1)
    openVerifiedDialog && (await logout())
    handleClose(openVerifiedDialog)
  }

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Typography sx={{ fontWeight: 800, fontSize: 18 }}>{emailVerificationPromptTitle}</Typography>
          <IconButton
            aria-label="close"
            sx={{ position: 'absolute', right: '8px', top: '8px', color: '#9E9E9E' }}
            onClick={() => handleCloseDialog()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container item sx={{ width: 400 }} justifyContent="center">
              {activeStep === 1 ? (
                <Grid container gap={2}>
                  <Typography sx={{ fontSize: 14 }}>{emailVerificationPromptSubTitle}</Typography>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography sx={{ fontWeight: 'bold' }}>{userEmail}</Typography>
                    <Button variant="text" onClick={() => handleCloseDialog(true)}>
                      {t('change')}
                    </Button>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    onClick={handleNext}
                    disabled={isLoadingSendCode}
                    isLoading={isLoadingSendCode}
                  >
                    {t('sendCode')}
                  </Button>
                </Grid>
              ) : (
                <OtpVerificationForm
                  handleBack={handleBack}
                  user={user}
                  setUser={setUser}
                  onLoggedIn={() => handleCloseDialog()}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default VerifyEmailLoginForm
