export const germanTranslation = {
  signIn: 'Anmelden',
  signUp: 'Registrieren',
  search: 'Suchen',
  firstName: 'Vorname',
  lastName: 'Nachname',
  emailAddress: 'E-Mail-Adresse',
  password: 'Passwort',
  requiredField: 'Erforderliches Feld',
  invalidValue: 'Ungültiger Wert',
  fillUpFieldsToCompletePayment: 'Füllen Sie alle Felder aus, um die Zahlung abzuschließen',
  paymentFailed: 'Zahlung fehlgeschlagen',
  somethingWrong: 'Etwas ist schiefgelaufen.',
  paymentNotSuccessful: 'Ihre Zahlung war nicht erfolgreich, bitte versuchen Sie es erneut.',
  logInFailed: 'Anmeldung fehlgeschlagen',
  phone: 'Telefon',
  address: 'Adresse',
  address1: 'Adresse 1',
  address2: 'Adresse 2',
  country: 'Land',
  state: 'Bundesland',
  city: 'Stadt',
  zipcode: 'Postleitzahl',
  saveChanges: 'Änderungen speichern',
  reset: 'Zurücksetzen',
  saveChangesSuccess: 'Gespeichert',
  saveChangesFailed: 'Änderungen konnten nicht gespeichert werden',
  details: 'Details',
  aboutThisEvent: 'Über dieses Ereignis',
  tags: 'Tags',
  findUsOn: 'Finden Sie uns auf',
  salesStarts: 'Verkauf beginnt',
  dateAndTime: 'Datum und Uhrzeit',
  from: 'Von',
  to: 'Bis',
  location: 'Ort',
  profile: 'Profil',
  favoriteEvents: 'Lieblingsveranstaltungen',
  noEvents: 'Keine Veranstaltungen',
  toggleFavoriteEventFailed: 'Favorisierte Veranstaltung konnte nicht umgeschaltet werden',
  logout: 'Abmelden',
  organizer: 'Veranstalter',
  eventNotFound: 'Veranstaltung nicht gefunden',
  tickets: 'Tickets',
  ticketsDetails: 'Ticketdetails',
  salesEndsOn: 'Verkauf endet am',
  fee: 'Gebühr',
  free: 'Kostenlos',
  soldOut: 'Ausverkauft',
  salesStartsOn: 'Verkauf beginnt am',
  register: 'Registrieren',
  guest: 'Gast',
  noTickets: 'Keine Tickets',
  minsLeftForCheckout: 'Minuten verbleiben zum Auschecken',
  deleteCartItemSuccess: 'Warenkorbartikel gelöscht',
  deleteCartItemFailed: 'Löschen des Artikels im Warenkorb fehlgeschlagen',
  deleteTicketCartItemSuccess: 'Warenkorb geleert',
  deleteTicketCartItemFailed: 'Löschen von Artikeln im Warenkorb fehlgeschlagen',
  total: 'Gesamt',
  totalDue: 'Gesamtfällig',
  subTotal: 'Zwischensumme',
  update: 'Aktualisieren',
  cartExpired: 'Warenkorb abgelaufen',
  checkout: 'Kasse',
  orderSummary: 'Bestellübersicht',
  contactInfo: 'Kontaktinformationen',
  orderInfo: 'Bestellinformationen',
  name: 'Name',
  cardInfo: 'Karteninformationen',
  placeOrder: 'Bestellung aufgeben',
  mobilePhone: 'Handynummer',
  placeOrderSuccess: 'Bestellung aufgegeben',
  placeOrderFailed: 'Bestellung konnte nicht aufgegeben werden',
  extend: 'Verlängern',
  cancel: 'Abbrechen',
  extendEventCartExpirationSuccess: 'Warenkorbablauf verlängert',
  extendEventCartExpirationFailed: 'Verlängerung des Warenkorbablaufs fehlgeschlagen',
  expirationExtendQuestion: 'Möchten Sie die Ablaufzeit des Warenkorbs verlängern?',
  orders: 'Bestellungen',
  myTickets: 'Meine Tickets',
  upcomingEvents: 'Kommende Veranstaltungen',
  pastEvents: 'Vergangene Veranstaltungen',
  eventDetails: 'Veranstaltungsdetails',
  summary: 'Zusammenfassung',
  events: 'Veranstaltungen',
  organizerEvents: 'Veranstaltungen des Organisators',
  eventsSearchResultFor: 'Suchergebnisse für Veranstaltungen',
  viewAll: 'Alle anzeigen',
  noTags: 'Keine Tags',
  backToEventDetails: 'Zurück zu den Veranstaltungsdetails',
  removeAll: 'Alle entfernen',
  areYouSureYouWantToRemoveAllTickets: 'Sind Sie sicher, dass Sie alle Tickets entfernen möchten?',
  minQuantityPerOrderIs: 'Mindestmenge pro Bestellung beträgt',
  maxQuantityPerOrderIs: 'Höchstmenge pro Bestellung beträgt',
  aboutTheOrganizer: 'Über den Organisator',
  viewOnMap: 'Auf Karte anzeigen',
  viewOrganizerEvents: 'Veranstaltungen des Organisators anzeigen',
  invalidEmail: 'Ungültige E-Mail',
  invalidNumber: 'Ungültige Nummer',
  unavailable: 'Nicht verfügbar',
  available: 'Verfügbar',
  addons: 'Zusatzleistungen',
  unlimited: 'Unbegrenzt',
  noOrders: 'Keine Bestellungen',
  email: 'E-Mail',
  termsOfService: 'Nutzungsbedingungen',
  privacyPolicy: 'Datenschutzrichtlinie',
  privacyPolicyHint: 'Community-Richtlinien gelesen haben und',
  backToEventTickets: 'Zurück zu den Veranstaltungstickets',
  step: 'Schritt',
  activities: 'Aktivitäten',
  price: 'Preis',
  missingPaymentKeyMessage:
    'Zahlung konnte nicht verarbeitet werden. Bitte kontaktieren Sie Ihren Veranstaltungsadministrator.',
  sendVerificationCode:
    'Um mit der Registrierung fortzufahren, müssen wir Ihre E-Mail-Adresse überprüfen. Geben Sie unten Ihre E-Mail-Adresse ein, um einen einmaligen Bestätigungscode zu erhalten.',
  verifyYourEmail: 'Bestätigen Sie Ihre E-Mail-Adresse',
  checkVerificationCode:
    'Wir haben Ihnen Ihren einmaligen Bestätigungscode gesendet. Bitte überprüfen Sie Ihre E-Mails.',
  failedSendVerificationCode: 'Senden des Bestätigungscodes fehlgeschlagen',
  back: 'Zurück',
  remainingAttempts: 'Verbleibende Versuche',
  wrongVerificationCode: 'Falscher Bestätigungscode. Bitte versuchen Sie es erneut.',
  resendAnotherCode: 'Erneut einen Code senden',
  wrongEmailVerificationCode: 'Falsche E-Mail oder falscher Bestätigungscode. Bitte versuchen Sie es erneut.',
  aboutOrganizer: 'Über den Organisator',
  proceedToCheckout: 'Zur Kasse gehen',
  ticket: 'Ticket',
  saveAndAddAnotherTicket: 'Speichern und ein weiteres Ticket hinzufügen',
  addToAppleWallet: 'Zu Apple Wallet hinzufügen',
  addToGoogleWallet: 'Zu Google Wallet hinzufügen',
  downloadFailed: 'Download fehlgeschlagen',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'Alle Rechte vorbehalten',
  fillFormWithInformation: 'Formular mit meinen Informationen ausfüllen',
  shareEventHint: 'Teilen Sie dieses Ereignis in sozialen Medien oder anderen Kanälen.',
  favoriteEventHint:
    'Ereignisse, die Sie favorisieren, erscheinen unter einem Herz in der oberen rechten Ecke für einen einfacheren Zugriff. Ein ausgefülltes Herz bedeutet, dass Sie das Ereignis favorisiert haben. Ein umrandetes Herz bedeutet, dass das Ereignis nicht favorisiert ist. Nach dem Favorisieren klicken Sie erneut auf das Herz, um es aus Ihren Favoriten zu entfernen.',
  sendCode: 'Code senden',
  continue: 'Weiter',
  sendConfirmationEmail: 'Bestätigungs-E-Mail senden an',
  specifyContactInfo: 'Kontaktdaten angeben',
  confirmationEmail: 'Eine Bestätigungs-E-Mail mit Ihren Tickets wird an diesen Kontakt gesendet',
  oops: 'Hoppla,',
  somethingWentWrong: 'Etwas ist schiefgelaufen!',
  next: 'Nächster',
  registration: 'Registrierung',
  includedItems: 'Inkludierte Artikel',
  packages: 'Pakete',
  select: 'Auswählen',
  selected: 'Ausgewählt',
  categories: 'Kategorien',
  all: 'Alle',
  noAddons: 'Keine Addons',
  noActivities: 'Keine Aktivitäten',
  noSessions: 'Keine Sitzungen',
  noMoreAvailable: 'Keine weiteren verfügbar',
  reachedMaxQuantityPerOrder: 'Maximale Menge pro Bestellung erreicht',
  extraMinQuantityPerOrderIs: 'Mindestmenge pro Bestellung ist',
  didNotMeetMinQuantityPerOrder: 'Mindestmenge pro Bestellung nicht erreicht',
  discountCode: 'Rabattcode',
  remove: 'Entfernen',
  apply: 'Anwenden',
  discount: 'Rabatt',
  totalPaid: 'Gesamtpreis',
  ticketType: 'Tickettyp',
  sessions: 'Sitzungen',
  speakers: 'Sprecher',
  sponsors: 'Sponsoren',
  aboutThisSession: 'Über diese Sitzung',
  sessionDetails: 'Sitzungsdetails',
  myCart: 'Mein Warenkorb',
  items: 'Artikel',
  confirmEmail: 'E-Mail bestätigen',
  invalidEmailConfirmation: 'Ungültige E-Mail-Bestätigung',
  addToCalendar: 'Zum Kalender hinzufügen',
  cartSummary: 'Warenkorb Zusammenfassung',
  eventTickets: 'Eventtickets',
  aboutEvent: 'Über die Veranstaltung',
  downloadTicket: 'Ticket herunterladen',
  addToWallet: 'Zur Brieftasche hinzufügen',
  editRegistration: 'Registrierung bearbeiten',
  purchaseExtras: 'Zusatzleistungen kaufen',
  carts: 'Warenkörbe',
  organizerNotFound: 'Veranstalter nicht gefunden',
  extras: 'Zusatzleistungen',
  registrationInfo: 'Registrierungsinformationen',
  ticketsPurchasedForOthers: 'Tickets für andere gekauft',
  eventPurchasedTicketsInfo: 'Du hast dich bereits für diese Veranstaltung registriert',
  view: 'ansicht',
  moreInfo: 'Mehr Info',
  maxQuantityPerOrderHasBeenReached: 'Maximale Bestellmenge erreicht',
  myOrders: 'Meine Bestellungen',
  liveAndUpcomingEvents: 'Live- und bevorstehende Veranstaltungen',
  brandName: 'Captello',
  save: 'Speichern',
  clear: 'Löschen',
  edit: 'Bearbeiten',
  verify: 'Überprüfen',
  change: 'Ändern',
  confirm: 'Bestätigen',
  minLeftForExtend: 'Minuten übrig für Verlängerung',
  cardNumber: 'Kartennummer',
  expiration: 'Ablaufdatum',
  cvv: 'CVV',
  submit: 'Einreichen',
} as const
