import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function setCookie(cookieName: string, value: string, expiresAt?: number): void {
  const options = {
    path: '/',
    sameSite: 'none' as const,
    expires: expiresAt ? new Date(expiresAt * 1000) : undefined,
    secure: true,
    maxAge: 2592000,
  }

  cookies.set(cookieName, value, options)
}

export function getCookie(cookieName: string): string {
  return cookies.get(cookieName)
}

export function removeCookie(CookieName: string): void {
  cookies.remove(CookieName, { path: '/' })
}

export function getSsrCookie(cookieName: string, reqCookie?: string): string | undefined {
  const cookies = new Cookies(reqCookie)
  return cookies.get(cookieName)
}
