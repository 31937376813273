import * as flatted from 'flatted'
import getConfig from 'next/config'
import _isEmpty from 'lodash/isEmpty'
import { IncomingMessage } from 'http'
import _kebabCas from 'lodash/kebabCase'
import _capitalize from 'lodash/capitalize'

import { Enum } from '../types'

export const isServer = (): boolean => typeof window === 'undefined'

export const isProduction = (): boolean => {
  return !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
}

export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development'
}

export const getLiteError = (error: any): Record<string, any> => {
  const result: Record<string, any> = {
    type: null,
    message: null,
    status: 400,
    data: null,
    error: error,
  }

  if (error.response) {
    const { status, message, data } = error.response
    result.type = `Response Error`
    result.message = data && (data.message || data.error || message)
    result.status = status || 400
    result.data = data || error.response
  } else if (error.request) {
    result.type = `Request Error`
    result.message = `The request was made but no response was received!`
    result.data = error.request
  } else {
    result.type = `Unknown Error`
    result.message = `${error.message}!`
    result.data = error
  }

  return result
}

export const stringify = (obj: Record<string, any>): string =>
  JSON.stringify(flatted.parse(flatted.stringify(obj)), null, 2)

export const redirectTo = (url: string): void => {
  if (!isServer() && url) window.location.href = url
}

export const absoluteUrl = (
  req?: IncomingMessage,
  setLocalhost?: string
): { protocol: string; host?: string | string[]; origin: string } => {
  let protocol = 'https:'
  let host: string | string[] | undefined = req
    ? req.headers['x-forwarded-host'] || req.headers['host']
    : !isServer()
      ? window.location.host
      : undefined
  if (host && host.indexOf('localhost') > -1) {
    if (setLocalhost) host = setLocalhost
    protocol = 'http:'
  }
  return {
    protocol: protocol,
    host: host,
    origin: protocol + '//' + host,
  }
}

export const getHost = (req?: IncomingMessage): string => {
  const location = absoluteUrl(req)
  return location.host && Array.isArray(location.host) ? location.host[0] : location.host || ''
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunc = (): void => {}

export const toBase64 = (str: string): string => Buffer.from(str).toString('base64')

export const fromBase64 = (str: string): string => Buffer.from(str).toString('ascii')

export const getVars = (): {
  apiUrl: string
  apiTimeout: string
  clientId: string
  clientSecret: string
  cartExtendExpirationAllowanceMinutes: number
  googleApiKey: string
  cognitoUserPoolID: string
  cognitoUserPoolWebClientID: string
  cognitoUserPoolRegion: string
  cognitoSessionDurationInMinutes: string
  ulcCaptureUrl: string
} => {
  const { publicRuntimeConfig } = getConfig()
  return publicRuntimeConfig
}

export function intToHex(num: number) {
  return num.toString(16).padStart(2, '0')
}

export function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('')
}

export const isEmpty = <T>(value?: T) => _isEmpty(value)

export const safeJsonParse = (json: string) => {
  try {
    return JSON.parse(json) as Record<string, any>
  } catch (e) {
    console.error(e)
    return null
  }
}

export const localTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

export const numberWithCommas = (num: number): string => Intl.NumberFormat().format(num)

export const currencyFormat = (amount: number, currency = 'USD'): string => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
  }).format(amount)
}

export function centToUSD(cents: number): string | null {
  return isNaN(cents) ? null : currencyFormat(cents / 100)
}

export const wordsCounter = (sentence: string): number => {
  return sentence.split(' ').length
}

export function capitalizeLabel(str: string): string {
  return _kebabCas(str).split('-').map(_capitalize).join(' ')
}

export const objToQueryString = (obj: Record<string, any>): string => new URLSearchParams(obj).toString()
export const decodeJWTToken = (token?: string) => {
  return token ? JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()) : null
}

export const downloadFile = async (fileLink: string, fileName: string) => {
  try {
    const res = await fetch(fileLink)
    const blob = await res.blob()
    const url = URL.createObjectURL(blob)

    const anchorElement = document.createElement('a')
    anchorElement.href = url
    anchorElement.download = `${fileName}`
    anchorElement.click()
  } catch (e) {
    console.error('download request failed', e)
  }
}

export const customPostMessage = (message: string, origin: string = '*') => {
  /*
   the mobile app will create a channel called eventGen,
   don't add * to the postMessage when sending to the mobile channel
   because it won't send it
   */

  if (typeof eventGen !== 'undefined') {
    eventGen.postMessage(message)
    return
  }

  window.parent.postMessage(message, origin)
}

export const isEnumValue = <T extends Enum<T>>(enumObj: T, value: string): value is T[keyof T] =>
  Object.values(enumObj).includes(value)
