import * as Yup from 'yup'
import _get from 'lodash/get'
import { Auth } from 'aws-amplify'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import log from '../../helpers/log'
import { useSnackBar } from '../../providers/SnackbarProvider'
import FormStateRPC from '../rpc/FormStateRPC'
import { AuthenticationMode } from '../../enums'
import { useAuth } from '../../providers/authProvider'
import { useTranslation } from '../../providers/localesProvider'
import { postPreAuthData } from '../../services/authService'
import { getHost, getRandomString } from '../../helpers/common'

import Button from '../shared/ui/Button'
import ControlledTextField from '../shared/form-fields/ControlledTextField'

type Props = {
  onLoggedIn?: () => void
}

function LimitedLoginForm(props: Props): JSX.Element {
  const { onLoggedIn } = props
  const { t } = useTranslation()

  const { enqueue } = useSnackBar()
  const { login } = useAuth()

  const validationSchema = Yup.object({
    email: Yup.string().email(t('invalidValue')).required(t('requiredField')),
    confirmEmail: Yup.string()
      .required(t('requiredField'))
      .oneOf([Yup.ref('email'), ''], t('invalidEmailConfirmation')),
  })

  const { control, handleSubmit } = useForm<{ email: string; confirmEmail: string }>({
    defaultValues: { email: '', confirmEmail: '' },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  })

  const onFormSubmit = handleSubmit(async (values: { email: string }, event): Promise<void> => {
    try {
      await postPreAuthData({ email: values.email, host: getHost(), authenticationMode: AuthenticationMode.limited })
      const user = await Auth.signIn(values.email)
      login(user.signInUserSession.idToken.jwtToken, true)

      onLoggedIn?.()
    } catch (err) {
      if (_get(err, 'code') === 'UserNotFoundException') {
        try {
          await Auth.signUp({
            username: values.email,
            password: `Pass@${getRandomString(30)}`,
            attributes: { name: values.email, 'custom:xHost': getHost() },
          })
          onFormSubmit(event).then()
        } catch (e) {
          enqueue({ title: 'error', body: t('logInFailed'), severity: 'error' })
          console.error(t('logInFailed'), e)
        }
      } else {
        enqueue({ title: 'error', body: t('logInFailed'), severity: 'error' })
      }
      console.error(err)
      log.debug(err)
    }
  })

  return (
    <Grid container>
      <form onSubmit={onFormSubmit} style={{ width: '100%' }}>
        <Grid item container justifyContent="center" mt={1}>
          <ControlledTextField
            data-cy="limitedEmailInput"
            fullWidth
            name="email"
            control={control}
            label={`${t('email')}`}
            placeholder={t('email')}
          />
        </Grid>

        <Grid item container justifyContent="center">
          <ControlledTextField
            data-cy="limitedConfirmEmailInput"
            fullWidth
            name="confirmEmail"
            control={control}
            label={`${t('confirmEmail')}`}
            placeholder={t('confirmEmail')}
            onPaste={(e) => e.preventDefault()}
          />
        </Grid>

        <FormStateRPC
          control={control}
          render={({ isSubmitting }) => {
            return (
              <Button type="submit" fullWidth disabled={isSubmitting} isLoading={isSubmitting} data-cy="limitedBtn">
                {t('continue')}
              </Button>
            )
          }}
        />
      </form>
    </Grid>
  )
}
export default LimitedLoginForm
