import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState, ReactNode, FC, useRef } from 'react'

import { CustomerSettings, SSRBrands } from '../types'
import { useTranslation } from '../providers/localesProvider'

import Link from '../components/shared/ui/Link'
import Image from '../components/shared/ui/Image'
import Button from '../components/shared/ui/Button'
import UserMenu from '../components/UserMenu'
import useIsMobile from '../hooks/useIsMobile'
import CartsMenu from '../components/CartsMenu'
import { useAuth } from '../providers/authProvider'
import LanguageMenu from '../components/LanguageMenu'
import LoginDialog from '../components/login/LoginDialog'
import GlobalSearch from '../components/search/GlobalSearch'
import VerifyEmailLoginForm from '../components/login/VerifyEmailLoginForm'
import { useBrandQuery, useCustomerSettingsQuery } from '../queryHooks/metaQuery'

const Header: FC<{ children?: ReactNode }> = ({ children }) => {
  const { data: ssrBrands = {} as SSRBrands } = useBrandQuery()
  const { data: customerSettingsData = {} as CustomerSettings } = useCustomerSettingsQuery()
  const { t } = useTranslation()
  const { isAuthenticated, isLimited } = useAuth()

  const { logo, headerBackgroundColor, isDisableSearch } = ssrBrands

  const isVerifiedRef = useRef(false)
  const [openLogInDialog, setOpenLogInDialog] = useState(false)
  const handleOpenLogInDialog = () => setOpenLogInDialog(true)
  const handleCloseLogInDialog = (openVerifiedDialog?: boolean) => {
    if (!openVerifiedDialog) {
      setOpenLogInDialog(false)
    }
  }
  const [openDropdown, setOpenDropdown] = useState(false)
  const toggleDropdown = () => setOpenDropdown((prevState) => !prevState)

  const isMobile = useIsMobile()
  const isSmallMobile = useIsMobile('sm')

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [openSearchBar, setOpenSearchBar] = useState(false)

  const toggleSearchBar = () => setOpenSearchBar((prev) => !prev)

  useEffect(() => {
    setIsAuthorized(isAuthenticated())
  }, [isAuthenticated])

  return (
    <div>
      {isLimited() && isAuthenticated() ? (
        <VerifyEmailLoginForm open={openLogInDialog} handleClose={handleCloseLogInDialog} />
      ) : (
        <LoginDialog open={openLogInDialog} handleClose={handleCloseLogInDialog} isVerified={isVerifiedRef.current} />
      )}

      <header style={{ backgroundColor: headerBackgroundColor }}>
        <Container maxWidth="lg">
          <Grid container alignItems="center">
            <AppBar position="static" color="transparent" elevation={0}>
              <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                <Link href={{ pathname: '/' }} passHref>
                  <Image
                    priority
                    src={logo || ''}
                    alt={t('brandName')}
                    style={{
                      height: '100%',
                      width: 'auto',
                      maxHeight: isSmallMobile ? 30 : 44,
                      paddingTop: '8px',
                      margin: 0,
                    }}
                    height={44}
                    width={200}
                  />
                </Link>

                {isMobile ? null : isDisableSearch ? null : <GlobalSearch />}

                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  flexWrap="nowrap"
                  sx={{ px: 1.5, width: 310 }}
                >
                  {isAuthorized ? (
                    <Grid>
                      <CartsMenu />
                    </Grid>
                  ) : null}

                  <Divider orientation="vertical" sx={{ height: 40, bgcolor: 'gray' }} />

                  {isDisableSearch ? null : (
                    <IconButton onClick={toggleSearchBar} sx={{ display: { xs: 'flex', md: 'none' } }}>
                      <SearchIcon sx={{ color: 'white', fontSize: 28 }} />
                    </IconButton>
                  )}

                  {customerSettingsData.isEnableLocalization &&
                  customerSettingsData.localizationSettings.languages?.length > 1 ? (
                    <LanguageMenu localizationSettings={customerSettingsData.localizationSettings} />
                  ) : null}

                  {isAuthorized ? (
                    <UserMenu
                      handleOpenLogInDialog={() => {
                        isVerifiedRef.current = true
                        handleOpenLogInDialog()
                      }}
                    />
                  ) : isMobile ? (
                    <IconButton sx={{ color: 'common.white' }} onClick={toggleDropdown}>
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <Button
                      data-cy="signInBtn"
                      onClick={() => {
                        isVerifiedRef.current = false
                        handleOpenLogInDialog()
                      }}
                      sx={{ ml: 1 }}
                    >
                      {t('signIn')}
                    </Button>
                  )}
                </Grid>
              </Toolbar>

              {openSearchBar ? (
                <Grid sx={{ display: { md: 'none' }, m: 1 }}>
                  <GlobalSearch mobileView />
                </Grid>
              ) : null}

              {openDropdown && !isAuthorized ? (
                <Grid container p={1}>
                  <Button
                    fullWidth
                    data-cy="signInBtn"
                    onClick={() => {
                      isVerifiedRef.current = false
                      handleOpenLogInDialog()
                    }}
                    sx={{ ml: 1 }}
                  >
                    {t('signIn')}
                  </Button>
                </Grid>
              ) : null}
            </AppBar>
          </Grid>
          {children}
        </Container>
      </header>
    </div>
  )
}

export default Header
