import { UrlObject } from 'url'
import _omit from 'lodash/omit'
import { useRouter } from 'next/router'
import NextLink, { LinkProps } from 'next/link'
import { ParsedUrlQueryInput } from 'querystring'
import { AnchorHTMLAttributes, ReactElement } from 'react'

type LinkAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps

type ComponentProps = Omit<LinkAnchorProps, 'href'> & {
  children: ReactElement
  href: UrlObject
}
function Link(props: ComponentProps): JSX.Element {
  const router = useRouter()
  const { children, href, ...rest } = props
  const { query } = href
  // to fix eventId, organizerId extra query params
  const routerQuery = _omit(router.query, ['eventId', 'organizerId'])

  return (
    <NextLink
      href={{
        ...href,
        query: { ...routerQuery, ...(query as ParsedUrlQueryInput) },
      }}
      {...rest}
    >
      {children}
    </NextLink>
  )
}

export default Link
