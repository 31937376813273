import _get from 'lodash/get'
import { Grid } from '@mui/material'
import { ControllerFieldState } from 'react-hook-form'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText'

type ComponentProps = FormHelperTextProps & {
  fieldState: ControllerFieldState
  helperText?: string | undefined
}
export default function FormFieldHelperText(props: ComponentProps): JSX.Element | null {
  const { helperText, fieldState, ...rest } = props
  return (
    <Grid container sx={{ minHeight: '25px', width: '100%', px: '10px', mb: helperText ? 1 : 0 }}>
      {_get(fieldState, 'error.message', helperText) ? (
        <FormHelperText error={!!fieldState.error} {...rest} sx={{ fontWeight: 600, display: 'flex', pb: 1 }}>
          {fieldState.error?.message ? (
            fieldState.error?.message
          ) : helperText ? (
            <>
              <InfoOutlinedIcon sx={{ fontSize: 16, mt: '3px', mr: '4px' }} />
              {helperText}
            </>
          ) : null}
        </FormHelperText>
      ) : null}
    </Grid>
  )
}
