import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { toBase64 } from '../helpers/common'
import { loadScript } from '../helpers/utils'
import { useAuth } from '../providers/authProvider'
import { useCustomerSettingsQuery } from '../queryHooks/metaQuery'
import { useCognitoUserInfo, useFetchProfile } from '../queryHooks/profileQuery'

export default function useAddTrackingCode() {
  const { isAuthenticated, isLimited, isUnlimited } = useAuth()
  const isLimitedValue = isLimited()
  const isAuthenticatedValue = isAuthenticated()
  const { data: customerSettingsData } = useCustomerSettingsQuery()
  const { data: cognitoUserInfo, isLoading: isCognitoUserInfoLoading } = useCognitoUserInfo({
    enabled: isLimited(),
  })
  const { trackingCode } = customerSettingsData ?? {}
  const router = useRouter()
  const isMounted = useRef(false)

  const { data, isLoading } = useFetchProfile({ enabled: isUnlimited })

  useEffect(() => {
    if (!isLoading && !isCognitoUserInfoLoading) {
      const HEUser = isLimitedValue ? cognitoUserInfo?.attributes?.email : data?.uuid
      const encodedHeUser = HEUser ? toBase64(HEUser.toString()) : undefined
      window.ll_he_uid_enc = `${encodedHeUser}`

      if (isMounted.current) {
        isAuthenticatedValue && window.ll_restart_tracking_enging?.()
      } else {
        isMounted.current = true
        trackingCode && loadScript(` ${trackingCode}`, 'trackingCodeId').finally()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticatedValue, isLimitedValue, isCognitoUserInfoLoading])

  // // detect route change
  useEffect(() => {
    if (window.ll_restart_tracking_enging && isMounted.current) window.ll_restart_tracking_enging?.()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])
}
