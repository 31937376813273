import _get from 'lodash/get'
import { ReactElement, useState } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { isProduction } from '../helpers/common'

function ReactQueryContainer(props: { children: ReactElement | ReactElement[] }): ReactElement {
  const { children } = props

  // ensures that data is not shared between different users and requests, while still only creating the QueryClient once per component lifecycle
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // query options
            retry: 3,
            refetchOnWindowFocus: false,
            staleTime: 10000,
            retryDelay: 500,
            gcTime: 1000 * 60 * 60,
          },
          mutations: {
            // mutation options
            retry: (number, error) => _get(error, 'status') === 401 && number < 2,
            retryDelay: 500,
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!isProduction() ? <ReactQueryDevtools initialIsOpen={false} /> : <></>}
    </QueryClientProvider>
  )
}

export default ReactQueryContainer
