import MuiButton, { ButtonProps } from '@mui/material/Button'
import { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react'

import Spinner from '../Spinner'
import Grid from '@mui/material/Grid'

export type Ref = HTMLButtonElement
type ComponentProps = ButtonProps & { isLoading?: boolean; target?: string }

const Button: ForwardRefExoticComponent<PropsWithoutRef<ComponentProps> & RefAttributes<HTMLButtonElement>> =
  forwardRef<Ref, ComponentProps>((props: ComponentProps, buttonRef) => {
    const { isLoading, color = 'primary', size = 'medium', variant = 'contained', ...rest } = props

    return (
      <MuiButton ref={buttonRef} color={color} variant={variant} size={size} {...rest}>
        <Grid container justifyContent="center" alignItems="center" sx={{ visibility: isLoading ? 'hidden' : 'unset' }}>
          {props.children}
        </Grid>

        {isLoading ? (
          <Grid
            container
            sx={{
              top: 0,
              position: 'absolute',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Grid>
        ) : null}
      </MuiButton>
    )
  })

Button.displayName = 'Button'

export default Button
