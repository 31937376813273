import { useRouter } from 'next/router'
import { useState, MouseEvent } from 'react'
import { Badge, Grid, Menu, MenuItem, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import Image from './shared/ui/Image'
import Button from './shared/ui/Button'

import { centToUSD } from '../helpers/common'
import { useTranslation } from '../providers/localesProvider'
import { useCartsSummaryQuery } from '../queryHooks/cartQuery'

function CartsMenu() {
  const { t } = useTranslation()
  const router = useRouter()

  const { data } = useCartsSummaryQuery()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const goToRoute = async (to: string): Promise<void> => {
    handleClose()
    await router.push({ pathname: to })
  }

  return Array.isArray(data) && data.length ? (
    <>
      <Button variant="text" sx={{ color: 'common.white', textTransform: 'none' }} onClick={handleClick}>
        <Badge color="primary" sx={{ mr: 2 }} badgeContent={data.length}></Badge>
        {t('carts')}
        <KeyboardArrowDownIcon />
      </Button>

      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {data.map((cart, idx) => (
          <MenuItem
            key={idx}
            sx={{
              minWidth: 350,
              borderBottom: idx < data.length - 1 ? '1px solid #0000001f' : 'none',
            }}
            onClick={(): Promise<void> => goToRoute(`/${cart.event.uriTitle}/cart`)}
          >
            <Grid container gap={1} alignItems="center">
              <Grid>
                <Image
                  src={cart.event?.details?.coverMedia?.url ?? '/images/placeholder-img.png'}
                  style={{
                    objectFit: 'cover',
                  }}
                  alt={cart?.event?.name || ''}
                  width={100}
                  height={55}
                />
              </Grid>

              <Grid>
                <Typography noWrap sx={{ mb: 1, fontSize: 14, color: 'text.primary' }}>
                  {cart.event.name}
                </Typography>

                <Grid item container gap={1.5}>
                  <Typography sx={{ fontSize: 13, color: 'text.secondary' }}>{t('total')}</Typography>
                  <Typography sx={{ fontSize: 13, color: 'text.primary' }}>
                    {`${centToUSD(cart.totalPriceCent ?? 0)}`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid sx={{ marginLeft: 'auto' }}>
                <KeyboardArrowRightIcon sx={{ color: 'text.secondary' }} />
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null
}
export default CartsMenu
