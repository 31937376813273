import dayjs from 'dayjs'
import djsTimeZone from 'dayjs/plugin/timezone'
import djsAdvancedFormat from 'dayjs/plugin/advancedFormat'

import { LocaleCode } from '../enums'
import { localTimeZone } from '../helpers/common'
import { useTranslation } from '../providers/localesProvider'

dayjs.extend(djsTimeZone)
dayjs.extend(djsAdvancedFormat)

const dateTimeFormatMap = {
  [LocaleCode.english]: { dateAndTime: 'MMM Do YYYY, hh:mm A (z)', dateOnly: 'MMM Do YYYY', monthAbbreviation: 'MMM' },
  [LocaleCode.french]: {
    dateAndTime: 'D MMMM YYYY [à] kk[h]mm (z)',
    dateOnly: 'D MMMM YYYY',
    monthAbbreviation: 'MMMM',
  },
}

// temporary solution to handle abbreviation localization
const timeZoneMap = {
  [LocaleCode.french]: { EDT: 'HAE', EST: 'HNE' },
}
const timeZoneRegEx = (localeCode: keyof typeof timeZoneMap) =>
  new RegExp(Object.keys(timeZoneMap[localeCode]).join('|'))

export const useDayJs = () => {
  const { code } = useTranslation()

  const monthAbbreviationFormat =
    code in dateTimeFormatMap
      ? dateTimeFormatMap[code as keyof typeof dateTimeFormatMap].monthAbbreviation
      : dateTimeFormatMap[LocaleCode.english].monthAbbreviation

  const format = ({
    timeStamp,
    timeZone = localTimeZone(),
    format,
    isShowDateAndTime = true,
  }: {
    timeStamp: number
    timeZone?: string
    format?: string
    isShowDateAndTime?: boolean
  }): string => {
    const defaultFormat = dateTimeFormatMap[LocaleCode.english]

    // refactor later
    const dateFormat =
      format ??
      (isShowDateAndTime
        ? dateTimeFormatMap[code as keyof typeof dateTimeFormatMap]?.dateAndTime ?? defaultFormat.dateAndTime
        : dateTimeFormatMap[code as keyof typeof dateTimeFormatMap]?.dateOnly ?? defaultFormat.dateOnly)

    const formattedDate = dayjs(timeStamp * 1000)
      .tz(timeZone)
      .format(dateFormat)

    // temporary solution to handle abbreviation localization
    return code in timeZoneMap
      ? formattedDate.replace(timeZoneRegEx(code as keyof typeof timeZoneMap), (zone) => {
          return timeZoneMap[code as keyof typeof timeZoneMap][
            zone as keyof (typeof timeZoneMap)[keyof typeof timeZoneMap]
          ]
        })
      : formattedDate
  }

  function formatRange({
    startTimeStamp,
    endTimeStamp,
    isShowStartTime = true,
    isShowEndTime = true,
    timeZone = localTimeZone(),
  }: {
    startTimeStamp: number
    endTimeStamp: number
    isShowStartTime?: boolean
    isShowEndTime?: boolean
    timeZone?: string
  }): string {
    return dayjs(startTimeStamp * 1000)
      .tz(timeZone)
      .isSame(dayjs(endTimeStamp * 1000).tz(timeZone), 'day')
      ? `${format({
          timeStamp: startTimeStamp,
          isShowDateAndTime: isShowStartTime,
          timeZone,
        })} ${isShowEndTime ? `- ${format({ timeStamp: endTimeStamp, format: 'hh:mm A (z)', timeZone })}` : ''}`
      : `${format({
          timeStamp: startTimeStamp,
          isShowDateAndTime: isShowStartTime,
          timeZone,
        })} - ${format({
          timeStamp: endTimeStamp,
          isShowDateAndTime: isShowEndTime,
          timeZone,
        })}`
  }

  function formatDateDayRange({
    startTimeStamp,
    endTimeStamp,
    timeZone,
  }: {
    startTimeStamp: number
    endTimeStamp: number
    timeZone?: string
  }): string {
    return dayjs(startTimeStamp * 1000).isSame(dayjs(endTimeStamp * 1000), 'month') &&
      !dayjs(startTimeStamp * 1000)
        .tz(timeZone)
        .isSame(dayjs(endTimeStamp * 1000).tz(timeZone), 'day')
      ? `${format({ timeStamp: startTimeStamp, format: 'D', timeZone: timeZone })}-${format({
          timeStamp: endTimeStamp,
          format: 'D',
          timeZone: timeZone,
        })}`
      : format({ timeStamp: startTimeStamp, format: 'D', timeZone: timeZone })
  }

  return { format, formatRange, formatDateDayRange, monthAbbreviationFormat }
}
