import { LocaleCode } from '../enums'
import { arEG, deDE, enUS, esES, frFR, itIT, jaJP, koKR, ptPT, zhCN } from '@mui/material/locale'

export const MuiLocalesMap = {
  [LocaleCode.english]: enUS,
  [LocaleCode.french]: frFR,
  [LocaleCode.german]: deDE,
  [LocaleCode.arabic]: arEG,
  [LocaleCode.italian]: itIT,
  [LocaleCode.chinese]: zhCN,
  [LocaleCode.japanese]: jaJP,
  [LocaleCode.spanish]: esES,
  [LocaleCode.korean]: koKR,
  [LocaleCode.Portuguese]: ptPT,
}

export const PayPalLocalesMap = {
  [LocaleCode.english]: 'en_US',
  [LocaleCode.french]: 'fr_FR',
  [LocaleCode.german]: 'de_DE',
  [LocaleCode.arabic]: 'ar_EG',
  [LocaleCode.italian]: 'it_IT',
  [LocaleCode.chinese]: 'zh_CN',
  [LocaleCode.japanese]: 'ja_JP',
  [LocaleCode.spanish]: 'es_ES',
  [LocaleCode.korean]: 'ko_KR',
  [LocaleCode.Portuguese]: 'pt_PT',
}
