export const italianTranslation = {
  signIn: 'Accedi',
  signUp: 'Registrati',
  search: 'Cerca',
  firstName: 'Nome',
  lastName: 'Cognome',
  emailAddress: 'Indirizzo Email',
  password: 'Password',
  requiredField: 'Campo obbligatorio',
  invalidValue: 'Valore non valido',
  fillUpFieldsToCompletePayment: 'Compila tutti i campi per completare il pagamento',
  paymentFailed: 'Pagamento fallito',
  somethingWrong: 'Qualcosa è andato storto.',
  paymentNotSuccessful: 'Il tuo pagamento non è andato a buon fine, ti preghiamo di riprovare.',
  logInFailed: 'Accesso fallito',
  phone: 'Telefono',
  address: 'Indirizzo',
  address1: 'Indirizzo 1',
  address2: 'Indirizzo 2',
  country: 'Paese',
  state: 'Stato',
  city: 'Città',
  zipcode: 'CAP',
  saveChanges: 'Salva modifiche',
  reset: 'Reimposta',
  saveChangesSuccess: 'Salvato',
  saveChangesFailed: 'Impossibile salvare le modifiche',
  details: 'Dettagli',
  aboutThisEvent: "Informazioni sull'evento",
  tags: 'Tag',
  findUsOn: 'Trova su',
  salesStarts: 'Le vendite iniziano',
  dateAndTime: 'Data e ora',
  from: 'Da',
  to: 'A',
  location: 'Luogo',
  profile: 'Profilo',
  favoriteEvents: 'Eventi preferiti',
  noEvents: 'Nessun evento',
  toggleFavoriteEventFailed: "Impossibile attivare/disattivare l'evento preferito",
  logout: 'Esci',
  organizer: 'Organizzatore',
  eventNotFound: 'Evento non trovato',
  tickets: 'Biglietti',
  ticketsDetails: 'Dettagli dei biglietti',
  salesEndsOn: 'Le vendite terminano il',
  fee: 'Tariffa',
  free: 'Gratuito',
  soldOut: 'Esaurito',
  salesStartsOn: 'Le vendite iniziano il',
  register: 'Registrati',
  guest: 'Ospite',
  noTickets: 'Nessun biglietto',
  minsLeftForCheckout: 'minuti rimasti per il checkout',
  deleteCartItemSuccess: 'Elemento del carrello eliminato',
  deleteCartItemFailed: "Impossibile eliminare l'elemento dal carrello",
  deleteTicketCartItemSuccess: 'Carrello svuotato',
  deleteTicketCartItemFailed: 'Impossibile eliminare gli elementi dal carrello',
  total: 'Totale',
  totalDue: 'Totale dovuto',
  subTotal: 'Subtotale',
  update: 'Aggiorna',
  cartExpired: 'Carrello scaduto',
  checkout: 'Checkout',
  orderSummary: 'Riepilogo ordine',
  contactInfo: 'Informazioni di contatto',
  orderInfo: "Informazioni sull'ordine",
  name: 'Nome',
  cardInfo: 'Informazioni sulla carta',
  placeOrder: "Effettua l'ordine",
  mobilePhone: 'Telefono cellulare',
  placeOrderSuccess: 'Ordine effettuato',
  placeOrderFailed: "Impossibile effettuare l'ordine",
  extend: 'Estendi',
  cancel: 'Annulla',
  extendEventCartExpirationSuccess: 'Scadenza del carrello estesa',
  extendEventCartExpirationFailed: 'Impossibile estendere la scadenza del carrello',
  expirationExtendQuestion: 'Desideri prolungare il tempo di scadenza del carrello?',
  orders: 'Ordini',
  myTickets: 'I miei biglietti',
  upcomingEvents: 'Eventi in arrivo',
  pastEvents: 'Eventi Passati',
  eventDetails: "Dettagli dell'evento",
  summary: 'Sommario',
  events: 'Eventi',
  organizerEvents: "Eventi dell'organizzatore",
  eventsSearchResultFor: 'Risultati della ricerca degli eventi per',
  viewAll: 'Visualizza tutti',
  noTags: 'Nessun tag',
  backToEventDetails: "Torna ai dettagli dell'evento",
  removeAll: 'Rimuovi tutto',
  areYouSureYouWantToRemoveAllTickets: 'Sei sicuro di voler rimuovere tutti i biglietti?',
  minQuantityPerOrderIs: 'Quantità minima per ordine',
  maxQuantityPerOrderIs: 'Quantità massima per ordine',
  aboutTheOrganizer: "Informazioni sull'organizzatore",
  viewOnMap: 'Visualizza sulla mappa',
  viewOrganizerEvents: "Visualizza gli eventi dell'organizzatore",
  invalidEmail: 'Email non valida',
  invalidNumber: 'Numero non valido',
  unavailable: 'Non disponibile',
  available: 'Disponibile',
  addons: 'Extra',
  unlimited: 'Illimitato',
  noOrders: 'Nessun ordine',
  email: 'Email',
  termsOfService: 'Termini di servizio',
  privacyPolicy: 'informativa sulla privacy',
  privacyPolicyHint: 'linee guida della community e ho letto le',
  backToEventTickets: "Torna ai biglietti dell'evento",
  step: 'Passo',
  activities: 'Attività',
  price: 'Prezzo',
  missingPaymentKeyMessage: "Impossibile elaborare il tuo pagamento. Contatta l'amministratore dell'evento.",
  sendVerificationCode:
    'Per continuare con la registrazione, dovremo verificare il tuo indirizzo email. Inserisci la tua email qui sotto per ricevere un codice di verifica monouso.',
  verifyYourEmail: 'Verifica il tuo indirizzo email',
  checkVerificationCode: 'Ti abbiamo inviato il tuo codice di verifica monouso. Controlla la tua email.',
  failedSendVerificationCode: 'Impossibile inviare il codice di verifica',
  back: 'Indietro',
  remainingAttempts: 'Tentativi rimanenti',
  wrongVerificationCode: 'Codice di verifica errato. Riprova.',
  resendAnotherCode: 'Invia un altro codice',
  wrongEmailVerificationCode: 'Email o codice di verifica errati. Riprova.',
  aboutOrganizer: "Informazioni sull'organizzatore",
  proceedToCheckout: 'Procedi al Checkout',
  ticket: 'Biglietto',
  saveAndAddAnotherTicket: 'Salva e Aggiungi un altro biglietto',
  addToAppleWallet: 'Aggiungi a Apple wallet',
  addToGoogleWallet: 'Aggiungi a Google wallet',
  downloadFailed: 'Download fallito',
  ios: 'iOS',
  android: 'Android',
  allRightsReserved: 'Tutti i diritti riservati',
  fillFormWithInformation: 'Compila il modulo con le mie informazioni',
  shareEventHint: 'Condividi questo evento sui social media o su altri canali.',
  favoriteEventHint:
    "Gli eventi che preferisci appaiono sotto un cuore nell'angolo in alto a destra per un accesso più facile. Un cuore pieno significa che hai preferito l'evento. Un cuore delineato significa che l'evento non è preferito. Dopo averlo preferito, clicca di nuovo sul cuore per rimuoverlo dai preferiti.",
  sendCode: 'invia codice',
  continue: 'continua',
  sendConfirmationEmail: 'Invia email di conferma a',
  specifyContactInfo: 'Specifica le informazioni di contatto',
  confirmationEmail: 'Una email di conferma che include i tuoi biglietti verrà inviata a questo contatto',
  oops: 'Ops,',
  somethingWentWrong: 'Qualcosa è andato storto!',
  next: 'Avanti',
  registration: 'Registrazione',
  includedItems: 'Articoli inclusi',
  packages: 'Pacchetti',
  select: 'Seleziona',
  selected: 'Selezionato',
  categories: 'Categorie',
  all: 'Tutti',
  noAddons: 'Nessun extra',
  noActivities: 'Nessuna attività',
  noSessions: 'Nessuna sessione',
  noMoreAvailable: 'Nessun altro disponibile',
  reachedMaxQuantityPerOrder: 'Quantità massima raggiunta per ordine',
  extraMinQuantityPerOrderIs: 'quantità minima per ordine è',
  didNotMeetMinQuantityPerOrder: 'Non hai raggiunto la quantità minima per ordine',
  discountCode: 'Codice sconto',
  remove: 'Rimuovi',
  apply: 'Applica',
  discount: 'Sconto',
  totalPaid: 'Totale pagato',
  ticketType: 'Tipo di biglietto',
  sessions: 'Sessioni',
  speakers: 'Relatori',
  sponsors: 'Sponsor',
  aboutThisSession: 'Informazioni sulla sessione',
  sessionDetails: 'Dettagli della sessione',
  myCart: 'Il mio carrello',
  items: 'Articoli',
  confirmEmail: 'Conferma email',
  invalidEmailConfirmation: 'Email di conferma non valida',
  addToCalendar: 'Aggiungi al calendario',
  cartSummary: 'Riepilogo carrello',
  eventTickets: "Biglietti per l'evento",
  aboutEvent: "Informazioni sull'evento",
  downloadTicket: 'Scarica il biglietto',
  addToWallet: 'Aggiungi al portafoglio',
  editRegistration: 'Modifica registrazione',
  purchaseExtras: 'Acquista extra',
  carts: 'Carrelli',
  organizerNotFound: 'Organizzatore non trovato',
  extras: 'Extra',
  registrationInfo: 'Informazioni di registrazione',
  ticketsPurchasedForOthers: 'Biglietti acquistati per altri',
  eventPurchasedTicketsInfo: 'Ti sei già registrato per questo evento',
  view: 'visualizza',
  moreInfo: 'Ulteriori informazioni',
  maxQuantityPerOrderHasBeenReached: 'È stata raggiunta la quantità massima per ordine',
  myOrders: 'I miei ordini',
  liveAndUpcomingEvents: 'Eventi in diretta e imminenti',
  brandName: 'Captello',
  save: 'Salva',
  clear: 'Cancella',
  edit: 'Modifica',
  verify: 'Verifica',
  change: 'Cambia',
  confirm: 'Conferma',
  minLeftForExtend: 'minuti rimasti per estendere',
  cardNumber: 'Numero di carta',
  expiration: 'Scadenza',
  cvv: 'CVV',
  submit: 'Invia',
} as const
