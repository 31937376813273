import { AxiosError } from 'axios'
import { Auth } from 'aws-amplify'
import {
  useQuery,
  useMutation,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
  UseQueryOptions,
  QueryFunction,
  MutationFunction,
} from '@tanstack/react-query'

import { CognitoUserInfo, HexUserProfile, PatchProfileVars } from '../types'
import { patchProfile } from '../services/profileService'
import axios from '../helpers/axios'

const profileKey = 'profile'
const cognitoProfileKey = 'cognito-profile'

export function useFetchProfile(
  queryOptions?: Partial<UseQueryOptions<HexUserProfile>>
): UseQueryResult<HexUserProfile> {
  const queryFn: QueryFunction<HexUserProfile> = ({ signal }) =>
    axios.get(`/profiles/me`, { signal }).then((res) => res.data)

  return useQuery({ queryKey: [profileKey], queryFn, ...queryOptions })
}

export function usePatchProfile(): UseMutationResult<HexUserProfile, AxiosError, PatchProfileVars, PatchProfileVars> {
  const queryClient = useQueryClient()
  const mutationFn: MutationFunction<HexUserProfile, PatchProfileVars> = ({ data, formData }) =>
    patchProfile(data, formData)

  return useMutation({
    mutationFn,
    onSuccess(data) {
      queryClient.setQueryData([profileKey], data)
    },
  })
}

export function useCognitoUserInfo(
  queryOptions?: Partial<UseQueryOptions<CognitoUserInfo>>
): UseQueryResult<CognitoUserInfo> {
  const queryFn = (): Promise<CognitoUserInfo> => Auth.currentUserInfo().then((data) => data)
  return useQuery({ queryKey: [cognitoProfileKey], queryFn, ...queryOptions })
}
