import { HexAxiosError } from '../types'
import axios, { AxiosError, AxiosInterceptorOptions, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import log from './log'
import { getLiteError, stringify, isServer, getVars, getHost, isDevelopment } from './common'

const { apiUrl: baseURL, apiTimeout: timeout } = getVars()
log.info(`publicRuntimeConfig: `, getVars())

// handlers
export const onRequestFulfilled = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // Do something before request is sent
  //log.debug('Axios Request Config:', config)
  if (isServer()) {
    log.info(
      `Axios Request:`,
      stringify({
        REQUEST: `${config.method?.toUpperCase()} => ${config.baseURL}${config.url}`,
        HEADERS: config.headers,
        PARAMS: config.params,
        DATA: config.data,
      })
    )
  }

  return config
}

export const onRequestRejected = (error: AxiosError): Promise<AxiosError> => {
  // Do something with request error
  const err = getLiteError(error)
  if (isServer()) log.error(error, `onRequestRejected: `, err.message)
  return Promise.reject(error)
}

export const onResponseFulfilled = (response: AxiosResponse): AxiosResponse => {
  if (isServer() && isDevelopment()) log.info(`Axios Response:`, response.data)
  return response
}

export const onResponseRejected = (error: AxiosError): Promise<AxiosError> => {
  const err = getLiteError(error)

  if (isServer() && isDevelopment()) log.error(error, `onResponseRejected: `, err.message)
  if (axios.isCancel(error)) log.error(error, `Aborted: ${axios.isCancel(error)}`)
  return Promise.reject(err)
}

// init
const instance = axios.create({
  baseURL,
  timeout: Number(timeout),
  headers: {
    'Content-Type': 'application/json',
    ...(!isServer() && { 'X-HOST': getHost() }),
  },
})

// Global error handling
if (isDevelopment()) {
  instance.interceptors.request.use(onRequestFulfilled, onRequestRejected)
}
instance.interceptors.response.use(onResponseFulfilled, onResponseRejected)

// helpers

export const setHeader = (key: string, val = ''): void => {
  instance.defaults.headers.common[key] = val
  log.info(`Set ${key} header to: ${val}`)
}

export function setUnauthenticatedCallback(
  onError: (err: HexAxiosError) => Promise<AxiosError | AxiosResponse>
): number {
  function onFulfilled(res: AxiosResponse): AxiosResponse {
    return res
  }

  return instance.interceptors.response.use(onFulfilled, onError)
}

export function setRequestInterceptorCallback(
  onFulfilled: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig = (config) => config,
  options?: AxiosInterceptorOptions
): number {
  return instance.interceptors.request.use(onFulfilled, null, options)
}

export function ejectResponseInterceptor(id: number): void {
  return instance.interceptors.response.eject(id)
}

export function ejectRequestInterceptor(id: number): void {
  return instance.interceptors.request.eject(id)
}

export default instance
