import {
  useQuery,
  queryOptions,
  UseQueryResult,
  UseQueryOptions,
  UndefinedInitialDataOptions,
} from '@tanstack/react-query'

import axios from '../helpers/axios'
import { TicketMode } from '../enums'
import { EventTicket, HexEvent } from '../types'
import { getEventTicketsById } from '../services/ticketService'
import { AxiosRequestConfig } from 'axios'

const ticketQueryKey = 'ticket'
export const eventTicketsKey = 'event-tickets'

export const eventTicketsQueryOptions = (
  {
    eventUuid,
    mode,
    queryParameters,
    headers,
  }: {
    eventUuid: HexEvent['uuid']
    mode: TicketMode
    queryParameters?: Record<string, string | string[] | undefined>
    headers?: AxiosRequestConfig['headers']
  },
  options?: UndefinedInitialDataOptions<Array<EventTicket>>
) =>
  queryOptions({
    queryKey: [eventTicketsKey, { eventUuid, mode }],
    queryFn: () => getEventTicketsById({ eventUuid, mode, queryParameters }, { headers }).then((res) => res.data),
    ...options,
  })

export function useEventTickets(
  {
    eventUuid,
    mode,
    queryParameters,
  }: {
    eventUuid: HexEvent['uuid']
    queryParameters?: Record<string, string | string[] | undefined>
    mode: TicketMode
  },
  options?: Partial<UseQueryOptions<Array<EventTicket>>>
): UseQueryResult<Array<EventTicket>> {
  return useQuery({ ...eventTicketsQueryOptions({ eventUuid, mode, queryParameters }), ...options })
}

export const ticketQueryOptions = (
  id: EventTicket['id'],
  options?: Partial<UndefinedInitialDataOptions<EventTicket>>
) => {
  return queryOptions({
    queryKey: [ticketQueryKey, { id: `${id}` }],
    queryFn: ({ signal }) => axios.get(`/event-tickets/${id}`, { signal }).then((res) => res.data),
    ...options,
  })
}

export const useTicket = (
  id: EventTicket['id'],
  options?: Partial<UseQueryOptions<EventTicket>>
): UseQueryResult<EventTicket> => useQuery({ ...ticketQueryOptions(id), ...options })
