export const chineseTranslation = {
  signIn: '登录',
  signUp: '注册',
  search: '搜索',
  firstName: '名字',
  lastName: '姓氏',
  emailAddress: '邮箱地址',
  password: '密码',
  requiredField: '必填字段',
  invalidValue: '无效数值',
  fillUpFieldsToCompletePayment: '请填写所有字段以完成付款',
  paymentFailed: '付款失败',
  somethingWrong: '出现问题',
  paymentNotSuccessful: '您的付款未成功，请重试',
  logInFailed: '登录失败',
  phone: '电话',
  address: '地址',
  address1: '地址 1',
  address2: '地址 2',
  country: '国家',
  state: '州',
  city: '城市',
  zipcode: '邮政编码',
  saveChanges: '保存更改',
  reset: '重置',
  saveChangesSuccess: '已保存',
  saveChangesFailed: '保存更改失败',
  details: '详情',
  aboutThisEvent: '关于此活动',
  tags: '标签',
  findUsOn: '在以下位置找到我们',
  salesStarts: '销售开始',
  dateAndTime: '日期和时间',
  from: '从',
  to: '至',
  location: '地点',
  profile: '个人资料',
  favoriteEvents: '收藏的活动',
  noEvents: '无活动',
  toggleFavoriteEventFailed: '无法切换收藏的活动',
  logout: '登出',
  organizer: '组织者',
  eventNotFound: '未找到活动',
  tickets: '门票',
  ticketsDetails: '门票详情',
  salesEndsOn: '销售截止于',
  fee: '费用',
  free: '免费',
  soldOut: '售罄',
  salesStartsOn: '销售开始于',
  register: '注册',
  guest: '访客',
  noTickets: '无门票',
  minsLeftForCheckout: '分钟内结账',
  deleteCartItemSuccess: '购物车项目已删除',
  deleteCartItemFailed: '无法删除购物车中的项目',
  deleteTicketCartItemSuccess: '购物车已清空',
  deleteTicketCartItemFailed: '无法删除购物车中的项目',
  total: '总计',
  totalDue: '应付总额',
  subTotal: '小计',
  update: '更新',
  cartExpired: '购物车已过期',
  checkout: '结账',
  orderSummary: '订单摘要',
  contactInfo: '联系信息',
  orderInfo: '订单信息',
  name: '姓名',
  cardInfo: '卡信息',
  placeOrder: '下单',
  mobilePhone: '手机号码',
  placeOrderSuccess: '订单已下单',
  placeOrderFailed: '下单失败',
  extend: '延长',
  cancel: '取消',
  extendEventCartExpirationSuccess: '购物车过期时间已延长',
  extendEventCartExpirationFailed: '无法延长购物车过期时间',
  expirationExtendQuestion: '您是否希望延长购物车过期时间？',
  orders: '订单',
  myTickets: '我的门票',
  upcomingEvents: '即将举行的活动',
  pastEvents: '过去的活动',
  eventDetails: '活动详情',
  summary: '摘要',
  events: '活动',
  organizerEvents: '组织者的活动',
  eventsSearchResultFor: '搜索结果为活动',
  viewAll: '查看全部',
  noTags: '无标签',
  backToEventDetails: '返回活动详情',
  removeAll: '移除全部',
  areYouSureYouWantToRemoveAllTickets: '您确定要移除所有门票吗？',
  minQuantityPerOrderIs: '每个订单的最小数量为',
  maxQuantityPerOrderIs: '每个订单的最大数量为',
  aboutTheOrganizer: '关于组织者',
  viewOnMap: '查看地图',
  viewOrganizerEvents: '查看组织者的活动',
  invalidEmail: '无效的电子邮件',
  invalidNumber: '无效的号码',
  unavailable: '不可用',
  available: '可用',
  addons: '附加服务',
  unlimited: '无限制',
  noOrders: '无订单',
  email: '电子邮件',
  termsOfService: '服务条款',
  privacyPolicy: '隐私政策',
  privacyPolicyHint: '社区准则并已阅读',
  backToEventTickets: '返回活动门票',
  step: '步骤',
  activities: '活动',
  price: '价格',
  missingPaymentKeyMessage: '无法处理您的付款。请联系您的活动管理员。',
  sendVerificationCode: '为继续注册，我们需要验证您的电子邮件地址。请输入您的电子邮件以接收一次性验证代码。',
  verifyYourEmail: '验证您的电子邮件地址',
  checkVerificationCode: '我们已向您发送一次性验证代码。请查看您的电子邮件。',
  failedSendVerificationCode: '发送验证代码失败',
  back: '返回',
  remainingAttempts: '剩余尝试次数',
  wrongVerificationCode: '验证码不正确。请重试。',
  resendAnotherCode: '重新发送另一个代码',
  wrongEmailVerificationCode: '电子邮件或验证码不正确。请重试。',
  aboutOrganizer: '关于组织者',
  proceedToCheckout: '前往结账',
  ticket: '门票',
  saveAndAddAnotherTicket: '保存并添加另一张门票',
  addToAppleWallet: '添加到Apple钱包',
  addToGoogleWallet: '添加到Google钱包',
  downloadFailed: '下载失败',
  ios: 'iOS',
  android: '安卓',
  allRightsReserved: '保留所有权利',
  fillFormWithInformation: '用我的信息填写表格',
  shareEventHint: '在社交媒体或其他渠道分享此活动。',
  favoriteEventHint:
    '您收藏的活动将显示在右上角的心形图标下，以便更轻松地访问。实心心形表示您已收藏该活动。轮廓心形表示该活动未被收藏。收藏后，再次点击心形图标即可将其从收藏夹中移除。',
  sendCode: '发送代码',
  continue: '继续',
  sendConfirmationEmail: '发送确认电子邮件至',
  specifyContactInfo: '指定联系信息',
  confirmationEmail: '将包含您的门票的确认电子邮件发送至此联系人',
  oops: '哎呀，',
  somethingWentWrong: '出错了！',
  next: '下一步',
  registration: '注册',
  includedItems: '包含的项目',
  packages: '套餐',
  select: '选择',
  selected: '已选择',
  categories: '类别',
  all: '全部',
  noAddons: '无附加组件',
  noActivities: '无活动',
  noSessions: '无场次',
  noMoreAvailable: '无更多可用',
  reachedMaxQuantityPerOrder: '已达到每笔订单的最大数量',
  extraMinQuantityPerOrderIs: '每笔订单的最小数量为',
  didNotMeetMinQuantityPerOrder: '未达到每笔订单的最小数量',
  discountCode: '折扣码',
  remove: '移除',
  apply: '应用',
  discount: '折扣',
  totalPaid: '总付款',
  ticketType: '门票类型',
  sessions: '场次',
  speakers: '演讲者',
  sponsors: '赞助商',
  aboutThisSession: '关于本场次',
  sessionDetails: '场次详情',
  myCart: '我的购物车',
  items: '项目',
  confirmEmail: '确认电子邮件',
  invalidEmailConfirmation: '无效的电子邮件确认',
  addToCalendar: '添加到日历',
  cartSummary: '购物车摘要',
  eventTickets: '活动门票',
  aboutEvent: '关于活动',
  downloadTicket: '下载门票',
  addToWallet: '添加到钱包',
  editRegistration: '编辑注册信息',
  purchaseExtras: '购买附加项目',
  carts: '购物车',
  organizerNotFound: '未找到组织者',
  extras: '附加项目',
  registrationInfo: '注册信息',
  ticketsPurchasedForOthers: '为他人购买的门票',
  eventPurchasedTicketsInfo: '您已经注册了此活动',
  view: '查看',
  moreInfo: '更多信息',
  maxQuantityPerOrderHasBeenReached: '已达到每个订单的最大数量',
  myOrders: '我的订单',
  liveAndUpcomingEvents: '直播和即将到来的活动',
  brandName: 'Captello',
  save: '保存',
  clear: '清除',
  edit: '编辑',
  verify: '验证',
  change: '更改',
  confirm: '确认',
  minLeftForExtend: '剩余分钟延长',
  cardNumber: '卡号',
  expiration: '到期',
  cvv: 'CVV',
  submit: '提交',
} as const
