import { isServer, isProduction, stringify } from './common'

const prefix = `HEW | ${isServer() ? 'SERVER' : 'CLIENT'} LOG:`
const colors = {
  success: isServer() ? '\x1b[36m\x1b[89m' : 'lightgreen',
  info: isServer() ? '\x1b[32m\x1b[89m' : 'orange',
  debug: isServer() ? '\x1b[33m\x1b[89m' : 'blue',
  error: isServer() ? '\x1b[31m\x1b[89m' : 'red',
}

const log = (...args: string[]): void => {
  if (isProduction()) return
  console.log(...args)
}

const logError = (onServer: boolean, prefix: string, error: Error, ...rest: any[]): void => {
  if (isProduction() && !isServer()) return
  console.log()

  if (onServer) {
    log(`${colors.error}%s\x1b[0m`, `Error start---------------------`)
    console.error(`${Date.now()} -> ${prefix}`, colors.error, ...rest, error)
    log(`${colors.error}%s\x1b[0m`, `Error end-----------------------`)
  } else {
    console.log('%cError start---------------------', `color: red`)
    const message = rest.reduce((acc, curr) => {
      curr = typeof curr === 'string' ? curr : stringify(curr)
      return `${acc + curr} `
    }, ``)
    console.log(`%c${Date.now()} -> ${prefix} ${message}`, `color:${colors.error}`)
    console.error(error)
    console.log('%cError end-----------------------', `color:${colors.error}`)
  }
}

const prettyLog = (onServer: boolean, color: string, prefix: string, ...rest: any[]): void => {
  const message = rest.reduce((acc, curr) => {
    curr = typeof curr === 'string' ? curr : stringify(curr)
    return `${acc + curr} `
  }, ``)

  console.log()

  if (onServer) {
    log(`${color}%s\x1b[0m`, `${Date.now()} -> ${prefix}: ${message}`)
  } else {
    log(`%c ${Date.now()} -> ${prefix}: ${message}`, `color:${color}`)
  }
}

const info = (...rest: any[]): void => prettyLog(isServer(), colors.info, prefix, ...rest)

const debug = (...rest: any[]): void => prettyLog(isServer(), colors.debug, prefix, ...rest)

const error = (error: Error, ...rest: any[]): void => logError(isServer(), prefix, error, ...rest)

const success = (...rest: any[]): void => prettyLog(isServer(), colors.success, prefix, ...rest)

/*info('info')
debug('debug')
error(new Error('error'))
success('success')*/

export default {
  info,
  debug,
  error,
  success,
}
