import _get from 'lodash/get'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import { useRouter } from 'next/router'
import MuLink from '@mui/material/Link'
import React, { useEffect } from 'react'
import Popper from '@mui/material/Popper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import debounceFn, { DebouncedFunction } from 'debounce-fn'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { EventsSearchType } from '../../enums'
import { useTranslation } from '../../providers/localesProvider'
import { useSearchTags } from '../../queryHooks/tagQuery'
import { useSearchEvents } from '../../queryHooks/eventQuery'

import Link from '../shared/ui/Link'
import Spinner from '../shared/Spinner'
import HexTabPanel from '../shared/HexTabPanel'
import EventGlobalSearchCard from './EventGlobalSearchCard'

type Props = {
  mobileView?: boolean
}

const GlobalSearch: React.FC<Props> = (props: Props) => {
  const { mobileView } = props
  const { t } = useTranslation()
  const router = useRouter()
  const query = _get(router, 'query.query', '')
  const [debounceFunc, setDebounceFunc] = React.useState<DebouncedFunction<string[], void> | void>()
  const [searchQuery, setSearchQuery] = React.useState(() => (router.pathname === '/search' ? query : '') as string)
  const debouncedSearch = debounceFn(setSearchQuery, { wait: 700 })
  const { data: events, isLoading } = useSearchEvents(searchQuery, EventsSearchType.Title)
  const { data: tags, isLoading: isLoadingTags } = useSearchTags(searchQuery)
  const [searchQueryVal, setSearchQueryVal] = React.useState(searchQuery)
  const hasSearchQuery = Boolean(searchQuery) && searchQuery.length > 1
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null)
  const isOpen = Boolean(anchorEl) && hasSearchQuery
  const [value, setValue] = React.useState(0)

  const isTag = value === 1

  const goToRoute = (pathname: string): void => {
    searchQuery.trim().length &&
      router.push({
        pathname,
        query: {
          ...router.query,
          query: searchQuery.trim(),
          ...(isTag && {
            searchType: EventsSearchType.Tag,
          }),
        },
      })
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (debounceFunc && debounceFunc.cancel) debounceFunc.cancel()
    const val = _get(e, 'target.value')
    setSearchQueryVal(val)

    if (val && val.length > 1) {
      const d = debouncedSearch(val)
      setDebounceFunc(d)
    } else {
      setSearchQuery('')
    }
  }

  const handlePopoverOpen = (event: React.FocusEvent<HTMLInputElement>): void => {
    setAnchorEl(event.target)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const searchSubmit = () => {
    if (searchQueryVal.trim()) {
      handlePopoverClose()
      searchQuery.trim().length
      goToRoute(`/search`)
    }
  }

  const handleTagClick = (query: string): void => {
    setSearchQuery(query)
    setSearchQueryVal(query)
    handlePopoverClose()
  }

  useEffect(() => {
    const searchVal = router.pathname === '/search' ? (query as string) : ''
    setSearchQueryVal(searchVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  return (
    <div style={{ width: mobileView ? '100%' : '45%' }}>
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <Grid
          sx={{
            height: 44,
            width: '100%',
            ml: { xs: 0, md: 3 },
            borderRadius: '6px',
            position: 'relative',
            backgroundColor: 'white',
          }}
        >
          <InputBase
            value={searchQueryVal}
            placeholder={`${t('search')}`}
            sx={{
              height: '100%',
              width: '100%',
              '& .MuiInputBase-input': { fontSize: 14, padding: '8px 8px 8px 22px' },
            }}
            inputProps={{ 'aria-label': t('search') }}
            endAdornment={
              <InputAdornment position="end">
                {Boolean(searchQueryVal) && searchQueryVal.length > 1 ? (
                  <IconButton
                    size="small"
                    aria-label="clear search"
                    onClick={(): void => {
                      setSearchQuery('')
                      setSearchQueryVal('')
                      handlePopoverClose()
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                ) : null}
                <Grid
                  sx={{
                    color: 'white',
                    paddingX: 2,
                    height: '44px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'primary.main',
                    borderRadius: '0px 6px 6px 0px',
                  }}
                  onClick={searchSubmit}
                >
                  {isLoading ? <Spinner /> : <SearchIcon sx={{ fontSize: 30 }} />}
                </Grid>
              </InputAdornment>
            }
            data-cy="searchInput"
            onInput={handleSearchInput}
            onFocus={handlePopoverOpen}
            onKeyDown={(e) => {
              if (e.key === 'Enter') searchSubmit()
            }}
          />
          <Popper
            keepMounted
            placement={'bottom-start'}
            open={isOpen}
            anchorEl={anchorEl}
            sx={{
              width: { xs: '100%', md: 520 },
              height: 368,
              zIndex: 5,
              borderRadius: '5px',
              overflowY: 'hidden',
              overflowX: 'hidden',
              display: 'inline-block',
              boxSizing: 'border-box',
              mt: '5px !important',
              border: '1px solid #E1E1E1',
              py: 2,
              px: 3,
              fill: 'common.white',
              boxShadow: '0 6px 9px 0 rgba(0,0,0,0.09)',
              backgroundColor: 'common.white',
            }}
          >
            <Grid container spacing={1}>
              <Grid item container justifyContent="flex-start">
                {isOpen ? (
                  <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                    <Tab label={t('events')} />
                    <Tab label={t('tags')} />
                  </Tabs>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <HexTabPanel value={value} index={0}>
                  {isLoading ? (
                    <Spinner size="xlarge" />
                  ) : events && events.length > 0 ? (
                    <Grid container direction="column">
                      <Grid container item>
                        <Grid item container spacing={1}>
                          <Grid item container alignItems="center" justifyContent="flex-start" spacing={1}>
                            {events.map((event) => (
                              <EventGlobalSearchCard
                                key={event.uuid}
                                eventData={event}
                                onTitleClick={handlePopoverClose}
                              />
                            ))}
                            <Link href={{ pathname: `/search`, query: { query: searchQuery } }} passHref>
                              <Typography
                                color="primary"
                                sx={{
                                  fontSize: 20,
                                  margin: 0,
                                  fontWeight: 'bold',
                                  '&:hover': {
                                    textDecoration: 'underline',
                                  },
                                }}
                                onClick={handlePopoverClose}
                              >
                                {t('viewAll')}
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" justifyContent="center" style={{ marginTop: 80 }}>
                      <Typography>{`${t('noEvents')}`}</Typography>
                    </Grid>
                  )}
                </HexTabPanel>
                <HexTabPanel value={value} index={1}>
                  {isLoadingTags ? (
                    <Spinner size="xlarge" />
                  ) : tags && tags.length > 0 ? (
                    <Grid container spacing={2}>
                      {tags.map((tag, idx) => (
                        <Grid key={tag + idx} item xs={12} style={{ borderBottom: '1px solid #E1E1E1' }}>
                          <Link
                            href={{
                              pathname: `/search`,
                              query: { query: `${tag}`, searchType: `${EventsSearchType.Tag}` },
                            }}
                            passHref
                          >
                            <MuLink
                              sx={{ fontSize: 20, margin: 0, fontWeight: 'bold' }}
                              onClick={(): void => handleTagClick(tag)}
                              underline="hover"
                            >
                              {tag}
                            </MuLink>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" justifyContent="center" style={{ marginTop: 80 }}>
                      <Typography>{`${t('noTags')}`}</Typography>
                    </Grid>
                  )}
                </HexTabPanel>
              </Grid>
            </Grid>
          </Popper>
        </Grid>
      </ClickAwayListener>
    </div>
  )
}

export default GlobalSearch
