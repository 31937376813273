import Head from 'next/head'
import { ReactElement, useEffect } from 'react'
import App, { AppContext, AppProps } from 'next/app'
import { Amplify, withSSRContext } from 'aws-amplify'
import { HydrationBoundary } from '@tanstack/react-query'
import { CacheProvider, EmotionCache } from '@emotion/react'

import '../../public/fonts/stylesheet.css'

import { LocaleCode } from '../enums'
import { CustomerSettings, SSRBrands } from '../types'
import { postPreAuthData } from '../services/authService'
import ReactQueryProvider from '../queryHooks/reactQuery'
import { getBrand, getCustomerSettings } from '../services/metaDataService'
import { getSSRLocalCode, LocalesProvider } from '../providers/localesProvider'
import { decodeJWTToken, getHost, getVars } from '../helpers/common'

import log from '../helpers/log'
import initMocks from '../mocks'
import AppLayout from '../layout/AppLayout'
import MetaDataProvider from '../providers/MetaDataProvider'
import createEmotionCache from '../components/createEmotionCache'
import { AuthProvider, getSsrSession } from '../providers/authProvider'

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  initMocks().then()
}

const { cognitoUserPoolID, cognitoUserPoolWebClientID, cognitoUserPoolRegion } = getVars()

Amplify.configure({
  region: cognitoUserPoolRegion,
  userPoolId: cognitoUserPoolID,
  userPoolWebClientId: cognitoUserPoolWebClientID,
  ssr: true,
})

const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  pageProps: {
    dehydratedState: unknown
    brand: SSRBrands
    localeCode: LocaleCode
    token?: string
    customerSettings: CustomerSettings
  }
}

export default function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps): ReactElement {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
  }, [])

  const { brand, customerSettings, localeCode, token = null, dehydratedState } = pageProps

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link
          href={`https://fonts.googleapis.com/css2?family=${brand?.fontFamily}:wght@100;200;300;400;500;600;700;800;900&display=swap`}
          rel="stylesheet"
        />
        <title>{brand?.customer?.company ?? 'Captello Events'}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no" />
        <meta property="og:site_name" content="Hosted Events" />
      </Head>

      <ReactQueryProvider>
        <HydrationBoundary state={dehydratedState}>
          <LocalesProvider localeCode={localeCode}>
            <MetaDataProvider customerSettingsData={customerSettings} brandData={brand}>
              <AuthProvider token={token}>
                <AppLayout brand={brand}>
                  <Component {...pageProps} />
                </AppLayout>
              </AuthProvider>
            </MetaDataProvider>
          </LocalesProvider>
        </HydrationBoundary>
      </ReactQueryProvider>
    </CacheProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)
  const host = getHost(appContext.ctx.req)

  const reqCookies = appContext.ctx.req?.headers.cookie

  const { token: ssrToken } = getSsrSession(reqCookies)
  const decodedToken = decodeJWTToken(ssrToken)

  const pageProps = { ...appProps.pageProps, host }

  if (ssrToken && decodedToken?.email) {
    try {
      await postPreAuthData({
        email: decodedToken.email,
        host,
        token: ssrToken,
      })
    } catch (e) {
      log.info('failed to set auth session', { e })
    }
  }

  let localeCode = LocaleCode.english
  let brand: SSRBrands = {} as SSRBrands
  let customerSettings: CustomerSettings = {} as CustomerSettings

  const { Auth } = withSSRContext(appContext.ctx)

  try {
    const res = await Auth.currentSession()
    pageProps.token = res.getIdToken().getJwtToken()
  } catch (e) {
    log.info('User not logged in', { e })
    pageProps.token = null
  }

  // check if it's client side route navigation
  // const isCSR = appContext.ctx.req?.url?.startsWith('/_next')
  // if (isCSR) {
  //   return {
  //     props: { appProps, pageProps },
  //   }
  // }
  // any code after this line won't run while navigating

  try {
    if (host) {
      const [brandResponse, customerSettingsRes] = await Promise.all([
        getBrand({
          headers: { 'X-HOST': host },
        }).catch(),
        getCustomerSettings({
          headers: { 'X-HOST': host, ...(pageProps.token && { Authorization: `Bearer ${pageProps.token}` }) },
        }).catch(),
      ])

      const customerSettingsData = customerSettingsRes?.data

      localeCode = getSSRLocalCode({ req: appContext.ctx.req, customerSettingsData })

      brand = brandResponse.data
      customerSettings = customerSettingsData
    }
  } catch (e) {
    log.info('Fetching brand failed', { e })
  }

  pageProps.localeCode = localeCode
  pageProps.brand = brand
  pageProps.customerSettings = customerSettings

  return { appProps, pageProps }
}
