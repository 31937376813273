import React from 'react'
import { Container, Link as MuiLink, Typography } from '@mui/material'

import { useBrandQuery } from '../queryHooks/metaQuery'
import { useTranslation } from '../providers/localesProvider'
import useIsMobile from '../hooks/useIsMobile'

const Footer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { data: ssrBrands } = useBrandQuery()
  const { t } = useTranslation()
  const isMobile = useIsMobile('sm')

  return (
    <div>
      <footer
        style={{
          backgroundColor: ssrBrands?.footerBackgroundColor,
          height: isMobile ? 50 : 70,
          marginTop: 'auto',
          padding: isMobile ? '16px 16px' : '24px 16px',
        }}
      >
        <Container disableGutters maxWidth="md">
          <Typography variant="body2" align="center" sx={{ color: ssrBrands?.footerTextColor, fontWeight: 500 }}>
            © {`${new Date().getFullYear()} `}
            <MuiLink href="https://www.captello.com/event-management/" color="inherit" target="_blank">
              {t('brandName')}
            </MuiLink>{' '}
            {t('allRightsReserved')}
          </Typography>
          {children}
        </Container>
      </footer>
    </div>
  )
}

export default Footer
