import _get from 'lodash/get'
import _set from 'lodash/set'
import _noop from 'lodash/noop'
import { Auth } from 'aws-amplify'
import Cookies from 'universal-cookie'
import { AxiosError, AxiosResponse } from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { createContext, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react'

import log from '../helpers/log'
import {
  ejectRequestInterceptor,
  ejectResponseInterceptor,
  setRequestInterceptorCallback,
  setUnauthenticatedCallback,
} from '../helpers/axios'
import { HexEvent } from '../types'
import { AuthenticationMode } from '../enums'
import { isTokenLimited } from '../helpers/utils'
import { eventQueryKey } from '../queryHooks/eventQuery'
import { postPreAuthData } from '../services/authService'
import { decodeJWTToken, getHost } from '../helpers/common'
import { getCookie, removeCookie, setCookie } from '../helpers/cookies'

interface AuthContextProps {
  login: (token: string, limited: boolean) => void
  logout: () => Promise<void>
  isAuthenticated: () => boolean
  isLimited: () => boolean
  isUnlimited: boolean
}

/* Context */
const AuthContext = createContext<AuthContextProps>({
  login: _noop,
  logout: () => Promise.resolve(),
  isAuthenticated: () => false,
  isLimited: () => false,
  isUnlimited: false,
})
AuthContext.displayName = 'AuthContext'

const cookiePrefix = 'llscaup'
export const authCookieName = cookiePrefix

export function getSsrSession(reqCookie?: string): { token: string } {
  const cookies = new Cookies(reqCookie)
  return { token: cookies.get(authCookieName) }
}

/* Provider */
function AuthProvider(props: { children: ReactElement; token: string | null }): ReactElement {
  const { children } = props

  const ssrToken = props.token

  const isAuthenticated = (): boolean => !!getCookie(authCookieName)
  const [, setAuthorized] = useState<boolean>(() => isAuthenticated())

  const decodedToken = decodeJWTToken(getCookie(authCookieName))
  const isLimited = (): boolean => isTokenLimited(decodedToken)
  // isLimited state to cause rerender when the user verify login after limited session
  const [, setIsLimited] = useState(() => isLimited())

  const isUnlimited = isAuthenticated() && !isLimited()

  const invalidateCache = async () => {
    // remove each event query partial data cache so there won't be multiple requests for each event when signing in or out
    queryClient.removeQueries({
      queryKey: [eventQueryKey],
      predicate: (query) => !(query.state?.data as HexEvent)?.styleSettings,
    })
    await queryClient.invalidateQueries({ type: 'active' })
    queryClient.removeQueries({ type: 'inactive' })
  }

  const login = (token: string, limited: boolean): void => {
    setCookie(authCookieName, token)
    setIsLimited(limited)
    setAuthorized(true)
    invalidateCache().then()
  }

  const queryClient = useQueryClient()

  const logout = async (): Promise<void> => {
    try {
      await Auth.signOut()
      removeCookie(authCookieName)
      log.info('cookies removed')
      setAuthorized(false)
      setIsLimited(false)
      log.info('logged out!')
      //setTime out is used to run the "invalidateCache" after the redirect in case of private route
      //to avoid the invalidation of unnecessary queries
      setTimeout(() => {
        invalidateCache()
      })
    } catch (e) {
      log.info('logged failed!')
    }
  }

  const authContextProps: AuthContextProps = { login, logout, isAuthenticated, isLimited, isUnlimited }

  // handle token refresh & request retry on error with status 401
  const refreshing = useRef(false)

  //moved from useEffect to fix initial page Get request fail on first render
  const headerInterceptorId = setRequestInterceptorCallback((config) => {
    const token = getCookie(authCookieName)
    if (!config.headers?.skipAuth && token) _set(config, 'headers.Authorization', `Bearer ${token}`)

    return config
  })

  useEffect(() => {
    if (ssrToken) {
      setCookie(authCookieName, ssrToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssrToken])

  useEffect(() => {
    const interceptorId: number = setUnauthenticatedCallback(async (err): Promise<AxiosError | AxiosResponse> => {
      log.info(`refreshing: ${refreshing.current}`)
      const status = _get(err, 'status', null)

      if (isAuthenticated() && [401, 403].includes(status as number) && !refreshing.current) {
        refreshing.current = true
        log.info(`turn on | refreshing: ${refreshing.current}`)

        return new Promise((_, reject) => {
          postPreAuthData({
            email: decodedToken?.email,
            host: getHost(),
            authenticationMode: isLimited() ? AuthenticationMode.limited : AuthenticationMode.verified,
            token: getCookie(authCookieName),
          }).then(() =>
            Auth.currentSession()
              .then((res) => {
                const token = res.getIdToken().getJwtToken()
                if (token) {
                  setCookie(authCookieName, token)
                } else {
                  logout()
                }
              })
              .catch(() => {
                logout()
              })
              .finally(() => {
                // delay refreshing flag turn off to avoid double token refresh
                setTimeout(() => {
                  refreshing.current = false
                  log.info(`turn off | refreshing: ${refreshing.current}`)
                }, 1000)
                reject(err)
              })
          )
        })
      }

      return Promise.reject(err)
    })

    return (): void => {
      if (interceptorId) ejectResponseInterceptor(interceptorId)
      if (headerInterceptorId) ejectRequestInterceptor(headerInterceptorId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AuthContext.Provider value={authContextProps}>{children}</AuthContext.Provider>
}

/* useContext */
function useAuth(): AuthContextProps {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`AuthContext must be used within a AuthProvider`)
  }

  return useMemo(() => context || {}, [context])
}

export { AuthProvider, useAuth }
