import axios from '../helpers/axios'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { CustomerSettings, SSRBrands } from '../types'

export const getBrand = (config?: AxiosRequestConfig): AxiosPromise<SSRBrands> => {
  return axios.get(`/managed-events/branding`, { ...config })
}

export const getCustomerSettings = (config?: AxiosRequestConfig): AxiosPromise<CustomerSettings> => {
  return axios.get(`/managed-events/settings`, { ...config })
}
