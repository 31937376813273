import dayjs from 'dayjs'
import AcceptLanguageParser from 'accept-language-parser'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { createContext, ReactElement, useCallback, useContext, useMemo, useState } from 'react'

import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import 'dayjs/locale/ja'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/zh'
import 'dayjs/locale/es'
import 'dayjs/locale/ko'
import 'dayjs/locale/pt'

import {
  enTranslation,
  frTranslation,
  arTranslation,
  italianTranslation,
  chineseTranslation,
  japaneseTranslation,
  spanishTranslation,
  germanTranslation,
  koreanTranslation,
  PortugueseTranslation,
} from '../translation'

import { LocaleCode } from '../enums'
import { IncomingMessage } from 'http'
import { CustomerSettings } from '../types'
import { isEnumValue, isServer } from '../helpers/common'
import { getSsrCookie, setCookie } from '../helpers/cookies'
import { setRequestInterceptorCallback } from '../helpers/axios'

dayjs.extend(localizedFormat)
// dayjs.extend(preParsePostFormat)

export const locales = {
  [LocaleCode.english]: { code: LocaleCode.english, resources: enTranslation },
  [LocaleCode.french]: { code: LocaleCode.french, resources: frTranslation },
  [LocaleCode.german]: { code: LocaleCode.german, resources: germanTranslation },
  [LocaleCode.arabic]: { code: LocaleCode.arabic, resources: arTranslation },
  [LocaleCode.italian]: { code: LocaleCode.italian, resources: italianTranslation },
  [LocaleCode.chinese]: { code: LocaleCode.chinese, resources: chineseTranslation },
  [LocaleCode.japanese]: { code: LocaleCode.japanese, resources: japaneseTranslation },
  [LocaleCode.spanish]: { code: LocaleCode.spanish, resources: spanishTranslation },
  [LocaleCode.korean]: { code: LocaleCode.korean, resources: koreanTranslation },
  [LocaleCode.Portuguese]: { code: LocaleCode.Portuguese, resources: PortugueseTranslation },
}

type TranslationResource = (typeof locales)[keyof typeof locales]['resources']
type TranslationKey = keyof TranslationResource

interface LocaleContextProps {
  code: LocaleCode
  setLocale: (localeCode: LocaleCode) => void
  t: <T extends TranslationKey>(key: T) => TranslationResource[T]
}

const LocalesContext = createContext<LocaleContextProps>({} as LocaleContextProps)
LocalesContext.displayName = 'LocalesContext'

export const localeCookieName = 'localeCode'

export const getLocaleCode = ({
  req,
  isAutoDetectLocalization,
}: {
  req?: IncomingMessage
  isAutoDetectLocalization?: boolean
}): string => {
  let locale = ''
  try {
    const localeCookie = getSsrCookie(localeCookieName, req?.headers.cookie)
    if (localeCookie) {
      locale = localeCookie
      return locale
    }
    if (isAutoDetectLocalization) {
      const languages = req
        ? AcceptLanguageParser.parse(req.headers['accept-language'] || '')
        : AcceptLanguageParser.parse(navigator.language)
      if (Array.isArray(languages) && languages.length > 0) {
        locale = languages[0].code
      }
    }
  } catch (e) {
    return locale
  }
  return locale
}

export const getSSRLocalCode = ({
  req,
  customerSettingsData,
}: {
  req?: IncomingMessage
  customerSettingsData?: CustomerSettings
}) => {
  let localeCode = LocaleCode.english

  if (customerSettingsData?.isEnableLocalization) {
    const userLocaleCode =
      getLocaleCode({
        req,
        isAutoDetectLocalization: customerSettingsData.localizationSettings.isEnableAutoDetectLocalization,
      }) || customerSettingsData.localizationSettings.defaultLanguage?.code

    // check if it's one of supported languages,so it would fall back to (en) in case of cookie value modification
    localeCode =
      userLocaleCode &&
      isEnumValue(LocaleCode, userLocaleCode) &&
      customerSettingsData.localizationSettings.languages.some((lng) => lng.code === userLocaleCode)
        ? userLocaleCode
        : localeCode
  }
  return localeCode
}

export function LocalesProvider(props: { children: ReactElement; localeCode: LocaleCode }): ReactElement {
  const { localeCode } = props
  const defaultLocale = locales[localeCode] ?? locales[LocaleCode.english]
  dayjs.locale(defaultLocale.code)

  const t = useCallback(
    <T extends TranslationKey>(key: T): TranslationResource[T] => defaultLocale.resources[key],
    [defaultLocale]
  )

  const setLocale = useCallback((code: LocaleCode): void => {
    const newLocale = locales[code]
    if (newLocale) {
      setCookie(localeCookieName, code)
    }
  }, [])

  // useState to set interceptor only one time & in the start of app lifecycle
  // isServer check so it won't override the server side request header with old previous value
  // synchronous true to avoid any delays in request execution
  useState(() => {
    if (!isServer()) {
      setRequestInterceptorCallback(
        (config) => {
          config.headers['Accept-Language'] = defaultLocale.code
          return config
        },
        { synchronous: true }
      )
    }
  })

  const value = useMemo<LocaleContextProps>(() => ({ ...defaultLocale, setLocale, t }), [defaultLocale, setLocale, t])

  return <LocalesContext.Provider {...props} value={value} />
}

export function useTranslation() {
  const context = useContext(LocalesContext)
  if (context === undefined) {
    throw new Error(`LocalesContext must be used within a LocalesProvider`)
  }
  const { code, setLocale, t } = context
  return { code, setLocale, t }
}
