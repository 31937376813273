import dayjs from 'dayjs'
import _get from 'lodash/get'
import djsUtc from 'dayjs/plugin/utc'
import _isNumber from 'lodash/isNumber'
import _upperFirst from 'lodash/upperFirst'
import djsTimeZone from 'dayjs/plugin/timezone'
import djsAdvancedFormat from 'dayjs/plugin/advancedFormat'

import {
  AddonEventTicket,
  EventAddon,
  EventCartItem,
  EventCartItemStandardField,
  EventCartItemTicket,
  EventTicket,
  EventTicketAddons,
  EventTicketAddonsType,
  ExtraAddon,
  ExtraPackage,
  HexEvent,
  IncludedAddon,
  MyEventRegisteredTicket,
  MyTicketExtra,
  OptionType,
  OrderContactInfo,
  Organizer,
  PackageEventTicket,
  Prospect,
  SocialInfo,
  StringKeyValuePairs,
  TicketRecord,
} from '../types'
import axios from './axios'
import { capitalizeLabel, centToUSD, getVars } from './common'
import { SocialNetworkType } from '../enums'

dayjs.extend(djsUtc)
dayjs.extend(djsTimeZone)
dayjs.extend(djsAdvancedFormat)

const { apiUrl: baseURL } = getVars()

export function timeLeftToExpire(expireTimestamp = Date.now()) {
  const timeDifferenceInSec = (expireTimestamp * 1000 - Date.now()) / 1000

  const minutes = Math.floor(timeDifferenceInSec / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(timeDifferenceInSec % 60)
    .toString()
    .padStart(2, '0')

  return `${minutes}:${seconds}`
}

export function getBanner(event?: HexEvent): string {
  return _get(event, 'details.coverMedia.url', '')
}

export function getThump(event?: HexEvent): string {
  return _get(event, 'details.coverMedia.versions[0].url', '/images/placeholder-img.png')
}

export function getDescription(event?: HexEvent): string {
  const content = _get(event, 'content', [])
  const item = content.find((c) => c.type === 'text')
  return _get(item, 'pieceContent', '')
}

export function getOrganizerThump(organizer?: Organizer): string {
  return _get(organizer, 'photo', '')
}

export function getEventPrice(event?: HexEvent, freeText = 'Free'): string | null {
  const minPrice = event?.ticketPriceCentRange?.minPriceCent ?? null
  const maxPrice = event?.ticketPriceCentRange?.maxPriceCent ?? null

  if (minPrice === null || maxPrice === null) return null
  else if (minPrice === maxPrice) return minPrice === 0 ? freeText : `${centToUSD(minPrice)}`
  else if (minPrice === 0 && maxPrice > 0) return `${freeText} - ${centToUSD(maxPrice)}`
  else return `${centToUSD(minPrice ?? 0)} - ${centToUSD(maxPrice ?? 0)}`
}

export function getTicketFee(ticket: EventTicket): string | null {
  const fee = ticket.feesCent
  return fee && !ticket.isAbsorbFees ? centToUSD(fee) : null
}

export function getTicketPrice(ticket: EventTicket): string | null {
  const price = ticket.priceCent
  return price ? centToUSD(price) : null
}

export const getTicketFullPrice = (ticket: EventTicket): string | null => {
  const price = ticket.priceCent
  const fees = !ticket.isAbsorbFees ? ticket.feesCent : 0
  return price ? centToUSD(price + fees) : null
}

export function getTicketOriginalPrice(ticket?: EventTicket): string | null {
  const price: number | null = _get(ticket, 'priceCent', null)
  const original: number | null = _get(ticket, 'originalPriceCent', null)

  return price && original && original > price ? centToUSD(original) : null
}

export function getUuidFromUriTitle(uriTitle: string): string | null {
  const parts = uriTitle.split('-')
  return _get(parts, parts.length - 1, null)
}

export function getTicketQuantityOptions(ticket: EventTicket, ticketCartItems: number): Array<number> {
  const { minTicketsPerOrder = 1, maxTicketsPerOrder = 1 } = ticket
  if (_isNumber(ticket.maxTicketsPerOrder) && ticket.maxTicketsPerOrder === 0)
    ticket.maxTicketsPerOrder =
      _isNumber(ticket.remainAvailableQuantityCount) && ticket.remainAvailableQuantityCount + ticketCartItems < 100
        ? ticket.remainAvailableQuantityCount + ticketCartItems
        : 100
  ticket.minTicketsPerOrder = ticket.minTicketsPerOrder > 0 ? ticket.minTicketsPerOrder : 1
  return Array.from({ length: maxTicketsPerOrder - minTicketsPerOrder + 1 }, (v, k) => k + minTicketsPerOrder)
}

export function getGroupedEventCartTicketItems(cartItems: Array<EventCartItem>): {
  [key: `${EventCartItem['ticketRecord']['id']}`]: Array<EventCartItem>
} {
  return cartItems.reduce((acc, curr: EventCartItem) => {
    const items: Array<EventCartItem> = _get(acc, curr.ticketRecord?.eventTicket.id, [])
    return { ...acc, [curr.ticketRecord?.eventTicket.id]: [...items, curr] }
  }, {})
}

export function getGroupedUserEventTickets(cartItems: Array<MyEventRegisteredTicket>): {
  [key: string]: { registeredTickets: Array<MyEventRegisteredTicket>; totalPrice: number }
} {
  return cartItems.reduce((acc, curr: MyEventRegisteredTicket) => {
    const items: Array<MyEventRegisteredTicket> = _get(acc, `${curr.ticketRecord.eventTicket.id}.registeredTickets`, [])
    const price: number = _get(acc, `${curr.ticketRecord.eventTicket.id}.totalPrice`, 0)
    return {
      ...acc,
      [curr.ticketRecord.eventTicket.id]: {
        registeredTickets: [...items, curr],
        totalPrice: price + curr.ticketRecord.buyPrice + curr.ticketRecord.buyFees,
      },
    }
  }, {})
}

export const getGroupedAddonsByType = (
  addons: Array<EventTicketAddons>
): {
  [key: string]: Array<EventTicketAddons>
} => {
  return addons.reduce((acc, curr: EventTicketAddons) => {
    const items: Array<EventTicketAddons> = _get(acc, curr.eventAddon.category.name, [])
    return { ...acc, [curr.eventAddon.category.name]: [...items, curr] }
  }, {})
}

export const getGroupedExtrasByType = (
  addons: Array<EventAddon | ExtraAddon>
): {
  [key: string]: Array<EventAddon | ExtraAddon>
} => {
  return addons.reduce((acc, curr) => {
    const items: Array<EventAddon | ExtraAddon> = _get(acc, curr.category.name, [])
    return { ...acc, [curr.category.name]: [...items, curr] }
  }, {})
}

export const getGroupedMyTicketExtrasByType = (
  addons: Array<MyTicketExtra>
): {
  [key: EventTicketAddonsType['name']]: Array<MyTicketExtra>
} => {
  return addons.reduce(
    (acc, curr) => {
      const items: Array<MyTicketExtra> = acc[curr.addon.category.name] ?? []
      return { ...acc, [curr.addon.category.name]: [...items, curr] }
    },
    {} as {
      [key: EventTicketAddonsType['name']]: Array<MyTicketExtra>
    }
  )
}

export const groupedEventCartItemsExtrasByQuantity = (cartExtras: EventCartItemTicket[]): Record<number, number> => {
  return cartExtras.reduce((acc, curr) => {
    const itemQuantity: number = _get(acc, curr.eventTicket.id, 0)
    return { ...acc, [curr.eventTicket.id]: itemQuantity + curr.quantity }
  }, {})
}

export const groupedEventCartItemsExtrasByMinQuantity = (
  cartExtras: EventCartItemTicket[]
): Record<number, { name: string; quantity: number; minQuantity: number }> => {
  return cartExtras.reduce((acc, curr) => {
    const item: { name: string; quantity: number; minQuantity: number } | undefined = _get(
      acc,
      curr.eventTicket.id,
      undefined
    )
    const itemQuantity: number = _get(item, 'quantity', 0)

    return {
      ...acc,
      [curr.eventTicket.id]: {
        name: curr.eventTicket.addon ? curr.eventTicket.addon.name : curr.eventTicket.name,
        quantity: itemQuantity + curr.quantity,
        minQuantity: curr.eventTicket.minTicketsPerOrder,
      },
    }
  }, {})
}

export const groupedExtrasByCategory = (
  extras: TicketRecord<PackageEventTicket | AddonEventTicket>[]
): {
  addon: TicketRecord<AddonEventTicket>[]
  activity: TicketRecord<AddonEventTicket>[]
  session: TicketRecord<AddonEventTicket>[]
  package: TicketRecord<PackageEventTicket>[]
} => {
  return extras.reduce(
    (acc, curr) => {
      const itemCategory = curr.eventTicket.isAddonSpecific ? curr.eventTicket.addon.category.type : 'package'
      const items = curr.eventTicket.isAddonSpecific
        ? acc[curr.eventTicket.addon.category.type] ?? []
        : acc.package ?? []
      return { ...acc, [itemCategory]: [...items, curr] }
    },
    {} as {
      addon: TicketRecord<AddonEventTicket>[]
      activity: TicketRecord<AddonEventTicket>[]
      session: TicketRecord<AddonEventTicket>[]
      package: TicketRecord<PackageEventTicket>[]
    }
  )
}

export const groupedMyTicketExtrasByCategory = (
  extras: MyTicketExtra[]
): {
  addon: MyTicketExtra[]
  activity: MyTicketExtra[]
  session: MyTicketExtra[]
} => {
  return extras.reduce(
    (acc, curr) => {
      const itemCategory = curr.addon.category.type
      const items = acc[curr.addon.category.type] ?? []
      return { ...acc, [itemCategory]: [...items, curr] }
    },
    {} as {
      addon: MyTicketExtra[]
      activity: MyTicketExtra[]
      session: MyTicketExtra[]
    }
  )
}

export const getProspectName = (prospect?: Prospect): string => {
  let fName = ''
  let lName = ''

  if (prospect && Array.isArray(prospect.standardFields)) {
    for (const f of prospect.standardFields) {
      if (_get(f, 'field.identifier', null) === 'FirstName') fName = _get(f, 'value', '')
      if (_get(f, 'field.identifier', null) === 'LastName') lName = _get(f, 'value', '')
    }
  }

  return `${fName} ${lName}`.trim()
}

export const getProspectPhone = (prospect?: Prospect): string | null => {
  let phone = null

  if (prospect && prospect.standardFields) {
    for (const p of prospect.standardFields) {
      if (p.field.identifier === 'MobilePhone') phone = p.value || null
    }
  }

  return phone
}

export const getContactProspect = (contactInfo: OrderContactInfo): Prospect => {
  return {
    email: _get(contactInfo, 'email', undefined),
    standardFields: Object.keys(contactInfo).reduce(
      (acc: Array<EventCartItemStandardField>, key: string) =>
        _get(contactInfo, key, '').trim().length > 0
          ? [
              ...acc,
              {
                field: { identifier: _upperFirst(key) },
                value: _get(contactInfo, key, ''),
              },
            ]
          : acc,
      []
    ),
  }
}

export function getEnumOptions(
  e: StringKeyValuePairs,
  enumOptions: { helperText?: StringKeyValuePairs; hasEmptyItem?: boolean; exact?: boolean } = {
    helperText: undefined,
    hasEmptyItem: false,
    exact: false,
  }
): OptionType[] {
  const { hasEmptyItem, helperText, exact } = enumOptions
  const keys = Object.keys(e)
  const items = keys.map((k) =>
    helperText
      ? { label: exact ? k : capitalizeLabel(k), value: e[k], helperText: helperText[e[k]] }
      : { label: exact ? k : capitalizeLabel(k), value: e[k] }
  )
  return hasEmptyItem ? [{ label: '', value: '' }, ...items] : items
}

export function isExtraAddon(extra: any): extra is ExtraAddon {
  return !!extra?.category
}

export function isEventAddon(extra: any): extra is EventAddon {
  return !!extra?.category
}

export const getExtraSubTotal = (extras: Array<ExtraPackage | ExtraAddon>): number =>
  extras.reduce((acc, curr) => {
    const price = (isExtraAddon(curr) ? curr.ticket?.priceCent : curr?.priceCent) ?? 0
    return acc + price * curr.extrasQuantity
  }, 0)

export const getExtraTotalFees = (extras: Array<ExtraPackage | ExtraAddon>): number =>
  extras.reduce((acc, curr) => {
    const price = (isExtraAddon(curr) ? curr.ticket?.feesCent : curr?.feesCent) ?? 0
    return acc + price * curr.extrasQuantity
  }, 0)

export const isTokenLimited = (parsedToken: any) => {
  return !!parsedToken?.roles?.includes('ROLE_HEPORTAL_LIMITED')
}

export function loadScript(code: string, id: string): Promise<void> {
  try {
    return new Promise<void>((resolve) => {
      if (!document.getElementById('track_engine')) {
        const script = document.createElement('script')
        script.crossOrigin = ''
        script.id = id
        script.appendChild(document.createTextNode(code))
        document.head.appendChild(script)
        resolve()
      } else {
        resolve()
      }
    })
  } catch (err) {
    return Promise.reject()
  }
}

export const appleDownloadLink = async (eventUuid: string, eTicketToken?: string | string[]) => {
  const downloadLink = `${baseURL}/managed-events/events/${eventUuid}/etickets/${eTicketToken}?walletType=ios`
  const res = await fetch(downloadLink)
  if (res.status !== 200) throw new Error('download request failed')

  const blob = await res.blob()
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = 'pass.pkpass'
  link.click()
}

export const androidDownloadLink = async (eventUuid: string, eTicketToken?: string | string[]) => {
  const res = await axios.get(`/managed-events/events/${eventUuid}/etickets/${eTicketToken}?walletType=android`)
  window.location.href = res.data?.url
}

export function isIncludedAddon(addon: any): addon is IncludedAddon {
  return !addon?.ticket
}

export const isValidTicketsQuantity = (cartItems: EventCartItem[]): boolean => {
  const notRegisteredTickets = cartItems?.filter((item) => !item?.eventRegisteredTicket) ?? []
  const groupNotRegisteredCartTickets = getGroupedEventCartTicketItems(notRegisteredTickets)
  const notRegisteredTicketsIds = Object.keys(groupNotRegisteredCartTickets) as `${number}`[]
  const groupedCartTickets = notRegisteredTicketsIds.map((ticketId) => groupNotRegisteredCartTickets[ticketId])
  return groupedCartTickets
    .map((group) => {
      const { minTicketsPerOrder, maxTicketsPerOrder } = group[0]?.ticketRecord?.eventTicket ?? {}
      return (
        (minTicketsPerOrder || minTicketsPerOrder === 0 ? minTicketsPerOrder <= group.length : false) &&
        (maxTicketsPerOrder === 0 ? true : maxTicketsPerOrder ? maxTicketsPerOrder >= group.length : false)
      )
    })
    .every((element) => element)
}

export const getCartExtrasQuantityErrors = (
  cartItems: EventCartItem[]
): { name: string; quantity: number; minQuantity: number }[] => {
  const eventCartItemsExtras = cartItems?.map((cartItem) => cartItem.extras).flat() || []
  const groupedCartExtrasByMinQuantity = groupedEventCartItemsExtrasByMinQuantity(eventCartItemsExtras)
  return Object.values(groupedCartExtrasByMinQuantity).filter(
    (extra) => extra.minQuantity > 0 && extra.quantity < extra.minQuantity
  )
}

export const getSocialNetwork = (social: SocialInfo): string => {
  return social.socialNetworkType === SocialNetworkType.twitter ? 'x' : social.socialNetworkType.toLowerCase()
}

export const isTicketReachedMaxQuantity = (cartItems: EventCartItem[], ticket: EventTicket): boolean => {
  const notRegisteredTickets = cartItems?.filter((item) => !item?.eventRegisteredTicket) ?? []
  const groupNotRegisteredCartTickets = getGroupedEventCartTicketItems(notRegisteredTickets)
  return (
    ticket?.maxTicketsPerOrder > 0 &&
    groupNotRegisteredCartTickets[`${ticket.id}`]?.length >= ticket?.maxTicketsPerOrder
  )
}
