import { AxiosPromise, AxiosRequestConfig } from 'axios'

import axios from '../helpers/axios'
import { HexEvent, IPagedData } from '../types'
import { DataOrder, EventInterval } from '../enums'

export const getPaginatedEvents = ({
  offset = 0,
  itemsPerPage = 10,
  interval,
  isFeatured,
  config,
}: {
  offset: number
  itemsPerPage: number
  isFeatured?: boolean
  interval: EventInterval
  config?: AxiosRequestConfig
}): AxiosPromise<IPagedData<HexEvent>> => {
  return axios.get(`/events`, {
    params: {
      offset,
      itemsPerPage,
      isFeatured,
      interval,
      'order[minStartTimeSlot]': interval === EventInterval.upcoming ? DataOrder.asc : DataOrder.desc,
    },
    ...config,
  })
}

export const getEvents = (isFeatured?: boolean, config?: AxiosRequestConfig): AxiosPromise<HexEvent[]> => {
  return axios.get(`/events`, {
    params: { isFeatured, disablePagination: true },
    ...config,
  })
}

export const getEventById = (uriTitle: string, config?: AxiosRequestConfig): AxiosPromise<HexEvent> =>
  axios.get(`/events/${uriTitle}`, config)

export const searchEvents = (
  { title = undefined, tag = undefined }: { title?: string; tag?: string },
  offset = 0,
  itemsPerPage = 3,
  config?: AxiosRequestConfig
): AxiosPromise<IPagedData<HexEvent>> => {
  return axios.get(`/events`, { params: { title, 'tags.value': tag, offset, itemsPerPage }, ...config })
}
