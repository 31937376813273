import { PropsWithChildren } from 'react'

import { CustomerSettings, SSRBrands } from '../types'
import { useBrandQuery, useCustomerSettingsQuery } from '../queryHooks/metaQuery'

type Props = PropsWithChildren<{ customerSettingsData: CustomerSettings; brandData: SSRBrands }>

function MetaDataProvider(props: Props) {
  const { children, customerSettingsData, brandData } = props

  useCustomerSettingsQuery({ initialData: customerSettingsData })

  useBrandQuery({ initialData: brandData })

  return <>{children}</>
}

export default MetaDataProvider
